import React from 'react';

const NotificationErrorOval = ({ width = 28, height = 28, className = '' }) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#FFDEDD"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14 25C20.0751 25 25 20.0751 25 14C25 7.92487 20.0751 3 14 3C7.92487 3 3 7.92487 3 14C3 20.0751 7.92487 25 14 25Z" fill="#FFC3BB"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14 24C19.5228 24 24 19.5228 24 14C24 8.47715 19.5228 4 14 4C8.47715 4 4 8.47715 4 14C4 19.5228 8.47715 24 14 24Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6 14C6 18.4112 9.5888 22 14 22C18.4112 22 22 18.4112 22 14C22 9.5888 18.4112 6 14 6C9.5888 6 6 9.5888 6 14ZM17.2426 10.7574C17.6332 11.1479 17.6332 11.781 17.2426 12.1716L15.4142 14L17.2426 15.8284C17.6332 16.219 17.6332 16.8521 17.2426 17.2426C16.8521 17.6332 16.219 17.6332 15.8284 17.2426L14 15.4142L12.1716 17.2426C11.781 17.6332 11.1479 17.6332 10.7574 17.2426C10.3668 16.8521 10.3668 16.219 10.7574 15.8284L12.5858 14L10.7574 12.1716C10.3668 11.781 10.3668 11.1479 10.7574 10.7574C11.1479 10.3668 11.781 10.3668 12.1716 10.7574L14 12.5858L15.8284 10.7574C16.219 10.3668 16.8521 10.3668 17.2426 10.7574Z" fill="#AA0000"/>
        </svg>

    );
};

export default NotificationErrorOval;