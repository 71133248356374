import React from 'react';
import PropTypes from "prop-types";
import { Transition } from 'react-transition-group';
import { blue60 } from "../vars.js";
import './Preloader.scss'

const duration = 300;

const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
}

const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
};


const Preloader = props => {
    const { show } = props;
    return (
        <>
            {show &&
                <Transition in={show} timeout={duration}>

                    <div
                        _ngcontent-votings-c1=""
                        className="ngx-overlay"
                        style={{ backgroundColor: '#fff', borderRadius: '0px' }}
                    >
                        <div
                            _ngcontent-votings-c1=""
                            className="ngx-foreground-spinner center-center"
                            style={{ color: '#09d3ac', width: '150px', height: '150px', top: '50%' }}
                        >
                            <div _ngcontent-votings-c1="" className="sk-three-strings">

                                <div _ngcontent-votings-c1=""></div>
                                <div _ngcontent-votings-c1=""></div>
                                <div _ngcontent-votings-c1=""></div>
                            </div>
                        </div>
                        <div
                            _ngcontent-votings-c1=""
                            className="ngx-loading-text center-center"
                            style={{ top: '50%', color: 'rgb(255, 255, 255)' }}
                        ></div>
                    </div>

                </Transition>
            }
        </>
    );
};

Preloader.propTypes = {
    show: PropTypes.bool
};

Preloader.defaultProps = {
    show: false,
};


export default Preloader;
