import React from 'react';
import {blue60} from "../../vars.js";

const NavigationKebabDouble = ({ width = 8, height = 12, color = blue60, className = '' }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path fillRule="evenodd" clipRule="evenodd" d="M1.375 0C0.75368 0 0.25 0.50368 0.25 1.125C0.25 1.74632 0.75368 2.25 1.375 2.25C1.99632 2.25 2.5 1.74632 2.5 1.125C2.5 0.50368 1.99632 0 1.375 0ZM1.375 4.875C0.75368 4.875 0.25 5.37868 0.25 6C0.25 6.62132 0.75368 7.125 1.375 7.125C1.99632 7.125 2.5 6.62132 2.5 6C2.5 5.37868 1.99632 4.875 1.375 4.875ZM5.5 1.125C5.5 0.50368 6.00368 0 6.625 0C7.24632 0 7.75 0.50368 7.75 1.125C7.75 1.74632 7.24632 2.25 6.625 2.25C6.00368 2.25 5.5 1.74632 5.5 1.125ZM6.625 4.875C6.00368 4.875 5.5 5.37868 5.5 6C5.5 6.62132 6.00368 7.125 6.625 7.125C7.24632 7.125 7.75 6.62132 7.75 6C7.75 5.37868 7.24632 4.875 6.625 4.875ZM5.5 10.875C5.5 10.2537 6.00368 9.75 6.625 9.75C7.24632 9.75 7.75 10.2537 7.75 10.875C7.75 11.4963 7.24632 12 6.625 12C6.00368 12 5.5 11.4963 5.5 10.875ZM1.375 9.75C0.75368 9.75 0.25 10.2537 0.25 10.875C0.25 11.4963 0.75368 12 1.375 12C1.99632 12 2.5 11.4963 2.5 10.875C2.5 10.2537 1.99632 9.75 1.375 9.75Z" fill={color}/>
        </svg>

    );
};

export default NavigationKebabDouble;