import React from 'react';
import {blue60} from "../../vars.js";

const MapPin = ({width = 14, height = 17, className = '', color = blue60 }) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7 0C3.13401 0 0 3.13401 0 7C0 9.92579 1.79499 12.4323 4.34381 13.4785C6.15787 14.3295 6.69774 15.9788 6.90054 16.86C6.92161 16.9374 6.95476 17 7 17C7.04524 17 7.08244 16.9374 7.11161 16.86C7.31539 15.9871 8.05696 14.2572 9.73367 13.4461C12.2413 12.3813 14 9.89605 14 7C14 3.13401 10.866 0 7 0ZM9.75 7C9.75 8.51878 8.51878 9.75 7 9.75C5.48122 9.75 4.25 8.51878 4.25 7C4.25 5.48122 5.48122 4.25 7 4.25C8.51878 4.25 9.75 5.48122 9.75 7Z" fill={color}/>
        </svg>
    );
};

export default MapPin;