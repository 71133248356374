import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { initializeModals, hideModal } from "../store/actions/ModalActions";
import * as types from "../store/actions/ModalConstants";
// import GibddModal from "../modals/GibddModal";
// import StsModal from "../modals/StsModal";
// import FlatCountersNewModal from "../modals/FlatCountersNewModal";
// import FlatPaymentNewModal from "../modals/FlatPaymentNewModal";
// import FlatAddServiceModal from "../modals/FlatAddServiceModal";
// import FlatEditServiceModal from "../modals/FlatEditServiceModal";
// import SubscriptionUslugiModal from "../modals/SubscriptionUslugiModal";
// import ChargeReceiptModal from "../modals/ChargeReceiptModal";
// import ConfirmModal from "../modals/ConfirmModal";
import DeleteModal from "../common/modals/deleteModal/DeleteModal";
import TgLinkModal from "../pages/Settings/modals/tgLinkModal";
// import ChildrenHistoryModal from "../modals/ChildrenHistoryModal";
// import AlertModal from "../modals/AlertModal";
// import GibddAdvanceModal from "../components/Widgets/Dashboard/Car/modals/GibddAdvanceModal";
// import GibddDetailsModal from "../components/Widgets/Dashboard/Car/modals/GibddDetailsModal";
// import IsppPaymentModal from "../components/Widgets/Dashboard/Ispp/components/modals/IsppPaymentModal";
// import EzhdHistoryModal from "../modals/EzhdHistoryModal";
// import EzhdHomeworkModal from "../modals/EzhdHomeworkModal";
// import CarAddServiceModal from "../modals/CarAddServiceModal";
// import NotificationsDetailModal from "../modals/NotificationsDetailModal";
// import MgtsPaymentModal from "../modals/MgtsPaymentModal";
// import AddressInputModal from "../modals/AddressInputModal"
// import DriverLicenseInputModal from "../modals/DriverLicenseInputModal";
// import PassportRfInputModal from "../modals/PassportRfInputModal";
// import BirthCertInputModal from "../modals/BirthCertInputModal";
// import CreateFamilyMemberModal from "../modals/CreateFamilyMemberModal";
// import SaveEducationItemModal from "../modals/SaveEducationItemModal";
// import MechanicsModal from "../modals/MechanicsModal";
// import VuAddServiceModal from '../modals/VuAddServiceModal';
// import TrusteeModal from "../components/Widgets/Sidebar/WidgetTrustee/components/modals/trusteeModal";
// import HealthWidgetAddModal from '../modals/HealthWidgetAddModal';
// import AllchargesWidgetPrepareModal from '../modals/AllchargesWidgetPrepareModal';
// import AllchargesWidgetListModal from '../modals/AllchargesWidgetListModal';
// import AllchargesWidgetNotFilledNotice from '../modals/AllchargesWidgetNotFilledNotice';
// import AddProfileDataForSnilsModal from '../modals/AddProfileDataForSnilsModal';
// import HealthWidgetFavoritesModal from '../modals/HealthWidgetFavoritesModal';
// import VuExpiringModal from "../modals/VuExpiringModal";
// import HealthWidgetRecordsModal from '../modals/HealthWidgetRecordsModal';
// import InfoModal from "../modals/InfoModal";
// import EvacuationModal from "../modals/EvacuationModal";
// import CheckupCountersModal from "../modals/CheckupCountersModal";
// import PfrDetailModal from "components/Widgets/Dashboard/Pfr/components/modals/PfrDetailModal";
// import PfrAddServiceModal from "components/Widgets/Dashboard/Pfr/components/modals/PfrAddServiceModal";
// import GibddFinesModal from "../modals/GibddFinesModal";
// import GibddFinesModalConfirm from "modals/GibddFinesModalConfirm";
// import SportClubsDetailModal from "../../frontend/components/Widgets/Dashboard/SportsClubs/modals/SportClubsDetailModal";
// import SportsClubsNotificationModal from "../../frontend/components/Widgets/Dashboard/SportsClubs/modals/SportsClubsNotificationModal";
// import PrivateDataModal from "../modals/PrivateDataModal";
// import PassportInputModal from "../modals/PassportInputModal";
// import emptyModal from "../modals/emptyModal"
// import SessionExpiredModal from "modals/SessionExpiredModal";
// import EducationDetailModal from "../components/Widgets/Dashboard/Education/modals/EducationDetailModal";
// import OlympiadParticipantModal from "../components/Widgets/Dashboard/Education/modals/OlympiadParticipantModal";
// import GiaParticipantModal from "../components/Widgets/Dashboard/Education/modals/GiaParticipantModal";
// import CDPCloseModal from '../../frontend/components/Widgets/CDP/components/modals/CDPCloseModal'
// import ACPoolsModal from "../components/Widgets/Dashboard/ActiveCitizen/modals/ACPoolsModal";
// import ACDiscussionsModal from "../components/Widgets/Dashboard/ActiveCitizen/modals/ACDiscussionsModal";
// import ACAlertModal from "../components/Widgets/Dashboard/ActiveCitizen/modals/ACAlertModal";
// import ModalAddFavourites from '../components/Widgets/Dashboard/Favourites/components/modals/ModalAddFavourites';
// import ModalAllFavourites from '../components/Widgets/Dashboard/Favourites/components/modals/ModalAllFavourites';
// import DocumetRequisitesModal from "../modals/DocumentRequisitesModal";
// import TroykaEditModal from '../components/Widgets/Main/Settings/Troyka/components/modals/TroykaEditModal';
// import LegalizationModal
//     from "components/Widgets/Main/Settings/Legalization/components/legalizationModal/LegalizationModal";
// import AddSnilsModal from "../modules/Popups/Snils/AddSnilsModal";

// import BackDrop from "frontend/storybook/common/modals/backDrop/BackDrop.jsx";
// import ChedRebindModal from "components/Common/Form/Ched/modals/ChedRebindModal.js";
// import ChedDeletingModal from "components/Common/Form/Ched/modals/ChedDeletingModal.js";
// import MessageDeleteModal from "../components/Pages/MessagePage/MessageDeleteModal"
// import ChedAddingModal from "components/Common/Form/Ched/modals/ChedAddingModal.js";
// import ChedFilterModal from "components/Common/Form/Ched/modals/ChedFilterModal.jsx";
// import ChedBackdropMobileModal from "components/Common/Form/Ched/modals/ChedBackdropMobileModal.jsx";
// import SnilsModal from "components/Widgets/Main/Settings/Documents/blocks/modals/snilsModal/SnilsModal.jsx";
// import ForeignPassportModal
//     from "../components/Widgets/Main/Settings/Documents/blocks/modals/foreignPassportModal/ForeignPassportModal.jsx";
// import InternationalPassportModal
//     from "../components/Widgets/Main/Settings/Documents/blocks/modals/internationalPassportModal/InternationalPassportModal.jsx";
// import PassportRfModal
//     from "../components/Widgets/Main/Settings/Documents/blocks/modals/passportRFModal/PassportRFModal.jsx";
// import OMSModal from "components/Widgets/Main/Settings/Documents/blocks/modals/OMSModal/OMSModal.jsx";
// import INNModal from "components/Widgets/Main/Settings/Documents/blocks/modals/INNModal/INNModal.jsx";
// import VUModal from "components/Widgets/Main/Settings/Documents/blocks/modals/VUModal/VUModal.jsx";
// import LibraryModal from "components/Widgets/Main/Settings/Documents/blocks/modals/libraryModal/LibraryModal.jsx";
// import EsiaModal from "components/Widgets/Main/Settings/Profile/blocks/modals/ESIAModal/ESIAModal.jsx";
// import DeputyModal from "components/Widgets/Main/Settings/Documents/blocks/modals/deputyModal/DeputyModal.jsx";
import ModalNotification from "../common/modals/modalNotification/ModalNotification.jsx";
// import ConfirmDeputyModal
//     from "components/Widgets/Main/Settings/Documents/blocks/modals/confirmDeputyModal/ConfirmDeputyModal.jsx";
// import EmailSmsModal from "components/Widgets/Main/Settings/Profile/blocks/modals/emailSmsModal/EmailSmsModal.jsx";
// import RegAddressModal from "components/Widgets/Main/Settings/Documents/blocks/modals/regAddressModal/RegAddressModal.jsx";
// import AddressModal from "components/Widgets/Main/Settings/Documents/blocks/modals/addressModal/AddressModal.jsx";
// import RegAddressVerificationModal
//     from "components/Widgets/Main/Settings/Documents/blocks/modals/regAddressVerificationModal/RegAddressVerificationModal.jsx";
import { deepFind } from "../Helpers/Common.js";
// import HealthWidgetReferral2Rec from "modals/HealthWidgetReferral2Rec.js";
// import ApplicationNotificationModal from "modals/ApplicationNotificationModal.js";
// import ContactsModal from "../components/Widgets/Main/Settings/Profile/blocks/modals/contactsModal/ContactsModal";
// import QR_IMMUNE_MODAL from "modals/QR_IMMUNE_MODAL/QR_IMMUNE_MODAL.js";
// import HealthWidgetChooseRecipesModal from "../modals/HealthWidgetChooseRecipesModal.js";
// import HealthWidgetReferralsModal from "../modals/HealthWidgetReferralsModal";
// import HealthWidgetRecordOnMap from "modals/HealthWidgetRecordOnMap"
// import AddPetAddressModal from "components/Widgets/Main/Settings/Pets/components/modals/AddPetAddressModal";
// import AddPetOwnerModal from "components/Widgets/Main/Settings/Pets/components/modals/AddPetOwnerModal";
// import AddPetPhotoModal from "components/Widgets/Main/Settings/Pets/components/modals/AddPetPhotoModal";
// import IspkModal from "components/Decorators/ISPK/components/modals/ispkModal/IspkModal";
// import NonSavedChangesModal from "modals/nonSavedChangesModal/NonSavedChangesModal";
// import FirstGreetingsModal from "modals/FirstGreetingsModal";
// import DegModal from "../components/Widgets/Dashboard/Deg/modals/DegModal"
// import {MODAL_TYPE_CONFIRMING_PASSPORT_CHANGE} from "../actions/ModalConstants";
// import ConfirmChangePassportModal
//     from "components/Widgets/Main/Settings/Documents/blocks/modals/passportRFModal/components/confirmChangePassportModal/ConfirmChangePassportModal";


const MODAL_COMPONENTS = {
    // [types.MODAL_TYPE_GIBDD_ADD_STS]: GibddModal, //+
    // [types.MODAL_TYPE_ADD_STS]: StsModal, // обернул, нужно уточнить
    // [types.MODAL_TYPE_FLAT_SUBMIT_COUNTERS_DATA]: FlatCountersNewModal, //+
    // [types.MODAL_TYPE_FLAT_MAKE_PAYMENT]: FlatPaymentNewModal, //+
    // [types.MODAL_TYPE_FLAT_ADD_SERVICE]: FlatAddServiceModal, //+
    // [types.MODAL_TYPE_FLAT_EDIT_SERVICE]: FlatEditServiceModal,
    // [types.MODAL_TYPE_SUBSCRIPTION_USLUGI]: SubscriptionUslugiModal, //не используется
    // [types.MODAL_TYPE_CHARGE_RECEIPT]: ChargeReceiptModal,
    // [types.MODAL_TYPE_CONFIRM_POPUP]: ConfirmModal, //+
    // [types.MODAL_TYPE_CHILDREN_HISTORY]: ChildrenHistoryModal, //не используется, сделал новую обертку
    [types.MODAL_TYPE_DELETE_POPUP]: DeleteModal, //+
    [types.MODAL_TG_LINK]: TgLinkModal,
    // [types.MODAL_TYPE_ALERT]: AlertModal, //+
    // [types.MODAL_TYPE_SESSION_EXPIRED]: SessionExpiredModal, //+
    // [types.MODAL_TYPE_GIBDD_ADVANCE]: GibddAdvanceModal,
    // [types.MODAL_TYPE_GIBDD_DETAILS]: GibddDetailsModal,
    // [types.MODAL_TYPE_ISPP_PAYMENT]: IsppPaymentModal, //+
    // [types.MODAL_TYPE_EZHD_HISTORY]: EzhdHistoryModal,
    // [types.MODAL_TYPE_EZHD_HOMEWORK]: EzhdHomeworkModal,
    // [types.MODAL_TYPE_CAR_ADD_SERVICE]: CarAddServiceModal, //+
    // [types.MODAL_TYPE_VU_ADD_SERVICE]: VuAddServiceModal,
    // [types.MODAL_TYPE_NOTIFICATIONS_DETAIL]: NotificationsDetailModal,
    // [types.MODAL_TYPE_MGTS_PAYMENT]: MgtsPaymentModal,
    // [types.MODAL_TYPE_ADDRESS_INPUT]: AddressInputModal,
    // [types.MODAL_TYPE_DRIVER_LICENSE_INPUT]: DriverLicenseInputModal,
    // [types.MODAL_TYPE_PASSPORT_RF_INPUT]: PassportRfInputModal,
    // [types.MODAL_TYPE_BIRTH_CERT_INPUT]: BirthCertInputModal,
    // [types.MODAL_TYPE_CREATE_FAMILY_MEMBER]: CreateFamilyMemberModal, //+
    // [types.MODAL_TYPE_SAVE_EDUCATION_ITEM]: SaveEducationItemModal,
    // [types.MODAL_TYPE_PUSH_MECHANICS]: MechanicsModal,
    // [types.MODAL_TYPE_TRUSTEE]: TrusteeModal,
    // [types.MODAL_TYPE_HEALTH_WIDGET_CHOOSE_RECIPES_MODAL]: HealthWidgetChooseRecipesModal, //+
    // [types.MODAL_TYPE_HEALTH_WIDGET_ADD]: HealthWidgetAddModal, //+
    // [types.MODAL_TYPE_ALLCHARGES_WIDGET_PREPARE]: AllchargesWidgetPrepareModal, //+
    // [types.MODAL_TYPE_ALLCHARGES_WIDGET_LIST]: AllchargesWidgetListModal, //+
    // [types.MODAL_TYPE_ALLCHARGES_NOT_FILLED_NOTICE]: AllchargesWidgetNotFilledNotice, //+
    // [types.MODAL_TYPE_ADD_PROFILE_DATA_FOR_SNILS]: AddProfileDataForSnilsModal, // не ипользуется
    // [types.MODAL_TYPE_GIBDD_VU_EXPIRING]: VuExpiringModal,
    // [types.MODAL_TYPE_HEALTH_WIDGET_RECORDS]: HealthWidgetRecordsModal,
    // [types.MODAL_TYPE_INFO]: InfoModal,
    // [types.MODAL_TYPE_EVA_WIDGET_CAR]: EvacuationModal, // +
    // [types.MODAL_TYPE_CHECKUP_COUNTERS]: CheckupCountersModal, //+
    // [types.MODAL_TYPE_PFR_WIDGET_DETAIL]: PfrDetailModal,
    // [types.MODAL_TYPE_PFR_ADD_SERVICE]: PfrAddServiceModal, //+
    // [types.MODAL_TYPE_GIBDD_FINES]: GibddFinesModal,
    // [types.MODAL_TYPE_GIBDD_FINES_CONFIRM]: GibddFinesModalConfirm,
    // [types.MODAL_TYPE_SPORTS_CLUBS_DETAIL]: SportClubsDetailModal,
    // [types.MODAL_TYPE_SPORTS_CLUBS_NOTIFICATION]: SportsClubsNotificationModal,
    // [types.MODAL_TYPE_PRIVATE_DATA]: PrivateDataModal,
    // [types.MODAL_TYPE_PASSPORT_INPUT]: PassportInputModal,
    // [types.MODAL_TYPE_EMPTY]: emptyModal, //+
    // ['CDPCloseModal']: CDPCloseModal, //+
    // 'HealthWidgetReferral2Rec': HealthWidgetReferral2Rec, //+
    // 'HealthWidgetReferralsModal': HealthWidgetReferralsModal, //+,
    // 'HealthWidgetRecordOnMap' : HealthWidgetRecordOnMap,//+,
    // [types.MODAL_TYPE_HEALTH_WIDGET_FAVORITES]: HealthWidgetFavoritesModal,
    // [types.MODAL_TYPE_EDUCATION_WIDGET_DETAIL]: EducationDetailModal, // обернул Notification, не могу воспроизвести
    // [types.MODAL_TYPE_OLYMPIAD_FORM_PARTICIPANT]: OlympiadParticipantModal, //+
    // [types.MODAL_TYPE_GIA_FORM_PARTICIPANT]: GiaParticipantModal, //+
    // [types.MODAL_TYPE_AC_POOLS_DETAIL]: ACPoolsModal, //+
    // [types.MODAL_TYPE_AC_DISCUSSIONS_DETAIL]: ACDiscussionsModal,
    // [types.MODAL_TYPE_ACTIVE_CITIZEN_ALERT]: ACAlertModal, //+
    // [types.MODAL_TYPE_FAVOURITES_ADD]: ModalAddFavourites, //+
    // [types.MODAL_TYPE_FAVOURITES_SHOW_ALL]: ModalAllFavourites,
    // [types.MODAL_TYPE_DOCUMENT_REQUISITES]: DocumetRequisitesModal,
    // [types.MODAL_TYPE_TROYKA_EDIT]: TroykaEditModal, // +
    // [types.MODAL_TYPE_LEGALIZATION]: LegalizationModal,
    // [types.MODAL_TYPE_POPUP_ADD_SNILS]: AddSnilsModal, //+
    // [types.MODAL_CHED_REBIND]: ChedRebindModal, // +
    // [types.MODAL_CHED_DELETING]: ChedDeletingModal, // +
    // [types.MODAL_CHED_ADDING]: ChedAddingModal, // +
    // [types.MODAL_CHED_ADD_IMAGE]: AddPetPhotoModal,
    // [types.MODAL_BACKDROP]: BackDrop, // +
    // [types.MODAL_CHED_FILTER_MOBILE]: ChedFilterModal, // +
    // [types.MODAL_CHED_BACKDROP_MOBILE]: ChedBackdropMobileModal, // +
    // [types.MODAL_ADD_SNILS]: SnilsModal, // +
    // [types.MODAL_ADD_FOREIGN_PASSPORT]: ForeignPassportModal, // +
    // [types.MODAL_ADD_INTERNATIONAL_PASSPORT]:InternationalPassportModal, // +
    // [types.MODAL_ADD_PASSPORT_RF]: PassportRfModal, // +
    // [types.MODAL_ADD_OMS]: OMSModal, // +
    // [types.MODAL_ADD_INN]: INNModal, // +
    // [types.MODAL_ADD_VU]: VUModal, // +
    // [types.MODAL_ADD_LIBRARY_TICKET]: LibraryModal, // +
    // [types.MODAL_ADD_DEPUTY]: DeputyModal, // +
    // [types.MODAL_TYPE_ESIA_EDIT]: EsiaModal, // +
    // [types.MODAL_TYPE_CONTACTS]: ContactsModal, // +
    [types.MODAL_NOTIFICATION]: ModalNotification, // +
    // [types.MODAL_CONFIRM_DEPUTY]: ConfirmDeputyModal, // +
    // [types.MODAL_ADD_REG_ADDRESS]: RegAddressModal, // +
    // [types.MODAL_ADD_ADDRESS]: AddressModal, // +
    // [types.MODAL_TYPE_MESSAGES_DELETE]: MessageDeleteModal, // +
    // [types.MODAL_VERIFY_REG_ADDRESS]: RegAddressVerificationModal, // +
    // [types.MODAL_EMAIL_SMS_FORM]: EmailSmsModal, // +
    // [types.MODAL_APPLICATION_INFO]: ApplicationNotificationModal, // +
    // [types.QR_IMMUNE_MODAL]: QR_IMMUNE_MODAL, // +
    // [types.MODAL_TYPE_ISPK]: IspkModal, // +
    // [types.MODAL_TYPE_NON_SAVED_CHANGES_MODAL]: NonSavedChangesModal, // +
    // [types.QR_IMMUNE_MODAL]: QR_IMMUNE_MODAL,
    // [types.MODAL_ADD_PET_ADDRESS]: AddPetAddressModal,
    // [types.MODAL_ADD_PET_OWNER]: AddPetOwnerModal,
    // [types.MODAL_TYPE_FIRST_GREETINGS]:FirstGreetingsModal, // +
    // ["DEG_MODAL"]: DegModal,
    // [types.MODAL_TYPE_CONFIRMING_PASSPORT_CHANGE]: ConfirmChangePassportModal,

};

const ModalRoot = (props) => {
    const {
        dispatch,
        modalState,
    } = props;

    const prepareModals = (rawModals) => {
        const modals = {};
        Object.entries(rawModals).forEach(([key, value]) => {
            modals[key] = {
                component: value,
                isShow: null,
                props: {},
            }
        });
        return modals;
    }

    const onHide = (name) => {
        dispatch(hideModal(name));
    }

    useEffect(() => {
        dispatch(initializeModals(prepareModals(MODAL_COMPONENTS)));
    }, []);

    return Object.entries(modalState.modals)
        .filter(([key, value]) => value.isShow !== null)
        .map(([key, value]) => (
            <value.component
                key={key}
                onHide={() => onHide(key)}
                {...value.props}
                isShow={value.isShow}
            />
        ));

}

const mapState = state => ({ modalState: state.modalState });



// Костыль для навешивания zIndex на модалки

const target = document.querySelector('body');

// Конфигурация observer (за какими изменениями наблюдать)
const config = {
    childList: true,
};

// Колбэк-функция при срабатывании мутации
let scrollPosition = null;
const body = document.body;
const htmlNode = document.documentElement;

const callback = function (mutationsList, observer) {
    const startZIndex = 9998;
    if (mutationsList.length) {
        let elements = document.querySelectorAll('body > div');
        if (elements) {
            elements = Array.from(elements);
        }
        elements = elements.filter(el => el.classList.contains('modal-backdrop') || deepFind(el, 'attributes.role.value') === 'dialog');
        const modals = elements.filter(el => deepFind(el, 'attributes.role.value') === 'dialog');

        if (modals.length && scrollPosition === null) {
            scrollPosition = window.scrollY;
            if (body.classList.contains('modal-open')) {
                htmlNode.classList.add('no-scroll')
            }
            document.documentElement.style.top = -scrollPosition + 'px';
        } else if (modals.length === 0 && scrollPosition !== null) {
            htmlNode.classList.remove('no-scroll');
            document.documentElement.scrollTop = scrollPosition;
            document.documentElement.style = null;
            scrollPosition = null;
        }

        elements.forEach((el, ind) => {
            el.style.zIndex = startZIndex + ind;
        });

    }
};

// Создаём экземпляр наблюдателя с указанной функцией колбэка
const observer = new MutationObserver(callback);

// Начинаем наблюдение за настроенными изменениями целевого элемента
observer.observe(target, config);

export default connect(mapState)(ModalRoot);
