import React, { useState } from 'react';

import './ButtonBack.scss';
import PropTypes from 'prop-types';
import Icon from "../icon/Icon";
import Link from "../link/Link";

const ButtonBack = ({ to }) => {
    return <Link to={to} routerLink={true} isUnderlined={false} customClass="button-back">
        <Icon icon="Back" width={81} height={8} /> Назад
    </Link>
};

export default ButtonBack;


ButtonBack.propTypes = {
    to: PropTypes.string,
    // labelBalloonContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

ButtonBack.defaultProps = {
    to: false,
};
