import React from 'react';
import {blue60} from "../../vars";

const Delete = ({ width = 13, height = 15, className = '', color = blue60 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.33325 2.16667C5.33325 1.93655 5.5198 1.75 5.74992 1.75H8.24992C8.48004 1.75 8.66658 1.93655 8.66658 2.16667V3H5.33325V2.16667ZM4.08325 3V2.16667C4.08325 1.24619 4.82944 0.5 5.74992 0.5H8.24992C9.17039 0.5 9.91658 1.24619 9.91658 2.16667V3H12.6666C13.2189 3 13.6666 3.44772 13.6666 4V4.5C13.6666 5.02848 13.2566 5.46121 12.7374 5.49753L11.6854 14.6146C11.6272 15.1192 11.1999 15.5 10.692 15.5H3.30784C2.7999 15.5 2.37265 15.1192 2.31443 14.6146L1.26245 5.49753C0.743207 5.46121 0.333252 5.02848 0.333252 4.5V4C0.333252 3.44772 0.780967 3 1.33325 3H4.08325ZM2.50199 5.5H11.4978L10.6931 13.3519C10.6409 13.8621 10.2112 14.25 9.69836 14.25H4.30148C3.78867 14.25 3.35897 13.8621 3.30669 13.3519L2.50199 5.5ZM5.54159 7.16667C5.19641 7.16667 4.91659 7.44649 4.91659 7.79167V11.5417C4.91659 11.8868 5.19641 12.1667 5.54159 12.1667C5.88676 12.1667 6.16659 11.8868 6.16659 11.5417V7.79167C6.16659 7.44649 5.88676 7.16667 5.54159 7.16667ZM7.83325 7.79167C7.83325 7.44649 8.11307 7.16667 8.45825 7.16667C8.80343 7.16667 9.08325 7.44649 9.08325 7.79167V11.5417C9.08325 11.8868 8.80343 12.1667 8.45825 12.1667C8.11307 12.1667 7.83325 11.8868 7.83325 11.5417V7.79167Z" fill={color}/>
        </svg>

    );
};

export default Delete;



