import React from 'react';
import {blue60} from "../../vars";

const RatingEmpty = ({width = 18, height = 18, color = blue60, className = ''}) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.42726 0.776103L11.7799 5.50037C11.8498 5.64623 11.9829 5.74745 12.1369 5.77027L17.0933 6.65241C17.4834 6.71095 17.6383 7.21603 17.3549 7.5028L13.8454 11.2378C13.733 11.3529 13.681 11.5196 13.7075 11.6813L14.4744 16.9166C14.5414 17.3224 14.1363 17.633 13.7887 17.4425L9.2242 15.1136C9.08726 15.0392 8.92387 15.0392 8.78692 15.1136L4.2177 17.4425C3.8692 17.633 3.46498 17.3224 3.53203 16.9166L4.30837 11.6783C4.33576 11.5156 4.28381 11.3499 4.17048 11.2348L0.64581 7.5028C0.361532 7.21603 0.516421 6.71095 0.907421 6.65241L5.86103 5.75935C6.01498 5.73653 6.14815 5.63532 6.21709 5.48945L8.57915 0.776103C8.75387 0.407966 9.25253 0.407966 9.42726 0.776103ZM7.57323 6.13044C7.29259 6.7242 6.73663 7.14594 6.12717 7.23555L2.99022 7.80067L5.23933 10.1824C5.69182 10.6419 5.89444 11.2922 5.79216 11.8982L5.30171 15.2066L8.07069 13.7957C8.65428 13.4785 9.35685 13.4785 9.90592 13.7775L12.7081 15.2077L12.2271 11.9233C12.1242 11.2937 12.3252 10.6476 12.7522 10.2106L15.0127 7.80543L11.9169 7.25405C11.2632 7.15714 10.7095 6.73794 10.4371 6.16902L9.00021 3.28353L7.57323 6.13044Z" fill={color} />
        </svg>
    );
};

export default RatingEmpty;