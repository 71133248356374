import React from 'react';
import {blue60} from "../../vars";

const UserAuthorized = ({width = 16, height = 10, color = blue60, className = ''}) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.00789 10.5973C7.85113 10.5973 8.54786 10.5079 9.12348 10.3469C9.62454 10.2067 10.1768 10.4207 10.5933 10.7324C11.2155 11.1981 13.5 12.0543 13.5 13.5779V14.5418C13.5 15.044 13.0673 15.4615 12.5069 15.5H1.50737C0.960912 15.4619 0.533905 15.0628 0.515936 14.5733L0.5 13.5779C0.5 12.0543 2.80025 11.1981 3.42245 10.7324C3.83806 10.4214 4.38867 10.2065 4.8886 10.3463C5.46473 10.5075 6.16272 10.5973 7.00789 10.5973ZM7 0C9.70483 0 10.5 1.99622 10.5 4.5C10.5 7.00378 9.5116 9 7 9C4.4884 9 3.5 7.00073 3.5 4.5C3.5 1.99927 4.29517 0 7 0Z" fill={color}/>        </svg>
    );
};

export default UserAuthorized;