import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import InputMask from 'react-input-mask';
import { Field } from "redux-form";

import FieldError from "../fiedlError/FieldError";
import BalloonHelp from "../balloonHelp/BalloonHelp";

import "./TextField.scss";
import Icon from "../icon/Icon.jsx";
import { blue60 } from "../vars.js";
import Checkbox from "../checkbox/Checkbox";
import FormBlock from "../formBlock/FormBlock";

const RenderField = (props) => {
    const {
        input,
        meta,
        id,
        label,
        disabled,
        info,
        mask,
        maskChar,
        multiline,
        rows,
        labelBalloonContent,
        infoBalloonContent,
        endIcon,
        size,
        maxLength,
        normalize,
        formatChars,
        inputRef,
        onChangeCallback,
        onTouchedChange,
        customError,
        beforeMaskedValueChange,
        type,
        isValid,
        showPassword,
        heightAuto,
        readOnly,
    } = props;
    const { error, touched } = meta;

    const onChange = (e) => {
        let value = e.target.value;
        if (normalize instanceof Function) {
            e.target.value = normalize(value);
        }
        input.onChange(e);
    }

    const showPasswordFunc = () => {
        const currentInput = inputRef.current
        if (currentInput.getAttribute('type') == 'password') {
            currentInput.setAttribute('type', 'text');
        } else {
            currentInput.setAttribute('type', 'password');
        }
        return false;
    }

    useEffect(() => {
        if (onChangeCallback instanceof Function) {
            onChangeCallback(input.value);
        }
    }, [input.value]);

    useEffect(() => {
        if (onTouchedChange instanceof Function && meta.touched) {
            onTouchedChange(input.value);
        }
    }, [input.value]);

    const isError = touched && (error || customError);


    return (
        <div
            className={`nlk-textfield ${disabled ? 'nlk-textfield_disabled' : ''} ${isError ? 'nlk-textfield_error' : ''} ${size ? `nlk-textfield_${size}` : ''}`}
        >
            <label
                htmlFor={id}
                className={`nlk-textfield__label ${info ? 'nlk-textfield__label-margin-4' : 'nlk-textfield__label-margin-8'}`}
            >
                {label} {labelBalloonContent ? <BalloonHelp content={labelBalloonContent} /> : null}
                {isValid ? <span className="nlk-textfield__isValid"><Icon icon="Checkbox/Check" color={blue60} width={10} height={8} /></span> : null}
            </label>
            {info ? <div className="nlk-textfield__info">{info} {infoBalloonContent ? <BalloonHelp content={infoBalloonContent} /> : null}</div> : null}
            {isError
                ? <FieldError error={error || customError} />
                : null
            }
            <div className="nlk-textfield__input-wrapper">
                {multiline ?
                    <textarea
                        {...input}
                        id={id}
                        className={`nlk-textfield__input nlk-textfield__input_multiline ${heightAuto ? 'nlk-textfield__input--heightAuto' : ''}`}
                        disabled={disabled}
                        rows={rows}
                        maxLength={maxLength}
                        onChange={onChange}
                        ref={inputRef}
                        readOnly={readOnly}
                    /> :
                    !mask ?
                        <input
                            {...input}
                            id={id}
                            className="nlk-textfield__input"
                            disabled={disabled}
                            maxLength={maxLength}
                            onChange={onChange}
                            ref={inputRef}
                            type={type}
                            readOnly={readOnly}
                        /> :
                        <InputMask
                            {...input}
                            id={id}
                            className="nlk-textfield__input"
                            disabled={disabled}
                            mask={mask}
                            maskChar={maskChar}
                            onChange={onChange}
                            formatChars={formatChars}
                            inputRef={ref => inputRef.current = ref}
                            beforeMaskedValueChange={beforeMaskedValueChange}
                            type={type}
                            readOnly={readOnly}
                        />
                }
                {endIcon ?
                    <div className="nlk-textfield__endIcon">{endIcon}</div> :
                    null
                }

                {showPassword && type === 'password' ?
                    <FormBlock customMarginTop={16}>
                        <Checkbox
                            label="Показать пароль"
                            name="SHOW_PASSWORD"
                            id="SHOW_PASSWORD"
                            onClick={() => showPasswordFunc()}
                        />
                    </FormBlock> : null
                }
            </div>
        </div>
    )
}


const TextField = (props) => {
    const {
        id,
        name,
        type,
        label,
        onFocus,
        onBlur,
        validate,
        disabled,
        info,
        mask,
        maskChar,
        formatChars,
        multiline,
        rows,
        labelBalloonContent,
        infoBalloonContent,
        endIcon,
        size,
        maxLength,
        normalize,
        inputRef,
        onChangeCallback,
        customError,
        beforeMaskedValueChange,
        isValid,
        showPassword,
        heightAuto,
        readOnly,
        autocomplete,
    } = props;


    const handlerFocus = e => {
        if (onFocus instanceof Function) {
            onFocus(e);
        }
    }

    const handleBlur = e => {
        if (onBlur instanceof Function) {
            onBlur(e);
        }
    }
    return (
        <Field
            component={RenderField}
            customError={customError}
            id={id}
            name={name}
            type={type}
            label={label}
            onFocus={handlerFocus}
            onBlur={handleBlur}
            validate={validate}
            disabled={disabled}
            info={info}
            mask={mask}
            maskChar={maskChar}
            formatChars={formatChars}
            multiline={multiline}
            rows={rows}
            labelBalloonContent={labelBalloonContent}
            infoBalloonContent={infoBalloonContent}
            endIcon={endIcon}
            size={size}
            maxLength={maxLength}
            normalize={normalize}
            inputRef={inputRef}
            onChangeCallback={onChangeCallback}
            beforeMaskedValueChange={beforeMaskedValueChange}
            isValid={isValid}
            showPassword={showPassword}
            heightAuto={heightAuto}
            readOnly={readOnly}
            autocomplete={autocomplete}
        />
    );
};


TextField.defaultProps = {
    type: 'text',
    onFocus: undefined,
    onBlur: undefined,
    validate: [],
    disabled: false,
    readOnly: false,
    info: null,
    labelBalloonContent: null,
    mask: null,
    maskChar: null,
    multiline: false,
    rows: 5,
    size: 'default',
    maxLength: undefined,
    inputRef: { current: null },
    customError: '',
    isValid: false,
    onTouchedChange: null,
    showPassword: false,
    heightAuto: false,
    autocomplete: true,
};

TextField.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['text', 'password']),
    label: PropTypes.string,
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    maxLength: PropTypes.number,
    labelBalloonContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    validate: PropTypes.arrayOf(PropTypes.func), // TODO не удалось найти правильный тип для этого свойства, но вроде и так неплохо
    disabled: PropTypes.bool,
    info: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    infoBalloonContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    mask: PropTypes.string,
    maskChar: PropTypes.string,
    formatChars: PropTypes.object,
    beforeMaskedValueChange: PropTypes.func,
    isValid: PropTypes.bool,
    showPassword: PropTypes.bool,
    heightAuto: PropTypes.bool,
    readOnly: PropTypes.bool,

    size: PropTypes.oneOf(['small', 'default', 'halfWidth', 'fullWidth']),

    multiline: PropTypes.bool,
    rows: PropTypes.number,

    onFocus: PropTypes.func,
    onBlur: PropTypes.func,

    normalize: PropTypes.func,

    endIcon: PropTypes.element,

    onChangeCallback: PropTypes.func,
    customError: PropTypes.string,
    onTouchedChange: PropTypes.func,
    autocomplete: PropTypes.bool,
};

export default TextField;
