import React, { useReducer } from 'react';
import Context from './frontend/utils/context';
import * as types from './frontend/store/actions/types';

import * as Reducer1 from './frontend/store/reducers/plain';
import * as AuthReducer from './frontend/store/reducers/auth';
import * as FormReducer from './frontend/store/reducers/form';
import checkAuth from "./frontend/store/actions/CheckAuthAction"
import Routes from './routes';

import Auth from './frontend/utils/auth';


const auth = new Auth()


const ContextState = () => {
    /*
        Plain Reducer
    */
    // const [stateReducer1, dispatchReducer1] = useReducer(Reducer1.Reducer1,
    //     Reducer1.initialState)


    // const handleDispatchTrue = () => {
    //     //    dispatchReducer1(type: "SUCCESS")
    //     //    dispatchReducer1(ACTIONS.SUCCESS)
    //     dispatchReducer1(ACTIONS.success())
    // }

    // const handleDispatchFalse = () => {
    //     //     dispatchReducer1(type: "FAILURE")
    //     //    dispatchReducer1(ACTIONS.FAILURE)
    //     dispatchReducer1(ACTIONS.failure())
    // }

    // /*
    //   Auth Reducer
    // */
    const [stateAuthReducer, dispatchAuthReducer] = useReducer(AuthReducer.AuthReducer,
        AuthReducer.initialState)


    const handleAuth = () => {
        // console.log(dispatchAuthReducer(checkAuth()))
        // this.props.dispatch(loginUser(request));
        dispatchAuthReducer(checkAuth())
    }
    // const handleLogin = () => {
    //     dispatchAuthReducer(ACTIONS.login_success())
    // }

    // const handleLogout = () => {
    //     dispatchAuthReducer(ACTIONS.login_failure())
    // }

    // const handleAddProfile = (profile) => {
    //     dispatchAuthReducer(ACTIONS.add_profile(profile))
    // }

    // const handleRemoveProfile = () => {
    //     dispatchAuthReducer(ACTIONS.remove_profile())
    // }



    /*
      Form Reducer
    */

    // const [stateFormReducer, dispatchFormReducer] = useReducer(FormReducer.FormReducer,
    //     FormReducer.initialState)

    // const handleFormChange = (event) => {
    //     dispatchFormReducer(ACTIONS.user_input_change(event.target.value))
    // };

    // const handleFormSubmit = (event) => {
    //     event.preventDefault();
    //     event.persist();
    //     dispatchFormReducer(ACTIONS.user_input_submit(event.target.useContext.value))
    // };

    // //Handle authentication from callback
    // const handleAuthentication = (props) => {
    //     if (props.location.hash) {
    //         auth.handleAuth()
    //     }
    // }

    return (
        <div>
            <Context.Provider
                value={{
                    //Reducer1
                    // stateProp1: stateReducer1.stateprop1,
                    // stateProp2: stateReducer1.stateprop2,
                    // dispatchContextTrue: () => handleDispatchTrue(),
                    // dispatchContextFalse: () => handleDispatchFalse(),

                    // //Form Reducer
                    // useContextChangeState: stateFormReducer.user_textChange,
                    // useContextSubmitState: stateFormReducer.user_textSubmit,
                    // useContextSubmit: (event) => handleFormSubmit(event),
                    // useContextChange: (event) => handleFormChange(event),

                    // //Auth Reducer
                    // CHE: stateAuthReducer.is_authenticated,
                    // profileState: stateAuthReducer.profile,
                    handleCheckAuth: () => handleAuth(),
                    profile: {},
                    // handleUserLogin: () => handleLogin(),
                    // handleUserLogout: () => handleLogout(),
                    // handleUserAddProfile: (profile) => handleAddProfile(profile),
                    // handleUserRemoveProfile: () => handleRemoveProfile(),

                    // //Handle auth
                    // handleAuth: (props) => handleAuthentication(props),
                    // authObj: auth

                }}>
                <Routes />
            </Context.Provider>
        </div>
    )
}


export default ContextState;