import React, {useState} from 'react';

import './Link.scss';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';


const Link = ({children, href, onClick, customClass, routerLink, activeClassName, target, to, isUnderlined, download, exact}) => {

    const [isPressed, setPressed] = useState(false);

    const handleClick = (e) => {
        if (!isPressed) {
            setPressed(true);
        }
        if (onClick instanceof Function) {
            onClick(e);
        }
        setTimeout(() => {
            setPressed(false);
        }, 1000);
    }
    return <>
        {routerLink
            ? <NavLink
                onClick={handleClick}
                className={`nlk-link ${customClass} ${!isUnderlined ? 'nlk-link_underline' : ''}`}
                activeClassName={activeClassName}
                to={to}
                end={exact}
                download={download}
            >
                {children}


            </NavLink>
            : <a onClick={handleClick}
                 className={`nlk-link ${isPressed ? 'nlk-link_pressed' : ''} ${customClass} ${!isUnderlined ? 'nlk-link_underline' : ''}`}
                 href={href}
                 rel="noreferrer"
                 target={target}
                 download={download}
            >
                {children}
            </a>
        }
    </>
};

export default Link;


Link.propTypes = {
    customClass: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    onClick: PropTypes.func,
    href: PropTypes.string,
    to: (props, propName, componentName) => {
        if (props['routerLink'] && !props[propName]) {
            return new Error(`Invalid prop '${propName}' '${props[propName]}' supplied to '${componentName}'.`);
        }
    },
    routerLink: PropTypes.bool,
    exact: PropTypes.bool,
    activeClassName: PropTypes.string,
    target: PropTypes.string,
    download: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

Link.defaultProps = {
    customClass: '',
    isUnderlined: true,
    onClick: undefined,
    href: '#',
    routerLink: false,
    activeClassName: 'nlk-link_pressed',
    target: '_blank',
    download: false,
    exact: false,
};
