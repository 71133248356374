import React from 'react';
import {blue60} from "../../vars";

const Lock = ({ width = 14, height = 16, className = '', color = blue60 }) => {
    return (
        <svg  className={className} width={width} height={height} viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4 6H10V4.5C10 2.84315 8.65685 1.5 7 1.5C5.34315 1.5 4 2.84315 4 4.5V6ZM11.5 6H12C13.1046 6 14 6.89543 14 8V14C14 15.1046 13.1046 16 12 16H2C0.89543 16 0 15.1046 0 14V8C0 6.89543 0.895431 6 2 6H2.5V4.5C2.5 2.01472 4.51472 0 7 0C9.48528 0 11.5 2.01472 11.5 4.5V6ZM7 9C6.44772 9 6 9.44772 6 10V12C6 12.5523 6.44772 13 7 13C7.55228 13 8 12.5523 8 12V10C8 9.44772 7.55228 9 7 9Z" fill={color}/>
        </svg>
    );
};

export default Lock;