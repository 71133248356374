import React from 'react';
import {warning60} from "../../vars.js";

const NotificationWarning = ({width = 16, height = 16, color=warning60 ,className}) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8 16C3.5888 16 0 12.4112 0 8C0 3.5888 3.5888 0 8 0C12.4112 0 16 3.5888 16 8C16 12.4112 12.4112 16 8 16ZM9 10.9969C9 11.1348 8.97444 11.2654 8.92331 11.3887C8.87219 11.512 8.80164 11.6186 8.71166 11.7085C8.62168 11.7983 8.51636 11.8694 8.39571 11.9216C8.27505 11.9739 8.14315 12 8 12C7.86094 12 7.73006 11.9739 7.60736 11.9216C7.48466 11.8694 7.37832 11.7983 7.28834 11.7085C7.19836 11.6186 7.12781 11.512 7.07669 11.3887C7.02556 11.2654 7 11.1348 7 10.9969C7 10.8548 7.02556 10.7231 7.07669 10.6019C7.12781 10.4807 7.19836 10.3751 7.28834 10.2853C7.37832 10.1954 7.48466 10.1254 7.60736 10.0752C7.73006 10.0251 7.86094 10 8 10C8.14315 10 8.27505 10.0251 8.39571 10.0752C8.51636 10.1254 8.62168 10.1954 8.71166 10.2853C8.80164 10.3751 8.87219 10.4807 8.92331 10.6019C8.97444 10.7231 9 10.8548 9 10.9969ZM8.75 8.25C8.75 8.66421 8.41421 9 8 9C7.58579 9 7.25 8.66421 7.25 8.25V3.75C7.25 3.33579 7.58579 3 8 3C8.41421 3 8.75 3.33579 8.75 3.75V8.25Z" fill={color}/>
        </svg>
    );
};

export default NotificationWarning;