import axios from 'axios';
// import { API } from '../actions/types';
import { apiActionHook, apiError } from '../store/actions/api';
import Cookies from 'universal-cookie';
import moment from 'moment';
const cookies = new Cookies();
// const apiMiddleware = ({ dispatch }) => next => action => {
const apiMiddleware = (method, data, url, actions) => {
  // next(action);

  // if (action.type !== API) { // only apply middleware to actions of type API
  //   return;
  // }

  // const { url, method, data, onSuccess, onFailure, label } = action.payload;

  // Adds support to POST and PUT requests with data 
  const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data';

  // axios configs
  axios.defaults.baseURL = process.env.NODE_ENV !== 'production' ? 'http://localhost:9100/api' : 'https://ag-platform.ru/api';
  axios.defaults.headers.common['Content-Type'] = 'application/json';

  // if (label) {
  // dispatch(apiStart(label)); // Action to notify that the api call is starting.
  // dispatch(apiActionHook(actions[0], {})); // Action to notify that the api call is starting.
  // }

  let headers = {
    withCredentials: true,
  }
  const token = cookies.get('token')
  if (token) {
    headers['x-access-token'] = token
  }
  const errorMessage = (err) => {
    if (err.response && err.response.data) {
      if (err.response.headers['content-type'].indexOf('text/html') > -1) {
        return err.message
      } else {
        if (err.response.data.message) {
          return err.response.data.message;
        } else {
          return err.response.data;
        }
      }
    } else {
      return err.message;
    }
  }
  return axios
    .request({
      url,
      method,
      [dataOrParams]: data,
      headers: headers
    })
    .then(({ data }) => {
      return apiActionHook(actions[1], data)
    })
    .catch(error => {
      if (error.response && error.response.status == 401) {
        cookies.set('token', null, { path: '/', expires: moment().add(-12, 'hours').toDate() })
        window.location.href = '/'
      }
      // dispatch(apiError(error));
      // Original apiAction executor's error handler. e.g. Fn passed inside fetchPosts action. 
      return apiError(actions[2], { ...error, errorMessage: errorMessage(error) })
    })
  // .finally(() => {
  //   if (label) {
  //     // Action to notify that the api call has ended.
  //     dispatch(apiEnd(label));
  //   }
  // });
};

export default apiMiddleware;