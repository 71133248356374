import React from 'react';
import {blue60} from "../../vars.js";

const Edit = ({width = 14, height = 15, className = '', color = blue60}) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.35595 12.6146L0.3147 12.6284L0.527524 9.78619L8.27617 2.03754L11.1046 4.86596L3.35595 12.6146ZM11.8182 4.15241L8.98973 1.32399L9.6066 0.707108C9.99713 0.316584 10.6303 0.316584 11.0208 0.707108L12.435 2.12132C12.8256 2.51185 12.8256 3.14501 12.435 3.53554L11.8182 4.15241ZM0.75 13.5C0.335786 13.5 0 13.8358 0 14.25C0 14.6642 0.335786 15 0.75 15H13.25C13.6642 15 14 14.6642 14 14.25C14 13.8358 13.6642 13.5 13.25 13.5H0.75Z" fill={color} />
        </svg>
    );
};

export default Edit;