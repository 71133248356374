import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Context from '../utils/context';
import ModalRoot from '../models/ModalRoot';
import '../css/default.scss';
import 'normalize.css';
import '../css/vars.scss';


const defaultLayout = ({ children }) => {
    return (
        <>
            {children}
            <ModalRoot />
        </>

    )
};





export default defaultLayout;
