import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Routes, Router, Route, BrowserRouter, Navigate, Outlet, } from 'react-router-dom';
import history from './frontend/utils/history';
import Context from './frontend/utils/context';
import AuthCheck from './frontend/utils/authcheck';
import { createStore, applyMiddleware } from 'redux';
import { useSelector } from 'react-redux';
import DefaultLayout from './frontend/layouts/default';
import ProfileLayout from './frontend/layouts/profile';
import AuthUserReducer from './frontend/store/reducers/AuthUserReducer';
import thunk from 'redux-thunk';

import MainPage from './frontend/pages/Main';
import ProfilePage from './frontend/pages/Profile';
import ProjectsPage from './frontend/pages/Projects';
import ProjectPage from './frontend/pages/Projects/projectPage';
import Page404 from './frontend/pages/Page404';
import Settings from './frontend/pages/Settings';
import AuthRequire from "./frontend/Helpers/AuthRequire";
import checkAuth from "./frontend/store/actions/CheckAuthAction"
import Cookies from 'universal-cookie';
import axios from 'axios';
import { useDispatch } from "react-redux";
const store = createStore(AuthUserReducer, applyMiddleware(thunk));

const cookies = new Cookies();

// import Header from './frontend/hooks/header';
// import HooksContainer1 from './frontend/hooks/hook1';
// import Callback from './frontend/hooks/callbacks';
// import HooksForm from './frontend/hooks/hooks_form1';
// import PrivateComponent from './frontend/hooks/privatecomponent';
// import Profile from './frontend/hooks/profile';

const PrivateRoute = async ({ component: Component, path }) => {
    const checkTokenAuth = () => {
        return new Promise((resolve, reject) => {
            const token = cookies.get('token')
            if (!token) {
                reject(false)
            } else {
                axios.defaults.baseURL = process.env.NODE_ENV !== 'production' ? 'http://localhost:9100/api' : '';
                axios.defaults.headers.common['Content-Type'] = 'application/json';
                axios
                    .request({
                        url: '/verify_token',
                        methods: 'GET',
                        headers: {
                            'x-access-token': token
                        }
                    })
                    .then(({ data }) => {
                        if (data.auth == true) {
                            resolve(true)
                        } else {
                            reject(false)
                        }
                    })
                    .catch(error => {
                        reject(false)
                        // dispatch(apiError(error));
                        // Original apiAction executor's error handler. e.g. Fn passed inside fetchPosts action. 
                        // return apiError(actions[2], { ...error })
                    })
            }
        })

    }
    // const context = useContext(Context)
    // context.handleCheckAuth()    
    // authState
    // const context = useContext(Context)    
    // store.dispatch(checkAuth());
    // const { user: authUser } = useSelector(x => x.authState.authenticated);
    // console.log(888888)
    // console.log(888888)
    // console.log(888888)
    // console.log(888888)
    // console.log(888888)
    // console.log(authUser)
    let auth = false

    await checkTokenAuth()
        .then(r => {
            auth = true
        })
        .catch(err => {
            auth = false
        })

    return auth === true ?
        <ProfileLayout>
            <Component auth={auth} />
        </ProfileLayout>
        :
        <Navigate to="/" replace />
    // <Route path={path} element={<Component auth={auth} />} />

    // <Router render={props => auth === true
    //     ? <Component auth={auth} {...props} />
    //     : <Route
    //         path="/"
    //         element={<Navigate to={{ pathname: '/' }} replace />}
    //     />
    // }
    // />
}

const Private = (props) => {
    const token = cookies.get('token')
    return token ? <ProfileLayout props={props} ><Outlet /></ProfileLayout> : <Navigate to='/' exact />
}


const RouteMap = () => {
    const context = useContext(Context)
    const dispatch = useDispatch();
    return (
        <BrowserRouter history={history} >
            {/* <Header />
                <br />
                <br />
                <div> */}
            <Routes>
                <Route exact path="/" element={<DefaultLayout>
                    <MainPage />
                </DefaultLayout>} />
                <Route element={<Private dispatch={dispatch} />}>
                    <Route exact path='/profile/projects' element={<ProjectsPage dispatch={dispatch} />} />
                </Route>
                <Route element={<Private dispatch={dispatch} />}>
                    <Route exact path='/profile/settings' element={<Settings dispatch={dispatch} />} />
                </Route>
                <Route element={<Private dispatch={dispatch} />}>
                    <Route exact path='/profile/projects/:id' element={<ProjectPage dispatch={dispatch} />} />
                </Route>
                <Route element={<Private dispatch={dispatch} />}>
                    <Route exact path='/profile' element={<ProfilePage dispatch={dispatch} />} />
                </Route>
                {/* <Route path="/profile" element={AuthRequire(ProfilePage)} /> */}
                {/* <Route path="/profile" element={<PrivateRoute path='/profile'
                    // auth={context.authState}
                    component={ProfilePage} />} /> */}
                <Route path="*" element={<DefaultLayout>
                    <Page404 />
                </DefaultLayout>} />
                {/* <React.Fragment>
                    <PrivateRoute path='/profile'
                        auth={context.authState}
                        component={ProfilePage} />
                </React.Fragment> */}

                {/* <Route exact path="/profile/"
                    auth={context.authState}
                    component={PrivateComponent}
                    element={<ProfileLayout>
                        <ProfilePage />
                    </ProfileLayout>} /> */}
                {/* <Route path='/hooksform' element={<HooksForm />} />
                    <Route path='/profile' element={<Profile />} />
                    <Route path='/hookscontainer' element={<HooksContainer1 />} />
                    <Route path='/authcheck' element={AuthCheck} />
                    <Route path='/privateroute' element={<PrivateRoute path='/privateroute'
                        auth={context.authState}
                        component={PrivateComponent} />} />
                    <Route path='/profile' element={<PrivateRoute path='/privateroute'
                        auth={context.authState}
                        component={Profile} />} />
                    {/* <React.Fragment>
                            <PrivateRoute path='/privateroute'
                                auth={context.authState}
                                component={PrivateComponent} />
                        </React.Fragment> */}
                {/* <React.Fragment>
                            <PrivateRoute path="/profile"
                                auth={context.authState}
                                component={Profile} />
                        </React.Fragment> */}
                {/* <Route path='/callback'
                        render={(props) => {
                            context.handleAuth(props); return <Callback />
                        }} />  */}
            </Routes>
            {/* <BrowserRouter>
                        <Routes>
                            <Route exact path='/' component={Home} />
                            <Route path='/hooksform' component={HooksForm} />
                            <Route path='/profile' component={Profile} />
                            <Route path='/hookscontainer' component={HooksContainer1} />
                            <Route path='/authcheck' component={AuthCheck} />

                            <PrivateRoute path='/privateroute'
                                auth={context.authState}
                                component={PrivateComponent} />
                            <PrivateRoute path="/profile"
                                auth={context.authState}
                                component={Profile} />
                            <Route path='/callback'
                                render={(props) => {
                                    context.handleAuth(props); return <Callback />
                                }} />

                        </Routes>
                    </BrowserRouter> */}
            {/* </div> */}
        </BrowserRouter >
    )
}

export default RouteMap;