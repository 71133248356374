import React from 'react';
import {blue60} from "../../vars.js";

const ArrowDown = ({width = '14', height = '8', color = blue60, className}) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.32778 0.225003C1.02405 -0.0749798 0.53159 -0.0750046 0.227834 0.224948C-0.0759228 0.524901 -0.0759478 1.01124 0.227778 1.31123L7 8L13.7722 1.31123C14.0759 1.01124 14.0759 0.524901 13.7722 0.224948C13.4684 -0.0750046 12.9759 -0.0749798 12.6722 0.225003L7 5.82733L1.32778 0.225003Z" fill={color} />
        </svg>
    );
};

export default ArrowDown;