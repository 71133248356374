import * as types from "../actions/types";

const generalReducer = (state = { show: false }, action) => {
    switch (action.type) {
        case types.SEARCH_ADDRESS_REQUEST:
        case types.SEARCH_ADDRESS_SUCCESS:
        case types.ADD_PROJECT_SUCCESS:
        case types.DELETE_PROJECT_REQUEST:
        case types.DELETE_PROJECT_SUCCESS:
            return {
                ...state,
                show: true,
                action: action.type,
                data: action.payload
            };
            break;
        case types.ADD_PROJECT_REQUEST:
            return {
                ...state,
                failure: false,
                action: action.type,
                errorMessage: '',
                data: action.payload
            };
            break;
            break;
        case types.SEARCH_ADDRESS_FAILURE:
        case types.ADD_PROJECT_FAILURE:
        case types.DELETE_PROJECT_FAILURE:
            return {
                ...state,
                failure: true,
                action: action.type,
                errorMessage: action.error.errorMessage,
                data: []
            };
            break;
        case types.PROJECT_DELETE:
            return {
                ...state,
                action: action.type,
            };
            break;

    }
    return state;
};

export default generalReducer;
