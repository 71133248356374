import React, { useEffect, useState, useRef } from 'react';
import PropTypes from "prop-types";

import Button from "../button/Button";
import Icon from "../icon/Icon";
import { gray40 } from "../vars";
import * as types from "../../store/actions/types"
import './NotificationPush.scss';
import { useSelector, useDispatch } from "react-redux"

const NotificationPush = (props) => {
    const {        
        validation,        
    } = props;

    const [show, setShow] = useState(false);    
    const [notification, setNotification] = useState(false);
    const notificationState = useSelector(store => store.notificationPushState)
    const dispatch = useDispatch()

    const counter = useRef(5)
    const activeCounter = useRef(true)

    let icon = null;
    useEffect(() => {
        if (notificationState.action && notificationState.action == types.NOTIFICATION_PUSH_SHOW) {
            counter.current = 5
            activeCounter.current = true
            setNotification(notificationState.notification)
            setShow(true)
            counterWork()
        } else if (notificationState.action && notificationState.action == types.NOTIFICATION_PUSH_HIDE) {
            counter.current = 5
            setNotification({})
            setShow(false)
        }
    }, [notificationState])
    const iconContent = (
        <Icon
            icon={icon}
            width={validation ? 18 : 28}
            height={validation ? 18 : 28}
            className="notification-push__icon"
        />
    );

    const counterWork = () => {
        if(activeCounter.current){
            if(counter.current>0){
                --counter.current
                setTimeout(counterWork, 1000)
            }else{
                dispatch({ type: types.NOTIFICATION_PUSH_HIDE })
                return true
            }
        }
    }

    const onCloseButtonClick = () => {
        dispatch({ type: types.NOTIFICATION_PUSH_HIDE })
    }    
    const counterStop = () => {
        activeCounter.current = false
    }
    const counterStart = () => {
        activeCounter.current = true
        counterWork()
    }
    return (
        show &&
        <>
            <div
                className={`notification-push ${show ? 'open' : ''}`}
                onMouseEnter={() => counterStop()}
                onMouseLeave={() => counterStart()}
            >
                <div className="notification-push__body">
                    {notification.header &&
                        <div className="notification-push__header">
                            {notification.iconContent &&
                                <div className="notification-push__iconWrapper">
                                    {iconContent}
                                </div>
                            }
                            <div className="notification-push__headerContent">{notification.header}</div>
                        </div>
                    }
                    <div className="notification-push__content">{notification.content}</div>
                </div>
                <div className="notification-push__close">
                    <div className="notification-push__closeButton" onClick={onCloseButtonClick}>
                        <Icon icon="Mini/Close" height={15} width={15} color={gray40} />
                    </div>
                </div>
            </div>
        </>

    );
};

NotificationPush.defaultProps = {
    header: '',
    content: '',

    withButton: false,
    buttonContent: null,
    onButtonClick: () => { },
    validation: false,

    withCloseButton: false,
};

NotificationPush.propTypes = {
    type: PropTypes.oneOf(['error', 'warning', 'success', 'information',]).isRequired,
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),

    withButton: PropTypes.bool,
    buttonContent: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    onButtonClick: PropTypes.func,
    validation: PropTypes.bool,

    withCloseButton: PropTypes.bool,
};

export default NotificationPush;
