import React from 'react';
import {gray60} from "../../vars.js";

const Dislike = ({width = 24, height = 24, color = gray60, className= ''}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M19.375 6L19.375 13C19.375 13.2071 19.2071 13.375 19 13.375L17 13.375C16.7929 13.375 16.625 13.2071 16.625 13L16.625 6C16.625 5.79289 16.7929 5.625 17 5.625L19 5.625C19.2071 5.625 19.375 5.79291 19.375 6Z" stroke={color} strokeWidth="1.25"/>
                <path d="M5.04114 11.0326L7.23282 6.10813L7.23285 6.10814L7.23538 6.1023C7.35247 5.83181 7.64024 5.625 8 5.625L13.5455 5.625C14.023 5.625 14.375 5.99748 14.375 6.4L14.375 13.4C14.375 13.6068 14.2906 13.7933 14.1437 13.9308L14.1436 13.9308L14.1371 13.937L9.78308 18.1342L9.44796 17.8147C9.44769 17.8144 9.44742 17.8142 9.44714 17.8139C9.37144 17.7407 9.32639 17.644 9.32027 17.546L9.33491 17.3956L10.0182 14.2319L10.1817 13.475L9.40727 13.475L5.81818 13.475C5.3541 13.475 5.00859 13.1232 4.98947 12.734L4.9907 12.7245L5.03127 12.4121L4.98864 12.3711L4.98864 11.3C4.98864 11.2079 5.0058 11.1221 5.04114 11.0326Z" stroke={color} strokeWidth="1.25"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="16" height="16" fill="white" transform="translate(20 20) rotate(180)"/>
                </clipPath>
            </defs>
        </svg>


    );
};

export default Dislike;