import React from 'react';
import PropTypes from 'prop-types';

import "./Grid.scss";

const Grid = (props) => {
    const {
        children,
        gap,
        column,
    } = props;    

    return (
        <div
            className={`grid-template column-${column}`}
            style={{ gap: `${gap}px`, gridTemplateColumns: `repeat(${column}, 1fr)` }}
        >
            {children}
        </div>
    );
};

Grid.defaultProps = {
    column: 1,
    gap: 10,

}

Grid.propTypes = {
    column: PropTypes.number,
    gap: PropTypes.number,

}

export default Grid;