import React, { Component, useState, useEffect } from 'react';
import * as type from "../../store/actions/types"
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import { loginUser } from "../../../actions/AuthUserActions";
// import { Button, Alert } from 'react-bootstrap';
// import '../../../css/Application.scss';
// import './page404Page.scss'
import logo from '../../img/logo.svg'
import Link from "../../common/link/Link";
import { userProfile } from "../../store/actions/ProfileActions";
import { formValueSelector, reduxForm, initialize } from "redux-form";
import { deepFind } from '../../../backend/models/helpers';
import Template from '../../components/Template';
import Grid from '../../common/grid/Grid';
import Icon from "../../common/icon/Icon";
import GridBlock from '../../common/gridBlock/GridBlock';
import Heading from '../../common/heading/Heading'
import Clipboard from '../../common/clipboard/Clipboard'
import Notification from '../../common/notification/Notification'
import FormBlock from '../../common/formBlock/FormBlock'
import PersonalDataForm from './form/PersonDataForm'
import { useSelector } from 'react-redux';
const ProfilePage = props => {
    const { profile } = props
    
    const windowWidth = useSelector(store => store.windowWidth)
    const isMobile = windowWidth <= 768;
    const getCrmUrl = () => {
        return `https://${profile['_id']}.ag-platform.ru`
    }
    
    return <>
        <Template
            caption={'Профиль'}
            colorVariant={'white-gray'}
        >
            <Grid column={isMobile ? 1 : 2} gap={10}>
                <GridBlock>
                    <Heading variant='h3'>Подписка</Heading>
                </GridBlock>
                <GridBlock>
                    <Heading variant='h3'>Персональные данные</Heading>
                    <PersonalDataForm {...props} />
                </GridBlock>
                <GridBlock>
                    <Heading variant='h3'>Данные по CRM</Heading>
                    <Clipboard
                        label={'Ссылка для входа'}
                        text={getCrmUrl()}
                        disabled={false}
                    />
                    <FormBlock>
                        <Notification
                            type="warning"
                            content={'Для изменениия адреса CRM необходим премиум аккаунт'}
                            validation={false}
                            withCloseButton={false}
                        />
                    </FormBlock>
                </GridBlock>
                <GridBlock>
                    <Heading variant='h3'>Чат с техподдержкой</Heading>
                    <FormBlock>
                        <Notification
                            type="warning"
                            content={'Чат доступен только при наличии премиум аккаунта'}
                            validation={false}
                            withCloseButton={false}
                        />
                    </FormBlock>
                </GridBlock>
            </Grid>
        </Template>
    </>
}
const mapStateToProps = store => {
    // const pf = store.userState.size > 0 ? store.userState.get("profile") : null;    
    return {
        profile: deepFind(store.userState, 'data') || {},
        // pf,
        // documentsState: store.documentsState,
        // formValues: {
        //     PASSPORT_RF_NUMBER: selector(store, 'PASSPORT_RF_NUMBER') || '',
        //     PASSPORT_RF_ISSUED_ON: selector(store, 'PASSPORT_RF_ISSUED_ON') || '',
        //     PASSPORT_RF_ISSUED_BY: selector(store, 'PASSPORT_RF_ISSUED_BY') || '',
        //     PASSPORT_RF_DIVISION_CODE: selector(store, 'PASSPORT_RF_DIVISION_CODE') || '',
        //     PASSPORT_RF_BIRTHPLACE: selector(store, 'PASSPORT_RF_BIRTHPLACE') || '',
        //     SNILS: selector(store, 'SNILS') || deepFind(pf, 'SNILS.data.SNILS') || '',
        //     BIRTHDATE: selector(store, 'BIRTHDATE') || deepFind(pf, 'PERSON.data.BIRTHDATE') || '',
        // }
    }
}

const mapDispatchToProps = {
    userProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
// export default ProfilePage