import React from 'react';

const NotificationWarningOval = ({ width = 28, height = 28, className = '' }) => {
    return (
        <svg className={className}  width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#FFF1CC"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14 25C20.0751 25 25 20.0751 25 14C25 7.92487 20.0751 3 14 3C7.92487 3 3 7.92487 3 14C3 20.0751 7.92487 25 14 25Z" fill="#FFE8AA"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14 24C19.5228 24 24 19.5228 24 14C24 8.47715 19.5228 4 14 4C8.47715 4 4 8.47715 4 14C4 19.5228 8.47715 24 14 24Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14 22C9.5888 22 6 18.4112 6 14C6 9.5888 9.5888 6 14 6C18.4112 6 22 9.5888 22 14C22 18.4112 18.4112 22 14 22ZM15 16.9969C15 17.1348 14.9744 17.2654 14.9233 17.3887C14.8722 17.512 14.8016 17.6186 14.7117 17.7085C14.6217 17.7983 14.5164 17.8694 14.3957 17.9216C14.2751 17.9739 14.1432 18 14 18C13.8609 18 13.7301 17.9739 13.6074 17.9216C13.4847 17.8694 13.3783 17.7983 13.2883 17.7085C13.1984 17.6186 13.1278 17.512 13.0767 17.3887C13.0256 17.2654 13 17.1348 13 16.9969C13 16.8548 13.0256 16.7231 13.0767 16.6019C13.1278 16.4807 13.1984 16.3751 13.2883 16.2853C13.3783 16.1954 13.4847 16.1254 13.6074 16.0752C13.7301 16.0251 13.8609 16 14 16C14.1432 16 14.2751 16.0251 14.3957 16.0752C14.5164 16.1254 14.6217 16.1954 14.7117 16.2853C14.8016 16.3751 14.8722 16.4807 14.9233 16.6019C14.9744 16.7231 15 16.8548 15 16.9969ZM14.75 14.25C14.75 14.6642 14.4142 15 14 15C13.5858 15 13.25 14.6642 13.25 14.25V9.75C13.25 9.33579 13.5858 9 14 9C14.4142 9 14.75 9.33579 14.75 9.75V14.25Z" fill="#FFAA00"/>
        </svg>
    );
};

export default NotificationWarningOval;