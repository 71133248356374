import * as types from "../actions/types";
import { deepFind } from "../../Helpers/Common";

const INITIAL_STATE = {
    modals: {},
    initialized: false,
};

const modalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.INITIALIZE_MODALS:
            return {
                ...state,
                modals: action.payload || {},
                initialized: true,
            }

        case types.SHOW_MODAL:
            if (!state.modals[action.payload.modalType]) {
                console.error(`Modal not found: ${action.payload.modalType}`);
                return state;
            }
            return {
                ...state,
                modals: {
                    ...state.modals,
                    [action.payload.modalType]: {
                        ...(state.modals[action.payload.modalType] || {}),
                        isShow: true,
                        props: action.payload.modalProps,
                    }
                }
            };

        case types.HIDE_MODAL:
            if (!action.payload.modalType) {
                const newModalState = { ...state.modals };
                Object.keys(newModalState).forEach((key) => {
                    if (newModalState[key].isShow)
                        newModalState[key].isShow = false;
                });
                return {
                    ...state,
                    modals: newModalState,
                };
            }

            if (!state.modals[action.payload.modalType]) {
                console.error(`Modal not found: ${action.payload.modalType}`);
                return state;
            }
            return {
                ...state,
                modals: {
                    ...state.modals,
                    [action.payload.modalType]: {
                        ...(state.modals[action.payload.modalType] || {}),
                        isShow: false,
                        props: deepFind(state, `modals.${action.payload.modalType}.props`),
                    }
                }
            }

        case types.UNMOUNT_MODAL:
            if (!action.payload.modalType) {
                const newModalState = { ...state.modals };
                Object.keys(newModalState).forEach((key) => {
                    if (newModalState[key].isShow === false)
                        newModalState[key].isShow = null;
                });
                return {
                    ...state,
                    modals: newModalState,
                };
            }
            return {
                ...state,
                modals: {
                    ...state.modals,
                    [action.payload.modalType]: {
                        ...(state.modals[action.payload.modalType] || {}),
                        isShow: null,
                        props: {},
                    }
                }
            }
        default:
            return state
    }
};

export default modalReducer;
