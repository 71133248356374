import React, { Component, useState, useEffect, useRef, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import * as types from "../../../store/actions/types"
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm, initialize, touch, updateSyncErrors } from "redux-form";
import { useSelector } from 'react-redux';
import Button from '../../../common/button/Button';
import BalloonHelp from '../../../common/balloonHelp/BalloonHelp';
import Grid from '../../../common/grid/Grid';
import Icon from "../../../common/icon/Icon";
import GridBlock from '../../../common/gridBlock/GridBlock';
import Heading from '../../../common/heading/Heading'
import FormBlock from "../../../common/formBlock/FormBlock";
import ButtonsBlock from "../../../common/buttonsBlock/ButtonsBlock";
import TextField from "../../../common/textfield/TextField";
import { deepFind } from '../../../../backend/models/helpers';
import { FormUtilsMask, FormUtilsValidator, } from "../../../utils/FormUtils.js";
import { error60, gray60, gray30, gray90, corpGreen } from '../../../common/vars'
import { userProfile } from "../../../store/actions/ProfileActions";
import Context from '../../../utils/context';
import { hideModal, showModal } from "../../../store/actions/ModalActions";
import * as modals from "../../../store/actions/ModalConstants";
import "../settingsPage.scss"

const FORM_NAME = 'CLIENT_STATUSES_FORM';

const ClientStatusesForm = props => {
    // const { profile } = props    
    const windowWidth = useSelector(store => store.windowWidth)
    const isMobile = windowWidth <= 768;
    const context = useContext(Context)
    const [profile, setProfile] = useState({})
    const [clientStatuses, setClientStatuses] = useState([])
    const [saveInProgress, setSaveInProgress] = useState(false)

    useEffect(() => {
        if (deepFind(profile, 'settings.client_statuses')) {
            setClientStatuses(deepFind(profile, 'settings.client_statuses'))
        }
    }, [profile])
    useEffect(() => {
        setProfile(context.profile)
    }, [context.profile])

    useEffect(() => {
        if (deepFind(props, 'userState.action') == types.USER_SETTINGS_DELETE) {
            props.userProfile('DELETE', deepFind(props, 'userState.data'), '/delete_profile_settings', [types.USER_PROFILE_UPDATE_REQUEST, types.USER_PROFILE_DELETE_SETTINGS_SUCCESS, types.USER_PROFILE_DELETE_SETTINGS_FAILURE])
        } else if (deepFind(props, 'userState.action') == types.USER_PROFILE_DELETE_SETTINGS_SUCCESS) {
            try{
                let profileData = { ...profile },
                    answer = deepFind(props, 'userState.data')
                profileData.settings.client_statuses[answer.index].delete = 1
                setProfile(profileData)
            }catch(err){
                window.location.reload()
            }
        } else if (deepFind(props, 'userState.action') == types.USER_PROFILE_UPDATE_SUCCESS) {
            let newStatus = deepFind(props, 'userState.data')
            const { reset, clearSubmit, clearFields } = props;
            reset();
            clearSubmit();
            props.dispatch(initialize(FORM_NAME, { NAME: "", COLOR: "" }));
            setClientStatuses([...clientStatuses, newStatus])
            setSaveInProgress(false)
        } else if (
            deepFind(props, 'userState.action') == types.USER_PROFILE_UPDATE_FAILURE ||
            deepFind(props, 'userState.action') == types.USER_PROFILE_DELETE_SETTINGS_FAILURE
        ) {
            setSaveInProgress(false)
            // this.setState({
            //     saveInProgress: false
            // });
            props.dispatch(showModal(modals.MODAL_NOTIFICATION, {
                title: deepFind(props, 'userState.errorMessage') ? 'Ошибка' : 'Ошибка сервера',
                children: deepFind(props, 'userState.errorMessage'),
                variant: 'error',
                buttonTitle: 'Продолжить',
            }));
        }
    }, [props.userState.action])

    const deleteStatus = (id, ind) => {
        props.dispatch(
            showModal(modals.MODAL_TYPE_DELETE_POPUP, {
                title: 'Удалить филиал',
                confirmButton: 'Удалить',
                cancelButton: 'Отменить',
                content: "Все данные филиала будут удалены безвозвратно. Вы уверены, что хотите удалить филиал?",
                confirmAction: types.USER_SETTINGS_DELETE,
                cancelAction: types.PROJECT_DELETE_CANCEL,
                hideOnConfirm: true,
                hideOnCancel: true,
                payload: { id: id, field: 'client_statuses', ind: ind }
            })
        );
        // let request = {}
        // props.userProfile('PUT', request, '/upd_profile_settings', [types.USER_PROFILE_UPDATE_REQUEST, types.USER_PROFILE_DELETE_SETTINGS_SUCCESS, types.USER_PROFILE_DELETE_SETTINGS_FAILURE])
    }
    const handleFormSubmit = values => {
        if (deepFind(profile, 'settings.client_statuses')) {
            let hasStatus = deepFind(profile, 'settings.client_statuses').find(el => {
                if (el.name == values.NAME || el.color == values.COLOR && el.delete == 0) {
                    return el
                }
            })
            if (hasStatus) {
                props.dispatch(showModal(modals.MODAL_NOTIFICATION, {
                    title: 'Ошибка',
                    children: 'Такое название или цвет статуса уже используется',
                    variant: 'error',
                    buttonTitle: 'Продолжить',
                }));
            } else {
                setSaveInProgress(true)
                let request = {
                    client_statuses: {
                        name: values.NAME,
                        color: values.COLOR,
                    }
                }
                props.userProfile('PUT', request, '/upd_profile_settings', [types.USER_PROFILE_UPDATE_REQUEST, types.USER_PROFILE_UPDATE_SUCCESS, types.USER_PROFILE_UPDATE_FAILURE])
            }
        }
    }
    return <>
        <Grid column={isMobile ? 1 : 2} gap={10}>
            <GridBlock>
                <Heading variant='h3'>Статусы</Heading>
                <div className='statuses'>
                    {clientStatuses && clientStatuses.map((s, ind) => {
                        return s.delete == 0 ?
                            <div className='statuses__status' style={{ borderLeftColor: s.color }}>
                                <span className='statuses__status__name'>{s.name}</span>
                                {(s.id != 1 && s.id != 2) ?
                                    <Button
                                        customClass={'statuses__status__delete'}
                                        variant="text"
                                        onClick={() => { deleteStatus(s.id, ind) }}
                                        size={30}
                                    >
                                        <Icon icon="Delete" color={gray30} width={16} height={18} />
                                    </Button>
                                    :
                                    <BalloonHelp content="Этот статус обязательный и его удаление невозможно"></BalloonHelp>
                                }
                            </div>
                            : ''

                    })}
                </div>
            </GridBlock>
            <GridBlock>
                <Heading variant='h3'>Добавить статус</Heading>
                <form className={FORM_NAME} onSubmit={props.handleSubmit(handleFormSubmit)}>
                    <FormBlock>
                        <TextField
                            id={'NAME'}
                            name={'NAME'}
                            label={'Название'}
                            size={'fullWidth'}
                            validate={[FormUtilsValidator.required, FormUtilsValidator.name, FormUtilsValidator.maxLength50]}
                        />
                    </FormBlock>
                    <FormBlock>
                        <TextField
                            id={'COLOR'}
                            type={'color'}
                            name={'COLOR'}
                            label={'Цвет'}
                            size={'fullWidth'}
                            validate={[FormUtilsValidator.required, FormUtilsValidator.color]}
                        />
                    </FormBlock>
                    <ButtonsBlock>
                        <Button
                            size={40}
                            variant="primary"
                            // onClick={handleMobileFilterButtonClick}
                            fullWidth={false}
                            type={'submit'}
                            disabled={saveInProgress}
                        >
                            Добавить
                        </Button>
                    </ButtonsBlock>
                </form>
            </GridBlock>
        </Grid>
    </>
}
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = store => {
    // const pf = store.userState.size > 0 ? store.userState.get("profile") : null;    
    return {
        // profile: deepFind(store.userState, 'data'),
        userState: store.userState,
        formValues: {
            NAME: selector(store, 'NAME') || '',
            COLOR: selector(store, 'COLOR') || '',
        }
    }
}
const mapDispatchToProps = {
    userProfile
};
const RowDetailForm = reduxForm({
    form: FORM_NAME
})(ClientStatusesForm);
export default connect(mapStateToProps, mapDispatchToProps)(RowDetailForm);