import React from 'react';

const SelectArrow = ({width=24, height=24, color="black", className}) => {
    return (
        <svg className={className} width={width} height={height} viewBox={"0 0 6 12"} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.66635 0L5.33248 4.66667H0.000222206L2.66635 0ZM2.66613 12L0 7.33333H5.33225L2.66613 12Z" fill={color}/>
        </svg>
    );
};

export default SelectArrow;