import * as types from "../actions/types";

const INITIAL_STATE = {
    windowWidth: null,
    serverTime: null,
};

const metaReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.UPDATE_WINDOW_WIDTH:
            return {
                ...state,
                windowWidth: action.payload.windowWidth
            };
        default:
            return state
    }
};

export default metaReducer;
