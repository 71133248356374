import React from 'react';
import PropTypes from "prop-types";
import ArrowBack from "./icons/ArrowBack";
import ArrowDown from "./icons/ArrowDown";
import ChevronForward from "./icons/ChevronForward";
import ChevronBack from "./icons/ChevronBack";
import NotificationError from "./icons/NotificationError";
import NotificationSuccess from "./icons/NotificationSuccess";
import NotificationWarning from "./icons/NotificationWarning";
import Check from "./icons/Check";
import CheckInfo from "./icons/CheckInfo";
import Dash from "./icons/Dash";
import SelectArrow from "./icons/SelectArrow";
import RatingEmpty from "./icons/RatingEmpty";
import RatingFull from "./icons/RatingFull";
import MiniInfo from "./icons/MiniInfo";
import MiniClose from "./icons/MiniClose";
import Calendar from "./icons/Calendar";
import ArrowForward from "./icons/ArrowForward";
import NotificationSuccessOval from "./icons/NotificationSuccessOval";
import NotificationWarningOval from "./icons/NotificationWarningOval";
import NotificationErrorOval from "./icons/NotificationErrorOval";
import Link from "./icons/Link";
import FavoriteEmpty from "./icons/FavoriteEmpty";
import FavoriteFull from "./icons/FavoriteFull";
import Download from "./icons/Download";
import Delete from "./icons/Delete";
import File from './icons/File';
import ArrowUp from "./icons/ArrowUp";
import Sort from "./icons/Sort";
import Attach from "./icons/Attach";
import NavigationKebab from "./icons/NavigationKebab.jsx";
import Notification from "./icons/Notification.jsx";
import MailFilled from "./icons/MailFilled.jsx";
import PlitkaPlus from "./icons/PlitkaPlus.jsx";
import UserAuthorized from "./icons/UserAuthorized.jsx";
import PersonalData from "./icons/PersonalData.jsx";
import Lock from './icons/Lock.jsx'
import Print from "./icons/Print.jsx";
import PrintEmpty from "./icons/PrintEmpty.jsx";
import MapPin from "./icons/MapPin.jsx";
import Edit from "./icons/Edit.jsx";
import Settings from "./icons/Settings.jsx";
import Update from "./icons/Update.jsx";
import ArrowRight from "./icons/ArrowRight";
import Dislike from "./icons/Dislike";
import Like from "./icons/Like";
import Close from "./icons/Close";
import Return from './icons/Return.jsx'
import NotificationInformation from "./icons/NotificationInformation.jsx";
import NavigationKebabDouble from "../icon/icons/NavigationKebabDouble.jsx";
import NotificationInfoOval from "../icon/icons/NotificationInfoOval.jsx";
import FilterIcon from "./icons/Filter";
import Plus from "./icons/Plus.jsx";
import Search from "./icons/Search";
import Light from "../icon/icons/Light.jsx";
import Mgts from "../icon/icons/Mgts.jsx";
import Epd from "../icon/icons/Epd.jsx";
import Insurance from "./icons/Insurance.jsx";
import Clear from "../icon/icons/Clear";
import Copy from "../icon/icons/Copy";
import Back from "../icon/icons/Back";
import Menu from "../icon/icons/Menu";
import MoreVertical from "../icon/icons/MoreVertical";
import MoreHorizontal from "../icon/icons/MoreHorizontal";
import Add from "../icon/icons/Add";



const Icons = {
    'Arrow/Back': ArrowBack,
    'Arrow/Down': ArrowDown,
    'Arrow/Up': ArrowUp,
    'Arrow/Forward': ArrowForward,
    'Attach': Attach,
    'Arrow/Right': ArrowRight,
    'Chevron/Forward': ChevronForward,
    'Chevron/Back': ChevronBack,
    'Close': Close,
    'Calendar/Calendar': Calendar,
    'Checkbox/Check': Check,
    'Checkbox/Dash': Dash,
    'CheckInfo' : CheckInfo,
    'Dislike': Dislike,
    'Download': Download,
    'Delete': Delete,
    'Edit': Edit,
    'FavoriteEmpty': FavoriteEmpty,
    'FavoriteFull': FavoriteFull,
    'FilterIcon': FilterIcon,
    'File': File,
    'Notification/Error': NotificationError,
    'Notification/Success': NotificationSuccess,
    'Notification/Warning': NotificationWarning,
    'Notification/Information': NotificationInformation,
    'Select/Arrow': SelectArrow,
    'Rating/RatingEmpty': RatingEmpty,
    'Rating/RatingFull': RatingFull,
    'Mini/Info': MiniInfo,
    'Mini/Close': MiniClose,
    'Notification/SuccessWithOval': NotificationSuccessOval,
    'Notification/WarningWithOval': NotificationWarningOval,
    'Notification/ErrorWithOval': NotificationErrorOval,
    'Notification/InfoWithOval': NotificationInfoOval,
    'Navigation/Kebab': NavigationKebab,
    'Navigation/KebabDouble': NavigationKebabDouble,
    'Notification': Notification,
    'Link': Link,
    'Sort': Sort,
    'MailFilled': MailFilled,
    'List/PlitkaLarge-Plus': PlitkaPlus,
    'User/Authorized': UserAuthorized,
    'PersonalData': PersonalData,
    'Lock': Lock,
    'Print': Print,
    'PrintEmpty': PrintEmpty,
    'Like': Like,
    'Map/Pin': MapPin,
    'Settings': Settings,
    'Update':Update,
    'Return': Return,
    'Plus': Plus,
    'Search': Search,
    'Light': Light,
    'Mgts': Mgts,
    'Epd': Epd,
    'Insurance': Insurance,
    'Clear': Clear,
    'Copy': Copy,
    'Back': Back,
    'Menu': Menu,
    'MoreVertical': MoreVertical,
    'MoreHorizontal': MoreHorizontal,
    'Add': Add,
}

const Icon = (props) => {
    const { icon, width, height, color, className } = props;

    if(Icons[icon]) {
        const _Icon = Icons[icon];
        return <_Icon width={width} height={height} color={color} className={className} />
    } else {
        console.error(`Icon ${icon} not found`);
        return null;
    }
};

Icon.defaultProps = {
    width: undefined,
    height: undefined,
    color: undefined,
    className: undefined,
}

Icon.propTypes = {
    className: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    icon: PropTypes.oneOf([
        'Copy',
        'Arrow/Back',
        'Arrow/Down',
        'Arrow/Forward',
        'Arrow/Right',
        'Chevron/Forward',
        'Chevron/Back',
        'Dislike',
        'Notification/Error',
        'Notification/Success',
        'Notification/Warning',
        'Notification/Information',
        'Checkbox/Check',
        'Checkbox/Dash',
        'CheckInfo',
        'Select/Arrow',
        'Rating/RatingEmpty',
        'Rating/RatingFull',
        'Mini/Info',
        'Mini/Close',
        'Calendar/Calendar',
        'Notification/SuccessWithOval',
        'Notification/WarningWithOval',
        'Notification/ErrorWithOval',
        'Notification/InfoWithOval',
        'Link',
        'FavoriteEmpty',
        'FavoriteFull',
        'Download',
        'Delete',
        'File',
        'Arrow/Up',
        'Sort',
        'Attach',
        'Notification',
        'MailFilled',
        'List/PlitkaLarge-Plus',
        'User/Authorized',
        'PersonalData',
        'Navigation/Kebab',
        'Navigation/KebabDouble',
        'Lock',
        'Like',
        'Print',
        'PrintEmpty',
        'Map/Pin',
        'Edit',
        'Settings',
        'Search',
        'Update',
        'Close',
        'Return',
        'FilterIcon',
        'Plus',
        'Search',
        'Light',
        'Mgts',
        'Epd',
        'Insurance',
        'Clear',
        'Menu',
    ]).isRequired,
}

export default Icon;