import React, { useContext, useState, useRef, useEffect } from 'react';
import { formValueSelector, reduxForm, initialize, touch, updateSyncErrors, use } from "redux-form";
import { connect, useDispatch, useSelector } from 'react-redux';
import { hideModal, showModal } from "../../../store/actions/ModalActions";
// import ModalNotification from "../../../common/modals/modalNotification/ModalNotification.jsx";
import ModalForm from "../../../common/modals/modalForm/ModalForm";
import Button from "../../../common/button/Button.jsx";
import Notification from '../../../common/notification/Notification'
import { deepFind } from '../../../../backend/models/helpers';
import { compose } from "redux";
import { getFormValues } from "redux-form";
import FormBlock from "../../../common/formBlock/FormBlock.jsx";
import Link from "../../../common/link/Link.jsx";
import ButtonsBlock from "../../../common/buttonsBlock/ButtonsBlock.jsx";
import Select from "../../../common/select/Select.jsx";
import * as modalTypes from "../../../store/actions/ModalConstants";
import * as types from "../../../store/actions/types"
import Context from '../../../utils/context';
import { userProfile } from "../../../store/actions/ProfileActions";
import { showNotificationPush } from '../../../store/actions/NotificationPushAction';

/**
 * Используется для модального окна удаления элементов
 * По структуре произошел от `ConfirmModal` для совместимости с использованием в коде
 * Отображает также уведомление об удалении приложеных докуметнов если они есть
 */

const TgLinkModal = props => {
    const {
        confirm,
    } = props;
    const userState = useSelector((store) => store.userState)
    const dispatch = useDispatch()
    const [key, setKey] = useState('')
    const context = useContext(Context)
    const [options, setOptions] = useState([])
    const disabled = useRef(false)

    useEffect(() => {
        dispatch(userProfile('GET', {}, '/tg_link', [types.USER_TG_LINK_REQUEST, types.USER_TG_LINK_SUCCESS, types.USER_TG_LINK_FAILURE]))
    }, [])

    useEffect(() => {
        if (userState.action == types.USER_TG_LINK_SUCCESS) {
            setKey(userState.data?.key || '—')
        } else if (userState.action == types.USER_TG_LINK_FAILURE) {
            this.props.dispatch(showModal(modalTypes.MODAL_NOTIFICATION, {
                title: deepFind(userState, 'errorMessage') ? 'Ошибка' : 'Ошибка сервера',
                children: deepFind(userState, 'errorMessage') || '',
                variant: 'error',
                buttonTitle: 'Продолжить',
            }));
        }
    }, [userState.action])

    const buttonsBlock = (
        <ButtonsBlock>
            <Button
                // onClick={handleFormSubmit}
                variant="primary"
                size={48}
                type="submit"
                onClick={() => { onClose() }}
            >
                Продолжить
            </Button>
        </ButtonsBlock>
    )
    const onClose = () => {
        dispatch(
            hideModal(modalTypes.MODAL_TG_LINK)
        );
    }
    const copyKey = () => {
        if (!navigator.clipboard) {
            this.props.dispatch(showModal(modalTypes.MODAL_NOTIFICATION, {
                title: 'Ошибка',
                children: 'Копирование в буфер обмена не поддерживается вашим браузером.',
                variant: 'error',
                buttonTitle: 'Продолжить',
            }));
            return;
        }

        const text = key;
        navigator.clipboard.writeText(text)
            .then(() => {
                dispatch(showNotificationPush({
                    content: 'Ключ скопирован!'
                }))
            })
            .catch((err) => {
                console.error('Ошибка при копировании текста: ', err);
            });
    }
    return <>
        <ModalForm
            isShow={props.isShow}
            onHide={onClose}
            modalHeader={'Привязать Telegram'}
            size="md"
        >
            <FormBlock customMarginTop={5} direction="row">
                Добавьте нашего бота: <Link href="https://t.me/ag_platform_bot" routerLink={false}>@ag_platform_bot</Link>
            </FormBlock>
            <FormBlock customMarginTop={5}>
                <Notification
                    type="success"
                    content={'Укажите ключ: ' + key}
                    validation={false}
                    withCloseButton={false}
                    withButton={true}
                    buttonContent="Скопировать"
                    onButtonClick={() => { copyKey() }}
                />
            </FormBlock>
            <FormBlock customMarginTop={5}>
                <Notification
                    type="warning"
                    content={'Ключ дейсвует 5 минут'}
                    validation={false}
                    withCloseButton={false}
                />
            </FormBlock>
        </ModalForm>

    </>
}

export default TgLinkModal;
// export default ProfilePage


