export const SUCCESS = "SUCCESS"
export const FAILURE = "FAILURE"
export const ADD_PROFILE = "ADD_PROFILE"
export const REMOVE_PROFILE = "REMOVE_PROFILE"
export const USER_INPUT_CHANGE = "USER_INPUT_CHANGE"
export const USER_INPUT_SUBMIT = "USER_INPUT_SUBMIT"

//регистрация
export const REG_REQUEST = "REG_REQUEST"
export const REG_FAILURE = "REG_FAILURE"
export const REG_SUCCESS = "REG_SUCCESS"

//FORGOT
export const FORGOT_REQUEST = "FORGOT_REQUEST"
export const FORGOT_SUCCESS = "FORGOT_SUCCESS"
export const FORGOT_FAILURE = "FORGOT_FAILURE"

//LOGIN
export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILURE = "LOGIN_FAILURE"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const CHECK_AUTH = "CHECK_AUTH"
export const AUTH_SUCCESS = "AUTH_SUCCESS"
export const AUTH_FAILURE = "AUTH_FAILURE"

//API
export const API = 'API';
export const API_START = 'API_START';
export const API_END = 'API_END';
export const API_ERROR = 'API_ERROR';

//Для модальных окон
export const INITIALIZE_MODALS = 'INITIALIZE_MODALS';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const UNMOUNT_MODAL = 'UNMOUNT_MODAL';

//USER
export const USER_PROFILE_FAILURE = 'USER_PROFILE_FAILURE';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST';
export const USER_PROFILE_RELOAD = 'USER_PROFILE_RELOAD';
export const USER_PROFILE_UPDATE_REQUEST = 'USER_PROFILE_UPDATE_REQUEST';
export const USER_PROFILE_UPDATE_SUCCESS = 'USER_PROFILE_UPDATE_SUCCESS';
export const USER_PROFILE_UPDATE_FAILURE = 'USER_PROFILE_UPDATE_FAILURE';
export const USER_PROFILE_DELETE_SETTINGS_SUCCESS = 'USER_PROFILE_DELETE_SETTINGS_SUCCESS';
export const USER_PROFILE_DELETE_SETTINGS_FAILURE = 'USER_PROFILE_DELETE_SETTINGS_FAILURE';
export const USER_SETTINGS_DELETE = 'USER_SETTINGS_DELETE';
export const USER_TG_LINK_FAILURE = 'USER_TG_LINK_FAILURE';
export const USER_TG_LINK_SUCCESS = 'USER_TG_LINK_SUCCESS';
export const USER_TG_LINK_REQUEST = 'USER_TG_LINK_REQUEST';

//PRELOADER
export const PRELOADER_SHOW = 'PRELOADER_SHOW';
export const PRELOADER_HIDE = 'PRELOADER_HIDE';

//ADDRESS
export const SEARCH_ADDRESS_REQUEST = 'SEARCH_ADDRESS_REQUEST';
export const SEARCH_ADDRESS_SUCCESS = 'SEARCH_ADDRESS_SUCCESS';
export const SEARCH_ADDRESS_FAILURE = 'SEARCH_ADDRESS_FAILURE';

//PROJECT
export const ADD_PROJECT_REQUEST = 'ADD_PROJECT_REQUEST';
export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS';
export const ADD_PROJECT_FAILURE = 'ADD_PROJECT_FAILURE';
export const PROJECT_DELETE = 'PROJECT_DELETE';
export const PROJECT_DELETE_CANCEL = 'PROJECT_DELETE_CANCEL';
export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE';

//NOTIFICATION PUSH
export const NOTIFICATION_PUSH_SHOW = 'NOTIFICATION_PUSH_SHOW';
export const NOTIFICATION_PUSH_HIDE = 'NOTIFICATION_PUSH_HIDE';

//META
export const UPDATE_WINDOW_WIDTH = 'UPDATE_WINDOW_WIDTH';

//WS
export const PROFILE_RELOAD = 'PROFILE_RELOAD';