import React from 'react';
import {blue60} from "../../vars";

const Link = ({ width = 17, height = 17, className = '', color = blue60 }) => {
    return (
        <svg  className={className} width={width} height={height} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.3172 1.7574L15.9926 3.43277C17.2163 4.65643 17.1587 6.68903 15.8729 7.97478L13.0009 10.8469C11.7151 12.1326 9.6825 12.1902 8.45885 10.9665L7.34193 9.84961C7.04602 9.5537 7.04602 9.07394 7.34193 8.77803C7.63784 8.48212 8.1176 8.48212 8.41351 8.77803L9.53043 9.89495C10.1564 10.5209 11.2317 10.4729 11.9293 9.77528L14.8014 6.9032C15.4989 6.20561 15.547 5.13035 14.921 4.50436L13.2456 2.82898C12.6196 2.20298 11.5444 2.25106 10.8468 2.94865L8.62543 5.17002C8.32952 5.46593 7.84975 5.46593 7.55385 5.17002C7.25794 4.87411 7.25794 4.39435 7.55385 4.09844L9.77522 1.87707C11.061 0.591316 13.0936 0.533741 14.3172 1.7574ZM10.408 7.90045L9.29109 6.78353C8.06744 5.55988 6.03483 5.61745 4.74908 6.9032L1.877 9.77528C0.591255 11.061 0.53368 13.0936 1.75733 14.3173L3.43271 15.9927C4.65637 17.2163 6.68897 17.1587 7.97472 15.873L10.1961 13.6516C10.492 13.3557 10.492 12.876 10.1961 12.58C9.90018 12.2841 9.42042 12.2841 9.12451 12.58L6.90314 14.8014C6.20555 15.499 5.13029 15.5471 4.50429 14.9211L2.82892 13.2457C2.20292 12.6197 2.251 11.5444 2.94859 10.8469L5.82066 7.97478C6.51825 7.27719 7.59351 7.22912 8.21951 7.85511L9.33643 8.97203C9.63234 9.26794 10.1121 9.26794 10.408 8.97203C10.7039 8.67612 10.7039 8.19636 10.408 7.90045Z" fill={color} />
        </svg>
    );
};

export default Link;