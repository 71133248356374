import React, { Component, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import * as types from "../../store/actions/types"
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import { loginUser } from "../../../actions/AuthUserActions";
// import { Button, Alert } from 'react-bootstrap';
// import '../../../css/Application.scss';
// import './page404Page.scss'
import logo from '../../img/logo.svg'
import Link from "../../common/link/Link";
import { userProfile } from "../../store/actions/ProfileActions";
import { formValueSelector, reduxForm, initialize } from "redux-form";
import { deepFind } from '../../../backend/models/helpers';
import Template from '../../components/Template';
import Grid from '../../common/grid/Grid';
import GridBlock from '../../common/gridBlock/GridBlock';
import Heading from '../../common/heading/Heading'
import Button from '../../common/button/Button'
import ButtonBack from '../../common/buttonBack/ButtonBack'
import TableBlock from '../../common/tableBlock/TableBlock'
import Icon from '../../common/icon/Icon'
import { error70 } from '../../common/vars'
import ProjectForm from './form/ProjectForm';
import { error60, gray60, corpGreen } from '../../common/vars'
import { generalRequest } from "../../store/actions/GeneralActions";
import { hideModal, showModal } from "../../store/actions/ModalActions";
import * as modals from "../../store/actions/ModalConstants";
import "./projectsPage.scss"

const ProjectPage = props => {
    const { profile } = props
    const params = useParams();
    const navigate = useNavigate();
    const [itemID, setItemID] = useState(params.id == 'new' ? null : params.id)
    const [projectData, setProjectData] = useState({})
    const [disableDeleteButton, setDisableDeleteButton] = useState(false)

    const processDeleting = useRef(false)
    //  checkBoxRef.current

    useEffect(() => {
        if (itemID) {
            const findProjectData = deepFind(profile, `projects.${itemID}`)
            if (!findProjectData) {
                navigate('/profile/projects')
            } else {
                setProjectData(findProjectData)
            }
        }
    }, [itemID]);

    useEffect(() => {
        if (deepFind(props, 'generalRequestState.action') == types.PROJECT_DELETE) {
            const request = {
                id: [itemID],
            }
            setDisableDeleteButton(true)
            props.generalRequest('DELETE', request, '/del_project', [types.DELETE_PROJECT_REQUEST, types.DELETE_PROJECT_SUCCESS, types.DELETE_PROJECT_FAILURE])
        }
        if (processDeleting.current) {
            if (deepFind(props, 'generalRequestState.action') == types.DELETE_PROJECT_SUCCESS) {
                processDeleting.current = false
                props.dispatch(showModal(modals.MODAL_NOTIFICATION, {
                    title: 'Филиал удален',
                    redirectUrl: '/profile/projects/',
                    variant: 'success',
                }))
            } else if (deepFind(props, 'generalRequestState.action') == types.DELETE_PROJECT_FAILURE) {
                processDeleting.current = false
                setDisableDeleteButton(false)
                props.dispatch(showModal(modals.MODAL_NOTIFICATION, {
                    title: deepFind(props, 'generalRequestState.errorMessage') || 'Ошибка сервера',
                    variant: 'error',
                    buttonTitle: 'Продолжить',
                }));
            }
        }
    }, [deepFind(props, 'generalRequestState.action')]);

    const onDeleteButtonClick = () => {
        processDeleting.current = true
        props.dispatch(
            showModal(modals.MODAL_TYPE_DELETE_POPUP, {
                title: 'Удалить филиал',
                confirmButton: 'Удалить',
                cancelButton: 'Отменить',
                content: "Все данные филиала будуд удалены безвозвратно. Вы уверены что хотите удалить филиал?",
                confirmAction: types.PROJECT_DELETE,
                cancelAction: types.PROJECT_DELETE_CANCEL,
                hideOnConfirm: true,
                hideOnCancel: true,
                payload: {
                }
            })
        );
    }

    const getProjectName = () => {
        if (!itemID) {
            return <><h1>Добавление проекта</h1></>
        } else {
            return <>
                <div className='row-center-justify'>
                    <h1>Филиал</h1>
                    {/* <h1>{projectData.name}</h1> */}
                    {/* <ButtonBack to="/profile/projects" /> */}
                    <Button
                        customClass={'delete-button'}
                        variant="text"
                        startIcon={<Icon icon="Delete" color={disableDeleteButton ? gray60 : error60} width={16} height={18} />}
                        onClick={onDeleteButtonClick}
                        size={40}
                        disabled={disableDeleteButton}
                    >
                        Удалить
                    </Button>
                </div>
            </>
        }
    }

    return <>
        <Template
            customCaption={itemID ? getProjectName() : null}
            caption={!itemID ? 'Добавление проекта' : null}
            colorVariant={'white-gray'}
        >
            <Grid column={1} gap={10}>
                <GridBlock>
                    {/* <Heading variant='h3'>Подписка</Heading> */}
                    <ProjectForm itemID={itemID} {...props} navigate={navigate}/>
                </GridBlock>
            </Grid>
        </Template>
    </>
}
const mapStateToProps = store => {
    // const pf = store.userState.size > 0 ? store.userState.get("profile") : null;    
    return {
        profile: deepFind(store.userState, 'data'),
        generalRequestState: store.generalRequestState,
        // pf,
        // documentsState: store.documentsState,
        // formValues: {
        //     PASSPORT_RF_NUMBER: selector(store, 'PASSPORT_RF_NUMBER') || '',
        //     PASSPORT_RF_ISSUED_ON: selector(store, 'PASSPORT_RF_ISSUED_ON') || '',
        //     PASSPORT_RF_ISSUED_BY: selector(store, 'PASSPORT_RF_ISSUED_BY') || '',
        //     PASSPORT_RF_DIVISION_CODE: selector(store, 'PASSPORT_RF_DIVISION_CODE') || '',
        //     PASSPORT_RF_BIRTHPLACE: selector(store, 'PASSPORT_RF_BIRTHPLACE') || '',
        //     SNILS: selector(store, 'SNILS') || deepFind(pf, 'SNILS.data.SNILS') || '',
        //     BIRTHDATE: selector(store, 'BIRTHDATE') || deepFind(pf, 'PERSON.data.BIRTHDATE') || '',
        // }
    }
}
const mapDispatchToProps = {
    generalRequest,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectPage);
// export default ProfilePage