export const MODAL_TYPE_GIBDD_ADD_STS = 'MODAL_TYPE_GIBDD_ADD_STS';
export const MODAL_TYPE_GIBDD_VU_EXPIRING = "MODAL_TYPE_GIBDD_VU_EXPIRING";
export const MODAL_TYPE_ADD_STS = 'MODAL_TYPE_ADD_STS';
export const MODAL_TYPE_FLAT_SUBMIT_COUNTERS_DATA = 'MODAL_TYPE_FLAT_SUBMIT_COUNTERS_DATA';
export const MODAL_TYPE_FLAT_ADD_SERVICE = 'MODAL_TYPE_FLAT_ADD_SERVICE';
export const MODAL_TYPE_FLAT_EDIT_SERVICE = 'MODAL_TYPE_FLAT_EDIT_SERVICE';
export const MODAL_TYPE_FLAT_HISTORY = 'MODAL_TYPE_FLAT_HISTORY';
export const MODAL_TYPE_FLAT_MAKE_PAYMENT = 'MODAL_TYPE_FLAT_MAKE_PAYMENT';
export const MODAL_TYPE_CONFIRM_POPUP = 'MODAL_TYPE_CONFIRM_POPUP';
export const MODAL_TYPE_ALERT = 'MODAL_TYPE_ALERT';
export const MODAL_TYPE_SESSION_EXPIRED = 'MODAL_TYPE_SESSION_EXPIRED';
export const MODAL_TYPE_EMPTY = 'MODAL_TYPE_EMPTY';
export const MODAL_TYPE_GIBDD_ADVANCE = 'MODAL_TYPE_GIBDD_ADVANCE';
export const MODAL_TYPE_GIBDD_DETAILS = 'MODAL_TYPE_GIBDD_DETAILS';
export const MODAL_TYPE_ISPP_PAYMENT = 'MODAL_TYPE_ISPP_PAYMENT';
export const MODAL_TYPE_EZHD_HISTORY = 'MODAL_TYPE_EZHD_HISTORY';
export const MODAL_TYPE_EZHD_HOMEWORK = 'MODAL_TYPE_EZHD_HOMEWORK';
export const MODAL_TYPE_CAR_ADD_SERVICE = 'MODAL_TYPE_CAR_ADD_SERVICE';
export const MODAL_TYPE_NOTIFICATIONS_DETAIL = 'MODAL_TYPE_NOTIFICATIONS_DETAIL';
export const MODAL_TYPE_MGTS_PAYMENT = 'MODAL_TYPE_MGTS_PAYMENT';
export const MODAL_TYPE_ADDRESS_INPUT = 'MODAL_TYPE_ADDRESS_INPUT';
export const MODAL_TYPE_DRIVER_LICENSE_INPUT = 'MODAL_TYPE_DRIVER_LICENSE_INPUT';
export const MODAL_TYPE_PASSPORT_RF_INPUT = 'MODAL_TYPE_PASSPORT_RF_INPUT';
export const MODAL_TYPE_BIRTH_CERT_INPUT = 'MODAL_TYPE_BIRTH_CERT_INPUT';
export const MODAL_TYPE_CREATE_FAMILY_MEMBER = 'MODAL_TYPE_CREATE_FAMILY_MEMBER';
export const MODAL_TYPE_SAVE_EDUCATION_ITEM = 'MODAL_TYPE_SAVE_EDUCATION_ITEM';
export const MODAL_TYPE_NOTIFICATIONS_SETTINGS = 'MODAL_TYPE_NOTIFICATIONS_SETTINGS';
export const MODAL_TYPE_PUSH_MECHANICS = 'MODAL_TYPE_PUSH_MECHANICS';
export const MODAL_TYPE_VU_ADD_SERVICE = 'MODAL_TYPE_VU_ADD_SERVICE';
export const MODAL_TYPE_TRUSTEE = 'MODAL_TYPE_TRUSTEE';
export const MODAL_TYPE_HEALTH_WIDGET_ADD = 'MODAL_TYPE_HEALTH_WIDGET_ADD';
export const MODAL_TYPE_ALLCHARGES_WIDGET_PREPARE = 'MODAL_TYPE_ALLCHARGES_WIDGET_PREPARE';
export const MODAL_TYPE_ALLCHARGES_WIDGET_LIST = 'MODAL_TYPE_ALLCHARGES_WIDGET_LIST';
export const MODAL_TYPE_ALLCHARGES_NOT_FILLED_NOTICE = 'MODAL_TYPE_ALLCHARGES_NOT_FILLED_NOTICE';
export const MODAL_TYPE_ADD_PROFILE_DATA_FOR_SNILS = 'MODAL_TYPE_ADD_PROFILE_DATA_FOR_SNILS';
export const MODAL_TYPE_HEALTH_WIDGET_CHOOSE_RECIPES_MODAL = 'MODAL_TYPE_HEALTH_WIDGET_CHOOSE_RECIPES_MODAL';
export const MODAL_TYPE_HEALTH_WIDGET_RECORDS = 'MODAL_TYPE_HEALTH_WIDGET_RECORDS';
export const MODAL_TYPE_EVA_WIDGET_CAR = 'MODAL_TYPE_EVA_WIDGET_CAR';
export const MODAL_TYPE_CHECKUP_COUNTERS = 'MODAL_TYPE_CHECKUP_COUNTERS';
export const MODAL_TYPE_PFR_WIDGET_DETAIL = 'MODAL_TYPE_PFR_WIDGET_DETAIL';
export const MODAL_TYPE_PFR_ADD_SERVICE = 'MODAL_TYPE_PFR_ADD_SERVICE';
export const MODAL_TYPE_GIBDD_FINES = 'MODAL_TYPE_GIBDD_FINES';
export const MODAL_TYPE_GIBDD_FINES_CONFIRM = 'MODAL_TYPE_GIBDD_FINES_CONFIRM';
export const MODAL_TYPE_SPORTS_CLUBS_DETAIL = 'MODAL_TYPE_SPORTS_CLUBS_DETAIL';
export const MODAL_TYPE_SPORTS_CLUBS_NOTIFICATION = 'MODAL_TYPE_SPORTS_CLUBS_NOTIFICATION';
export const MODAL_TYPE_PRIVATE_DATA = 'MODAL_TYPE_PRIVATE_DATA';
export const MODAL_TYPE_PASSPORT_INPUT = 'MODAL_TYPE_PASSPORT_INPUT';
export const MODAL_TYPE_HEALTH_WIDGET_FAVORITES = 'MODAL_TYPE_HEALTH_WIDGET_FAVORITES';
export const MODAL_TYPE_EDUCATION_WIDGET_DETAIL = 'MODAL_TYPE_EDUCATION_WIDGET_DETAIL';
export const MODAL_TYPE_OLYMPIAD_FORM_PARTICIPANT = 'MODAL_TYPE_OLYMPIAD_FORM_PARTICIPANT';
export const MODAL_TYPE_GIA_FORM_PARTICIPANT = 'MODAL_TYPE_GIA_FORM_PARTICIPANT';
export const MODAL_TYPE_AC_POOLS_DETAIL = 'MODAL_TYPE_AC_POOLS_DETAIL';
export const MODAL_TYPE_AC_DISCUSSIONS_DETAIL = 'MODAL_TYPE_AC_DISCUSSIONS_DETAIL';
export const MODAL_TYPE_ACTIVE_CITIZEN_ALERT = 'MODAL_TYPE_ACTIVE_CITIZEN_ALERT';
export const MODAL_TYPE_FAVOURITES_ADD = 'MODAL_TYPE_FAVOURITES_ADD';
export const MODAL_TYPE_FAVOURITES_SHOW_ALL = 'MODAL_TYPE_FAVOURITES_SHOW_ALL';
export const MODAL_TYPE_DOCUMENT_REQUISITES = 'MODAL_TYPE_DOCUMENT_REQUISITES';
export const MODAL_TYPE_TROYKA_EDIT = 'MODAL_TYPE_TROYKA_EDIT';
export const MODAL_TYPE_POPUP_ADD_SNILS = "MODAL_TYPE_POPUP_ADD_SNILS"
export const MODAL_BACKDROP = 'MODAL_BACKDROP';
export const MODAL_NOTIFICATION = "MODAL_NOTIFICATION";
export const MODAL_TYPE_FIRST_GREETINGS = "MODAL_TYPE_FIRST_GREETINGS";

/**
 * Уведомления: услуги
 */
export const MODAL_TYPE_SUBSCRIPTION_USLUGI = 'MODAL_TYPE_SUBSCRIPTION_USLUGI';
export const MODAL_TYPE_CHARGE_RECEIPT = 'MODAL_TYPE_CHARGE_RECEIPT';

export const MODAL_TYPE_CHILDREN_HISTORY = 'MODAL_TYPE_CHILDREN_HISTORY';

export const MODAL_TYPE_INFO = 'MODAL_TYPE_INFO';
export const MODAL_TYPE_DELETE_POPUP = 'MODAL_TYPE_DELETE_POPUP';


/**
 * Легализация
 */

export const MODAL_TYPE_LEGALIZATION = 'MODAL_TYPE_LEGALIZATION';


/**
 * ЦХЭД
 */

export const MODAL_CHED_REBIND = 'MODAL_CHED_REBIND';
export const MODAL_CHED_DELETING = 'MODAL_CHED_DELETING';
export const MODAL_CHED_ADDING = 'MODAL_CHED_ADDING';
export const MODAL_CHED_ADD_IMAGE = 'MODAL_CHED_ADD_IMAGE';
export const MODAL_CHED_FILTER_MOBILE = 'MODAL_CHED_FILTER_MOBILE';
export const MODAL_CHED_BACKDROP_MOBILE = 'MODAL_CHED_BACKDROP_MOBILE';


export const MODAL_ADD_SNILS = 'MODAL_ADD_SNILS';
export const MODAL_ADD_FOREIGN_PASSPORT = 'MODAL_ADD_FOREIGN_PASSPORT';
export const MODAL_ADD_PASSPORT_RF = 'MODAL_ADD_PASSPORT_RF';
export const MODAL_ADD_OMS = 'MODAL_ADD_OMS';
export const MODAL_ADD_INN = 'MODAL_ADD_INN';
export const MODAL_ADD_VU = 'MODAL_ADD_VU';
export const MODAL_ADD_LIBRARY_TICKET = 'MODAL_ADD_LIBRARY_TICKET';
export const MODAL_ADD_DEPUTY = 'MODAL_ADD_DEPUTY';
export const MODAL_CONFIRM_DEPUTY = 'MODAL_CONFIRM_DEPUTY';
export const MODAL_ADD_REG_ADDRESS = 'MODAL_ADD_REG_ADDRESS';
export const MODAL_VERIFY_REG_ADDRESS = 'MODAL_VERIFY_REG_ADDRESS';
export const MODAL_ADD_ADDRESS = 'MODAL_ADD_ADDRESS';

/**
 ЕСИА
 */

export const MODAL_TYPE_ESIA_EDIT = 'MODAL_TYPE_ESIA_EDIT';
export const MODAL_TYPE_CONTACTS = 'MODAL_TYPE_CONTACTS';
export const MODAL_EMAIL_SMS_FORM = 'MODAL_EMAIL_SMS_FORM';

export const MODAL_TYPE_MESSAGES_DELETE = "NODAL_TYPE_MESSAGES_DELETE";

/** Центр уведомлений */
export const MODAL_APPLICATION_INFO = 'MODAL_APPLICATION_INFO';

export const QR_IMMUNE_MODAL = 'QR_IMMUNE_MODAL';

export const MODAL_TYPE_ISPK = 'MODAL_TYPE_ISPK';

export const MODAL_TYPE_NON_SAVED_CHANGES_MODAL = 'MODAL_TYPE_NON_SAVED_CHANGES_MODAL';

/**
 * Питомцы
 */
export const MODAL_ADD_PET_ADDRESS = 'MODAL_ADD_PET_ADDRESS';
export const MODAL_ADD_PET_OWNER = 'MODAL_ADD_PET_OWNER';

/**
 * Загранпаспорт
 */
export const MODAL_ADD_INTERNATIONAL_PASSPORT = 'MODAL_ADD_INTERNATIONAL_PASSPORT'

export const MODAL_TYPE_CONFIRMING_PASSPORT_CHANGE = 'MODAL_TYPE_CONFIRMING_PASSPORT_CHANGE';
export const MODAL_TG_LINK = 'MODAL_TG_LINK';