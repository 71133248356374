import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {CSSTransition} from "react-transition-group";

const CollapseItem = props => {
    const wrapper = useRef(null);

    const onEnter = () => {
        wrapper.current.style.height = '0px';
        wrapper.current.style.overflow = 'initial';
    }

    const onEntering = () => {
        wrapper.current.style.height = `${wrapper.current.firstChild.getBoundingClientRect().height}px`;
        wrapper.current.style.overflow = 'hidden';
    }

    const onEntered = () => {
        wrapper.current.style.height = 'auto';
        wrapper.current.style.overflow = 'initial';

    }

    const onExit = () => {
        wrapper.current.style.height = `${wrapper.current.firstChild.getBoundingClientRect().height}px`;
        wrapper.current.style.overflow = 'initial';
    }

    const onExiting = () => {
        wrapper.current.style.height = '0px';
        wrapper.current.style.overflow = 'hidden';
    }

    const onExited = () => {
        wrapper.current.style.height = '0px';
        wrapper.current.style.overflow = 'initial';
    }

    return (
        <CSSTransition
            onEnter={onEnter}
            onEntering={onEntering}
            onEntered={onEntered}
            onExit={onExit}
            onExiting={onExiting}
            onExited={onExited}
            {...props}
        >
            <div ref={wrapper} style={{transition: `height ${props.timeout}ms ease-in-out`, overflow: 'initial'}} >
                {props.children}
            </div>
        </CSSTransition>
    );
};

CollapseItem.defaultProps = {
    timeout: 400,
}

CollapseItem.propTypes = {
    timeout: PropTypes.number.isRequired,
    classNames: PropTypes.string,
}

export default CollapseItem;