// actions/api.js
import { API_START, API_END, API_ERROR } from './types';

export const apiStart = label => ({
    type: API_START,
    payload: label
});

export const apiEnd = label => ({
    type: API_END,
    payload: label
});

// export const apiError = error => ({
//     type: API_ERROR,
//     error
// });
export const apiError = (type, error) => ({
    type: type,
    error
});
export const apiActionHook = (type, label) => ({
    type: type,
    payload: label
});