import React, { Fragment } from 'react';
// import * as modal from "../actions/ModalConstants";
// import Config from "../../backend/config/application_config";

/**
 * Возвращает поле объекта по его "пути"
 *
 * @param obj
 * @param path
 * @return {*}
 */
export const deepFind = (obj, path) => {
    let paths = path.split('.'),
        current = obj;
    for (let i = 0; i < paths.length; ++i) {
        if (current === undefined || current === null || current[paths[i]] === undefined || current[paths[i]] === null) {
            return null;
        } else {
            current = current[paths[i]];
        }
    }
    return current;
};
export const transformAposToQuote = (string) => {
    if (string === undefined || string === null) {
        return string;
    }
    return string.replace(/`/ig, `'`);
};
export const uppercaseChars = (chars, string) => {
    if (chars) {
        chars = chars.toLowerCase();
    }
    if (!Array.isArray(chars)) {
        chars = chars.split('');
    }
    return string.replace(/./g, x => x && chars.indexOf(x) !== -1 ? x.toUpperCase() : x);
};