import React from 'react';
import {iconzky} from "../../vars.js";

const Epd = ({width = 24, height = 24, className = "", color = iconzky}) => {
    return (
        <svg width={width} height={height} className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1 5.5C1 4.67157 1.67157 4 2.5 4H21.5C22.3284 4 23 4.67157 23 5.5V18.5C23 19.3284 22.3284 20 21.5 20H2.5C1.67157 20 1 19.3284 1 18.5V5.5ZM10 14.5C10 14.2239 10.2239 14 10.5 14H15.5C15.7761 14 16 14.2239 16 14.5C16 14.7761 15.7761 15 15.5 15H10.5C10.2239 15 10 14.7761 10 14.5ZM10.5 12C10.2239 12 10 12.2239 10 12.5C10 12.7761 10.2239 13 10.5 13H19.5C19.7761 13 20 12.7761 20 12.5C20 12.2239 19.7761 12 19.5 12H10.5ZM10 9.5C10 9.22386 10.2239 9 10.5 9H15.5C15.7761 9 16 9.22386 16 9.5C16 9.77614 15.7761 10 15.5 10H10.5C10.2239 10 10 9.77614 10 9.5ZM10.5 7C10.2239 7 10 7.22386 10 7.5C10 7.77614 10.2239 8 10.5 8H19.5C19.7761 8 20 7.77614 20 7.5C20 7.22386 19.7761 7 19.5 7H10.5Z" fill={color}/>
        </svg>
    );
};

export default Epd;