import React from 'react';
import {blue60} from "../../vars";

const PrintEmpty = ({width = 18, height = 18, color = blue60, className = ''}) => {
  return (
      <svg width={width} height={height} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="3.5" y="0.5" width="11" height="4.25" rx="0.5" strokeWidth={1.5} stroke={blue60}/>
          <rect x="0.5" y="4.5" width="17" height="9" rx="0.5" strokeWidth={1.5} stroke={blue60}/>
          <rect x="3.5" y="10.5" width="11" height="6" rx="0.5" strokeWidth={1.5} fill="white" stroke={blue60}/>
      </svg>
  );
};

export default PrintEmpty;


