import React from 'react';
import InputElement from 'react-input-mask';
// import AutosuggestField from './AutosuggestField';
import { deepFind, transformAposToQuote, uppercaseChars } from "../Helpers/Common";
import { dateAddNYears, isDateGreaterThan, getDateFromString, humanizeDate } from "../Helpers/Dates";
// import * as types from "../actions/ApplicationConstants";
// import {isDateGreaterThan} from "../Helpers/Dates";
// import {getRegAddressFromProfile, isAddressesEqualsByUnom} from "../Helpers/Address";
// import { CheckboxEvent, RadioButtonEvent } from '../modules/Events/actions';
import moment from 'moment';
const CAR_ALLOWED_CHARS = 'АВЕКМНОРСТУХYKEHXBAPOCMTавекмнорстухykehxbapocmt';
const ALL_CHARACTERS_ENG_RU = 'QWERTYUIOPASDFGHJKLZXCVBNMqwertyuiopasdfghjklzxcvbnmЁЙЦУКЕНГШЩЗХЪФЫВАПРОЛДЖЭЯЧСМИТЬБЮёйцукенгшщзхъфывапролджэячсмитьбю'
const dateMinNYears = (n, addDay, message) => value => {
    if (value === '' || value === undefined || value === null) {
        return undefined;
    }

    value = getDateFromString(value);
    if (!(value instanceof Date)) {
        return undefined;
    }

    let minDate = new Date();
    if (addDay) {
        minDate.setDate(minDate.getDate() + addDay);
    }
    minDate.setFullYear(minDate.getFullYear() - n);

    return isDateGreaterThan(value, minDate) ? undefined : (message || `Не более ${n} лет`);
};
const dateMaxNYears = (n, addDay, message) => value => {
    if (value === '' || value === undefined || value === null) {
        return undefined;
    }

    value = getDateFromString(value);
    if (!(value instanceof Date)) {
        return undefined;
    }

    let minDate = new Date();
    if (addDay) {
        minDate.setDate(minDate.getDate() + addDay);
    }
    minDate.setFullYear(minDate.getFullYear() - n);

    return isDateGreaterThan(minDate, value) ? undefined : (message || `Не менее ${n} лет`);
};
export const FormValidateMessages = {
    required: "Поле обязательно для заполнения",
    email: "Введите корректный адрес электронной почты",
    number: "Введите корректное число",
    phone: "Номер телефона указан неверно",
    passwordSame: "Пароли не совпадают",
    maxlength: "Введите не более {0} символов",
    minlength: "Введите не менее {0} символов",
    fio: "Допускаются для ввода только русские буквы, пробел, тире, апостроф и точка",
    name: "Допускаются для ввода только русские буквы, литинские буквы, цифры, пробел, тире, апостроф и точка, нижнее подчеркивание",
    date: "Введите правильную дату",
    color: "Некорректный код цвета",
}
const minLength = (length) => value => {
    if (value === undefined || value === null)
        return undefined;
    return value.length >= length ? undefined : FormValidateMessages.minlength.replace('{0}', length);
};
const maxLength = (length) => value => {
    if (value === '' || value === undefined || value === null)
        return undefined;
    return value.length <= length ? undefined : FormValidateMessages.maxlength.replace('{0}', length);
};
export const FormUtilsValidator = {
    required: value => (value ? undefined : FormValidateMessages.required),
    email: value => {
        if (value === '' || value === undefined || value === null)
            return undefined;
        return /^[\-а-яёa-z0-9!#$%&'*+/=?^_`{|}~]+(?:\.[\-a-zа-яё0-9!#$%&'*+/=?^_`{|}~]+)*@(?:[a-zа-яё0-9]([\-а-яёa-z0-9]{0,61}[а-яёa-z0-9])?\.)+(?:[a-zа-яё0-9]{2,})$/i.test(value)
            ? undefined : FormValidateMessages.email;
    },
    number: value => {
        if (value === '' || value === undefined || value === null)
            return undefined;
        return /^[0-9]+$/i.test(value)
            ? undefined : FormValidateMessages.number;
    },
    color: value => {
        if (value === '' || value === undefined || value === null)
            return undefined;
        return /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i.test(value)
            ? undefined : FormValidateMessages.color;
    },
    phone: value => {
        if (value === '' || value === undefined || value === null)
            return undefined;
        return /^\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/i.test(value) ? undefined : FormValidateMessages.phone;
    },
    passwordSame: (value, allValues, props, field) => {
        if (value === '' || value === undefined || value === null)
            return undefined;
        return value === allValues.PASSWORD ? undefined : FormValidateMessages.passwordSame;
    },
    minLength: (length) => (value) => {
        if (value === undefined || value === null)
            return undefined;
        return value.length >= length ? undefined : FormValidateMessages.minlength.replace('{0}', length);
    },
    minLength6: minLength(6),
    maxLength75: maxLength(75),
    maxLength150: maxLength(150),
    maxLength300: maxLength(300),
    maxLength107: maxLength(107),
    maxLength50: maxLength(50),
    fio: value => {
        if (value === '' || value === undefined || value === null)
            return undefined;
        return /^[а-яё\.]+([- \`\']{1}[а-яё\.]+)*\.?$/i.test(value) ? undefined : FormValidateMessages.fio
    },
    name: value => {
        if (value === '' || value === undefined || value === null)
            return undefined;
        return /^[а-яёa-z\.\(\)_0-9]+([- \`\'\(\)_0-9]{1}[а-яёa-z\.\(\)_0-9]+)*\.?$/i.test(value) ? undefined : FormValidateMessages.name
    },
    date: value => {
        if (value === '' || value === undefined || value === null)
            return undefined;
        let matches = /^(\d{1,2})[.\/](\d{1,2})[.\/](\d{4})$/.exec(value),
            error = null;
        if (matches) {
            let d = parseInt(matches[1], 10);
            let m = parseInt(matches[2], 10) - 1;
            let y = parseInt(matches[3], 10);
            let composedDate = new Date(y, m, d);
            let result = composedDate.getDate() === d && composedDate.getMonth() === m && composedDate.getFullYear() === y;

            if (!result) error = FormValidateMessages.date;
            if (y < 1900) error = FormValidateMessages.date;

        } else {
            error = FormValidateMessages.date;
        }
        return error
    },
    minYears18: dateMaxNYears(18, 1, 'Ваш возраст не может быть младше 18 лет'),
    noFutureDates: value => {
        if (value === '' || value === undefined || value === null) {
            return undefined;
        }

        if (typeof value === 'string' && /^\d{2}\.\d{2}\.\d{4}$/.test(value)) {
            value = value.split('.').reverse().join('-');
        }

        try {
            value = new Date(value);
        } catch (e) {
            value = null;
        }

        if (!(value instanceof Date)) {
            return undefined;
        }

        let maxDate = new Date();
        return isDateGreaterThan(maxDate, value, false, false) ? undefined : 'Дата не должна быть из будущего';
    },
    minDate: (value, allValues, props, fieldName) => {
        if (value === '' || value === undefined || value === null) {
            return undefined;
        }

        value = getDateFromString(value);
        if (!(value instanceof Date)) {
            return undefined;
        }

        // TODO: ужасное решение передавать minDate таким образом, разобраться как делать это нормально
        if (!props.formValues || !props.formValues.hasOwnProperty(fieldName + '_validatorMinDate')) {
            return undefined;
        }
        let minDate = getDateFromString(props.formValues[fieldName + '_validatorMinDate']);
        if (!(minDate instanceof Date)) {
            try {
                minDate = Date.parse(minDate) || null;
            } catch (e) {
                minDate = null;
            }
        }

        if (!(minDate instanceof Date)) {
            return '[Некорректная minDate]';
        }

        let notice = props.formValues.hasOwnProperty(fieldName + '_validatorMinDate_notice')
            ? props.formValues[fieldName + '_validatorMinDate_notice']
            : `Дата должна быть не ранее ${humanizeDate(minDate, true)}`;

        return isDateGreaterThan(value, minDate, false, false) ? undefined : notice;
    },
    maxDate: (value, allValues, props, fieldName) => {
        if (value === '' || value === undefined || value === null) {
            return undefined;
        }

        value = getDateFromString(value);
        if (!(value instanceof Date)) {
            return undefined;
        }
        if (!props.formValues.hasOwnProperty(fieldName + '_validatorMaxDate')) {
            return undefined;
        }
        let maxDate = getDateFromString(props.formValues[fieldName + '_validatorMaxDate']);

        if (!(maxDate instanceof Date)) {
            try {
                maxDate = Date.parse(maxDate) || null;
            } catch (e) {
                maxDate = null;
            }
        }

        if (!(maxDate instanceof Date)) {
            return '[Некорректная maxDate]';
        }

        let notice = props.formValues.hasOwnProperty(fieldName + '_validatorMaxDate_notice')
            ? props.formValues[fieldName + '_validatorMaxDate_notice']
            : `Дата должна быть не позднее ${humanizeDate(maxDate, true)}`;

        return isDateGreaterThan(maxDate, value, false, false) ? undefined : notice;
    },
    // minLength: (value, allValues, props, fieldName) => {
    //     if (value === '' || value === undefined || value === null) {
    //         return undefined;
    //     }
    //     console.log(111111)
    //     console.log(111111)
    //     console.log(props.formValues)
    //     console.log(props.formValues.hasOwnProperty(fieldName + '_minlength'))
    //     // TODO: ужасное решение передавать minDate таким образом, разобраться как делать это нормально
    //     if (!props.formValues || !props.formValues.hasOwnProperty(fieldName + '_minlength')) {
    //         return undefined;
    //     }
    //     return 'error'
    // },
}
export const FormUtilsMask = {
    tariffPeriod: {
        // mask: '9c',      
        mask: (currentValue) => {
            console.log(1111111)
            console.log(1111111)
            console.log(1111111)
            console.log(currentValue)
            if (currentValue === null || currentValue === undefined || currentValue === '') {
                return '9c';
            } else {                
                return '9'.repeat(Math.max(currentValue.length, 3)) + 'd'
            }
        },
        formatChars: {
            '9': '[0-9]{1,3}',
            'c': '[d|m]'
        },
        maskChar: null,
        maxLength: 50
    },
    floatNumber: {
        mask: (currentValue) => {
            if (currentValue === null || currentValue === undefined || currentValue === '') {
                return '9';
            } else {
                let dotIndex = currentValue.indexOf('.');
                if (dotIndex === -1) {
                    dotIndex = currentValue.indexOf(',');
                }
                if (dotIndex === -1) {
                    return '9'.repeat(Math.min(currentValue.length, 7)) + (currentValue.length < 7 ? 'd' : 'D');
                } else {
                    return '9'.repeat(dotIndex) + '.' + '999'
                }
            }
        },
        formatChars: { 'd': '[0-9.,]', 'D': '[.,]', '9': '[0-9]' },
        maskChar: null,
        beforeMaskedValueChange: (newState, oldState) => {
            let newValue = newState.value;
            let newSelection = newState.selection;
            if (
                oldState.value.match(/\d[.|,]$/) &&
                !newValue.match(/[.|,]/) &&
                (newValue.length + 2 === oldState.value.length)
            ) {
                const arr = oldState.value.split('');
                arr.pop();
                newValue = arr.join('');
                newSelection = {
                    start: newValue.length,
                    end: newValue.length,
                }
            }

            return {
                value: newValue,
                selection: newSelection,
            }
        }
    },
    fraction: {
        mask: (currentValue) => {
            if (currentValue === null || currentValue === undefined || currentValue === '') {
                return '9';
            } else {
                const slashIndex = currentValue.indexOf('\/');
                if (slashIndex === -1) {
                    return '9'.repeat(Math.min(currentValue.length, 4)) + (currentValue.length < 4 ? 'd' : '/D');
                } else {
                    return '9'.repeat(slashIndex) + '/' + '9999'
                }
            }
        },
        formatChars: { 'd': '[0-9\/]', 'D': '\/', '9': '[0-9]' },
        maskChar: null
    },
    sts: {
        mask: '9999999999',
        formatChars: {
            '9': '[0-9авекмнорстухухykehxbapocmtАВЕКМНОРСТУХУХYKEHXBAPOCMT]'
        },
        maskChar: null,
        hint: 'Используйте цифры и прописные русские буквы. Пример: 77АА123456 или 1234567890',
        maxLength: 10
    },
    gia_reg_code: {
        mask: '9999-9999-9999',
        maskChar: null
    },
    card_lib: {
        mask: '9999999999999',
        maxLength: 13
    },
    gia_document_number: {
        mask: '999999999999',
        formatChars: {
            '9': `[0-9${ALL_CHARACTERS_ENG_RU}]`
        },
        maskChar: null,
        maxLength: 12
    },
    olympiad_login: {
        mask: 'v99.999.999',
        formatChars: { 'v': '[v]', '9': '[0-9]' },
        maskChar: null,
        maxLength: 11
    },
    /**
     * Новая маска для REG_NUMBER:
     * Формат:
     * М000ММ00,
     * М000ММ000,
     * ММ000М00,
     * 0000ММ00,
     * ММ0000 00,
     * где 0 - любая цифра, М - любая русская буква из списка: У, К, Е, Н, Х, В, А, Р, О, С, М, Т.
     */
    car_reg_number: {
        //mask: '9999999999',
        mask: 'AA99AA999',
        formatChars: {
            '9': `[0-9]`,
            'M': `[${CAR_ALLOWED_CHARS}]`,
            'A': `[0-9${CAR_ALLOWED_CHARS}]`,
            'R': `[0-9 ]`
        },
        maskChar: null,
        maxLength: 11,
        beforeMaskedValueChange: (newState, oldState, userInput) => {
            let { value } = newState;
            let selection = newState.selection
            let cursorPosition = selection ? selection.start : null


            if (cursorPosition === 8 && cursorPosition < value.length) {
                selection.start = selection.end = value.length
            }

            return {
                value,
                selection
            }
        }
    },
    car_pts_number: {
        mask: currentValue => {
            if (currentValue === undefined || currentValue === null) {
                return '99MM 9999999';
            }
            let value = currentValue.replace(' ', '');
            if ((value.length >= 5) && ('123'.indexOf(value[0]) < 0) && ('123'.indexOf(value[5]) < 0)) {
                return '99MM 999999';
            }
            if (value.length <= 10) {
                return '99MM 9999999';
            }
            return '199991999999999';
        },
        // mask: '99MM 999999',
        formatChars: {
            'M': `[${CAR_ALLOWED_CHARS}0-9]`,
            '9': '[0-9]',
            '1': '[1-3]',
        },
        maskChar: null
    },
    car_vin: {
        mask: 'AAAAAAAAAAAAAAAAA',
        formatChars: {
            'A': '[0-9a-zA-Zа-яА-Я]'
        },
        maskChar: null
    },
    phone_mgts: {
        mask: '+7 (DDD) DDD-DD-DD',
        formatChars: {
            'D': '[0-9]',
            'N': '[59]',
        },
        maskChar: null
    },
    driver_license_number: {
        mask: '99MM999999',
        formatChars: {
            'M': `[0-9${CAR_ALLOWED_CHARS}]`,
            '9': '[0-9]'
        },
        maskChar: null,
        hint: "Используйте цифры и прописные русские буквы. Пример: 77АК123456",
        maxLength: 11
    },
    oms9_16: {
        mask: currentValue => {
            if (currentValue === undefined || currentValue === null) {
                return '99999999999999999';
            }
            let value = currentValue.replace(' ', '');
            if (value.length <= 9) {
                return '99999999999999999';
            } else {
                return '999999 9999999999';
            }
        },
        formatChars: {
            's': '[0-9 ]',
            '9': '[0-9A-Za-zА-Яа-я]'
        },
        maskChar: null,
        normalize: (value, el) => {
            if (value === null || value === undefined) {
                return '';
            }
            if (value.length <= 9) {
                return value;
            } else {
                if (el && value.length === 10) {
                    setTimeout(() => {
                        el.selectionStart = el.selectionEnd = 100;
                    }, 1);
                }
                return value.substring(0, 6) + ' ' + (value.substring(6) || '').trim();
            }
        },
        hint: "Номер указан на полисе обязательного медицинского страхования («синий» документ).",
        maxLength: 17
    },
    fio: {
        mask: 'F'.repeat(50),
        formatChars: {
            'F': `[а-яА-ЯёЁ\\–\\- '\`ivxlcIVXLC.\(\)]`
        },
        maskChar: null,
        maxLength: 50,
        beforeMaskedValueChange: (newState, oldState, userInput) => {
            let {
                value,
                selection,
            } = newState;

            const match = value.match(/[^\s]\(/);
            if (match) {
                if (selection.start >= match.index + 2) {
                    selection.start += 1;
                }

                if (selection.end >= match.index + 2) {
                    selection.end += 1;
                }

                value = value.replace(/([^\s])(\()/, '$1 $2');
            }

            // Replace '–' to '-'
            value = value.replace(/–/g, '-');

            const regex = /([а-яА-ЯЁё]+[ivxlcIVXLC]+[а-яА-ЯЁё]*)|([ivxlcIVXLC]+[а-яА-ЯЁё]+)/ig;
            const test = value.split(' ').some((item) => {
                return regex.test(item);
            });

            if (test) {
                value = oldState.value;
            }

            value = uppercaseChars('IVXLC', transformAposToQuote(value));

            return {
                value,
                selection,
            }
        }
    },
    fio_extended: {
        mask: 'F'.repeat(50),
        formatChars: {
            'F': `[а-яА-ЯёЁ\\–\\- '\`ivxlcIVXLC.\(\)]`
        },
        maskChar: null,
        maxLength: 50
    },
    birth_place: {
        mask: 'F'.repeat(120),
        formatChars: {
            'F': `[а-яА-ЯёЁ0-9\\–\\- '\`ivxlcIVXLC.]`
        },
        maskChar: null,
        maxLength: 50
    },
};