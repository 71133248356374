import React from 'react';
import {blue60} from "../../vars";

const Search = ({width = 14, height = 15, color = blue60, className = ''}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.0068 6.50319C13.0068 2.91158 10.0952 0 6.50342 0C2.91168 0 0 2.91158 0 6.50319C0 10.0948 2.91168 13.0064 6.50342 13.0064C7.77133 13.0064 8.9545 12.6436 9.95475 12.0161L12.7193 14.7805C13.0122 15.0734 13.4873 15.0731 13.7802 14.7802C14.0731 14.4873 14.0734 14.0122 13.7805 13.7193L11.1323 11.0712C12.2914 9.89684 13.0068 8.28356 13.0068 6.50319ZM6.50342 1.50074C3.74054 1.50074 1.50079 3.74041 1.50079 6.50319C1.50079 9.26597 3.74054 11.5056 6.50342 11.5056C9.26629 11.5056 11.506 9.26597 11.506 6.50319C11.506 3.74041 9.26629 1.50074 6.50342 1.50074Z" fill={color}/>
        </svg>

    );
};

export default Search;

