import React from 'react';
import {blue60} from "../../vars";

const MailFilled = ({ width = 16, height = 12, className = '', color = blue60 }) => {
    return (
        <svg  className={className} width={width} height={height} viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2 0C0.895431 0 0 0.895431 0 2V10C0 11.1046 0.895431 12 2 12H14C15.1046 12 16 11.1046 16 10V2C16 0.895431 15.1046 0 14 0H2ZM3.05382 1.94273L8.00233 5.89865L12.9464 1.94629C13.2569 1.69802 13.7074 1.73571 13.9724 2.03214C14.2589 2.35266 14.2174 2.84809 13.8815 3.11644L8.62437 7.31617C8.25925 7.60784 7.74082 7.60776 7.3758 7.31596L2.11826 3.11303C1.78245 2.84458 1.74098 2.34918 2.02749 2.02864C2.29255 1.73209 2.74315 1.69438 3.05382 1.94273Z" fill={color}/>
        </svg>
    );
};

export default MailFilled;