import React from 'react';
import PropTypes from "prop-types";

import './TableBlock.scss'

const TableBlock = props => {
    const { children } = props
    return (
        <div className='scroll-table'>
            {children}
        </div>
    );
};

TableBlock.propTypes = {
    // show: PropTypes.bool
};

TableBlock.defaultProps = {
    // show: false,
};


export default TableBlock;
