import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, API } from './types';
// import {MAKE_REQUEST_TO_API_SERVER} from "../middleware/Api";
import apiMiddleware from "../../middleware/index";
// import {Schemas} from "../middleware/schemas";
// import {deepFind} from "../Helpers/Common";


/**
 *
 * @param request
 */
const loginUser = (request) => (dispatch, getState) => {

    dispatch({ type: LOGIN_REQUEST })
    apiMiddleware('POST', request, '/login', [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE])
        .then(data => {            
            dispatch(data)
        })
        .catch(err => {            
            dispatch(err)
        })
};

export default loginUser