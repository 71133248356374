import React from 'react';
import {blue60} from "../../vars";

const File = ({ width = 14, height = 16, className = '', color = blue60 }) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.88477 0L14 3.61328V14C14 15.1046 13.1046 16 12 16H2C0.89543 16 0 15.1046 0 14V2C0 0.89543 0.89543 0 2 0H9H9.88477ZM9 1.5V4.13159C9 4.68388 9.44771 5.13159 10 5.13159H12.5V14C12.5 14.2761 12.2761 14.5 12 14.5H2C1.72386 14.5 1.5 14.2761 1.5 14V2C1.5 1.72386 1.72386 1.5 2 1.5H9Z" fill={color} />
        </svg>
    );
};

export default File;