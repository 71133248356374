import React from 'react';
import {blue60} from "../../vars";

 const Return = ({width = 24, height = 24, color=blue60, className}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.77507 5.22783C9.07503 5.53159 9.075 6.02405 8.77502 6.32778L6.90308 8.25L13 8.25H18C19.5188 8.25 20.75 9.48121 20.75 11V15.75C20.75 17.2688 19.5188 18.5 18 18.5H16.2524C16.2516 18.5 16.2508 18.5 16.25 18.5H7.75C7.33579 18.5 7 18.1642 7 17.75C7 17.3358 7.33579 17 7.75 17L13 17H18C18.6904 17 19.25 16.4404 19.25 15.75V11C19.25 10.3096 18.6904 9.75 18 9.75H15.2524C15.2516 9.75 15.2508 9.75 15.25 9.75H6.90308L8.77502 11.6722C9.075 11.9759 9.07503 12.4684 8.77507 12.7722C8.47512 13.0759 7.98878 13.0759 7.6888 12.7722L4.68371 9.69915C4.30367 9.31052 4.30367 8.68948 4.68371 8.30085L7.6888 5.22778C7.98878 4.92405 8.47512 4.92408 8.77507 5.22783Z" fill={color}/>
        </svg>


    );
};

export default Return