import React from 'react';
import PropTypes from 'prop-types';
import Loader from "../loader/Loader.jsx";
import {blue60} from "../vars.js";


import './FormDataFetching.scss';
import FormBlock from "../formBlock/FormBlock.jsx";
import CollapseItem from "../collapseItem/CollapseItem.jsx";
import {TransitionGroup} from "react-transition-group";

const FormDataFetching = (props) => {
    const {
        marginTop,
        isFetching,
        marginBottom,
    } = props;

    return (
        <div>
            <TransitionGroup enter={true} exit={true}>
                {isFetching &&
                    <CollapseItem timeout={200}>
                        <div style={{ overflow: 'hidden' }}>
                            <FormBlock customMarginTop={marginTop}>
                                <div style={{marginBottom: `${marginBottom}px`}} className={'nlk-FormDataFetching'}>
                                    <Loader width={20} height={20} marginRight={10} marginLeft={0} color={blue60}/> Получаем
                                    информацию...
                                </div>
                            </FormBlock>
                        </div>
                    </CollapseItem>
                }
            </TransitionGroup>
        </div>
    );
};


FormDataFetching.defaultProps = {
    marginTop: 24,
    marginBottom: 0,
    isFetching: false,
}

FormDataFetching.propTypes = {
    isFetching: PropTypes.bool,
    marginTop: PropTypes.number,
    marginBottom: PropTypes.number,
}

export default FormDataFetching;