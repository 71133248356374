/**
 * Формирует дату в формате "1 января", "10 октября" ...
 *
 * @param date
 * @param withYear
 * @param withTime
 * @param timePrefix
 */
export const humanizeDate = (date, withYear, withTime, timePrefix, notReplaceDateToCurrentDays) => {
    const MONTHS = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'],
        DAY_IN_MILLISECONDS = 3600 * 24 * 1000,
        CURRENT_DATE = new Date();

    if (!(date instanceof Date)) {
        return null;
    }

    let time = withTime === true ? humanizeTime(date) : null;

    CURRENT_DATE.setHours(0);
    CURRENT_DATE.setMinutes(0);
    CURRENT_DATE.setSeconds(0);
    CURRENT_DATE.setMilliseconds(0);

    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    let humanizeDate = '';

    if (!notReplaceDateToCurrentDays) {
        if (CURRENT_DATE.getTime() === date.getTime()) {
            humanizeDate = 'сегодня';
        } else if (CURRENT_DATE.getTime() - date.getTime() === DAY_IN_MILLISECONDS) {
            humanizeDate = 'вчера';
        } else if (date.getTime() - CURRENT_DATE.getTime() === DAY_IN_MILLISECONDS) {
            humanizeDate = 'завтра';
        } else {
            humanizeDate = date.getDate() + ' ' + MONTHS[date.getMonth()];
            if (withYear === true) {
                humanizeDate += ' ' + date.getFullYear();
            }
        }
    }else {
        humanizeDate = date.getDate() + ' ' + MONTHS[date.getMonth()];
        if (withYear === true) {
            humanizeDate += ' ' + date.getFullYear();
        }
    }

    if (withTime === true) {
        humanizeDate += (timePrefix ? ' ' + timePrefix + ' ' : ' ') + time;
    }

    return humanizeDate;
};

/**
 * Формирует дату в формате "Январь 2017", "Октября 2017" ...
 *
 * @param date
 * @param withYear
 */
export const humanizeMonth = (date, withYear) => {
    const MONTHS = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
    if (!(date instanceof Date)) {
        return null;
    }
    let humanizedMonth = MONTHS[date.getMonth()];
    if (withYear) {
        humanizedMonth += ' ' + date.getFullYear();
    }
    return humanizedMonth;
};


/**
 * Извлекает время из даты
 *
 * @param date
 * @param withSeconds
 */
export const humanizeTime = (date, withSeconds) => {
    let h = date.getHours(),
        m = date.getMinutes(),
        s = date.getSeconds();
    if (h < 10) {
        h = '0' + h;
    }
    if (m < 10) {
        m = '0' + m;
    }
    if (s < 10) {
        s = '0' + s;
    }

    let time = h + ':' + m;
    if (withSeconds) {
        time += ':' + s;
    }

    return time;
};

/**
 * Возвращает дату со смещением
 */
export const getDateWithOffset = (offset) => {
    let d = new Date(),
        utc = d.getTime() + (d.getTimezoneOffset() * 60000);
    return new Date(utc + (3600000*offset));
};

const getMoscowDate = () => {
  const moscowUtcOffset = 3
  return getDateWithOffset(moscowUtcOffset)
}

export const getGreeting = () => {
  const hour = getMoscowDate().getHours()

  if (hour >= 5 && hour < 11) return 'Доброе утро!'
  if (hour >= 11 && hour < 18) return 'Добрый день!'
  if (hour >= 18 && hour < 23) return 'Добрый вечер!'
  if (hour >= 23 || hour < 5) return 'Доброй ночи!'
  return 'Добрый день!';
  // Загрузка...
}

/**
 * Проверяет является {date1} больше, чем {date2}
 *
 * @param date1 Date
 * @param date2 Date
 * @param withTime? boolean
 * @param strict? boolean
 * @return boolean|null
 */
export const isDateGreaterThan = (date1, date2, withTime, strict) => {
    if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
        return null;
    }

    if (withTime !== true) {
        date1.setHours(0);
        date1.setMinutes(0);
        date1.setSeconds(0);
        date1.setMilliseconds(0);

        date2.setHours(0);
        date2.setMinutes(0);
        date2.setSeconds(0);
        date2.setMilliseconds(0);
    }

    return strict !== true ? date1.getTime() >= date2.getTime() : date1.getTime() > date2.getTime();
};

/**
 * Возвращает количество лет, прошедших с {birthDate}
 *
 * @see https://stackoverflow.com/a/21984136
 *
 * @param birthday Date
 */
export const getAge = (birthday) => {
    birthday = getDateFromString(birthday);
    if (!birthday || !(birthday instanceof Date)) {
        return null;
    }

    const now = new Date();
    const years = now.getFullYear() - birthday.getFullYear();
    birthday.setFullYear(now.getFullYear());
    return (birthday < now) ? years : years - 1;
};

export const getDateFromString = (value) => {
    if (value instanceof Date) {
        return new Date(value.getTime());
    }

    if (typeof value === 'string' && /^\d{2}\.\d{2}\.\d{4}$/.test(value)) {
        value = value.split('.').reverse().join('-');
    }

    if (!value) {
        return null;
    }

    try {
        value = new Date(value);
    } catch (e) {
        value = null;
    }

    return value;
};

export const dateAddNYears = (date, n, offset=0) => {
    date = getDateFromString(date);
    if (!date || !(date instanceof Date)) {
        return null;
    }

    date.setFullYear(date.getFullYear() + n);

    if (offset) {
      date.setDate(date.getDate() + offset);
    }

    return date;
};

export const getYear = (date) => {
    date = getDateFromString(date);
    if (!date || !(date instanceof Date)) {
        return null;
    }
    return date.getFullYear();
};

export const  calculateAge = (dmY) => {
    try{
        let ar = dmY.split('.');
        let dt = ar[2]+'-'+ar[1]+'-'+ar[0];
        if(ar.length<3){
            return false;
        }
        var birthday = new Date(dt);
        var ageDifMs = Date.now() - birthday.getTime();
        var ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }catch(e){
        console.log('Error',e);
        return false;
    }
}

export const DateInfo = (str, capitalize) => {

  capitalize = capitalize || false;

  function jsUcfirst(string){
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  // удаление пояса создаем дату считая что она по московскому времени (так js вопспримет)
  function parseISOLocal(s) { var b = s.split(/\D/); return new Date(b[0], b[1]-1, b[2], b[3], b[4], b[5]); }
  // через сколько дней эта дата
  function remainDays(tsDate /*timestamp*/){
    let zdate = new Date(tsDate);
    zdate.setHours(0);
    zdate.setMinutes(0);
    zdate.setSeconds(0);
    let zdatefrom = zdate.getTime(); // начало дня целевого

    const daysTillRecord = (zdatefrom-Date.now())/1000/3600/24;
    let result = Math.floor(daysTillRecord)+1; // сегодня в 23:59 для даты на завтра 00:01 будет 1 день
    return result;
  }

  let weekday_short = {};
      weekday_short[0] =  "вс.";
      weekday_short[1] = "пн.";
      weekday_short[2] = "вт.";
      weekday_short[3] = "ср.";
      weekday_short[4] = "чт.";
      weekday_short[5] = "пт.";
      weekday_short[6] = "сб.";

  let weekDayName = {
      0: 'воскресенье',
      1: 'понедельник',
      2: 'вторник',
      3: 'среда',
      4: 'четверг',
      5: 'пятница',
      6: 'суббота',
  };

  let month_short = {};
      month_short[0] = 'янв.';
      month_short[1] = 'фев.';
      month_short[2] = 'мар.';
      month_short[3] = 'апр.';
      month_short[4] = 'мая';
      month_short[5] = 'июн.';
      month_short[6] = 'июл.';
      month_short[7] = 'авг.';
      month_short[8] = 'сен.';
      month_short[9] = 'окт.';
      month_short[10] = 'ноя.';
      month_short[11] = 'дек.';

   let month = {};
      month[0] = 'января';
      month[1] = 'февраля';
      month[2] = 'марта';
      month[3] = 'апреля';
      month[4] = 'мая';
      month[5] = 'июня';
      month[6] = 'июля';
      month[7] = 'августа';
      month[8] = 'сентября';
      month[9] = 'октября';
      month[10] = 'ноября';
      month[11] = 'декабря';

	//let ts = Date.parse(str);
    //let dt = new Date(ts);
    let dt = parseISOLocal(str);
    let ts = dt.getTime();


    let h = dt.getHours();
    let m = dt.getMinutes();

    if (h < 10) {
        h = '0' + h;
    }
    if (m < 10) {
        m = '0' + m;
    }

    let zeroStartedMonthNumber = (dt.getMonth()+1);
    if(zeroStartedMonthNumber < 10){
        zeroStartedMonthNumber = '0'+zeroStartedMonthNumber;
    }

    let zeroStartedDay = dt.getDate();
    if(zeroStartedDay < 10){
        zeroStartedDay = '0'+zeroStartedDay;
    }

  let result = {
    timestamp: ts,
    weekday_short: weekday_short[ dt.getDay() ],
    weekDayName: weekDayName[ dt.getDay() ],
    month_short: month_short[ dt.getMonth() ],
    month: month[ dt.getMonth() ],
    monthNumber: (dt.getMonth()+1),
    day: dt.getDate(),
    hours: h,
    minutes: m,
    remain_days:remainDays(ts),
    short_date: zeroStartedDay+'.'+zeroStartedMonthNumber
  };

  if(capitalize){
    for(let k in result){
      if(k=='timestamp') continue;
      result[k] = jsUcfirst(''+result[k]);
    }
  }

  return result;

};

export const convertHtmlFormDate = (date) => {
    if(date && /(\d+)\.(\d+)\.(\d+)/.test(date)) return new Date(date.replace(/(\d+)\.(\d+)\.(\d+)/,'$3-$2-$1'));
};

export const minDatepickerDate = new Date(2011, 0, 1);