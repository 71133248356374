import React from 'react';
import {gray60} from "../../vars.js";

const Like = ({width = 24, height = 24, color = gray60, className= ''}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M4.625 18L4.625 11C4.625 10.7929 4.79287 10.625 5 10.625L7 10.625C7.20711 10.625 7.375 10.7929 7.375 11L7.375 18C7.375 18.2071 7.20711 18.375 7 18.375L5 18.375C4.7929 18.375 4.625 18.2071 4.625 18Z" stroke={color} strokeWidth="1.25"/>
                <path d="M18.9589 12.9674L16.7672 17.8919L16.7672 17.8919L16.7646 17.8977C16.6475 18.1682 16.3598 18.375 16 18.375L10.4545 18.375C9.977 18.375 9.625 18.0025 9.625 17.6L9.625 10.6C9.625 10.3932 9.70945 10.2067 9.85631 10.0692L9.85635 10.0692L9.86285 10.063L14.2169 5.86581L14.552 6.1853C14.5523 6.18557 14.5526 6.18583 14.5529 6.1861C14.6286 6.25934 14.6736 6.35603 14.6797 6.45403L14.6651 6.60441L13.9818 9.76806L13.8183 10.525L14.5927 10.525L18.1818 10.525C18.6459 10.525 18.9914 10.8768 19.0105 11.266L19.0093 11.2755L18.9687 11.5879L19.0114 11.6289L19.0114 12.7C19.0114 12.7921 18.9942 12.8779 18.9589 12.9674Z" stroke={color} strokeWidth="1.25"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="16" height="16" fill="white" transform="translate(4 4)"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default Like;