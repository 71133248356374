import React from 'react';
import { connect } from 'react-redux';
import { BaseModalComponent } from "./BaseModalComponent";
import { hideModal } from "../../../store/actions/ModalActions";
import ModalNotification from "../modalNotification/ModalNotification.jsx";
import Button from "../../../common/button/Button.jsx";
import { compose } from "redux";
import { getFormValues } from "redux-form";
import FormBlock from "../../../common/formBlock/FormBlock.jsx";
import ButtonsBlock from "../../../common/buttonsBlock/ButtonsBlock.jsx";
import * as modalTypes from "../../../store/actions/ModalConstants";


/**
 * Используется для модального окна удаления элементов
 * По структуре произошел от `ConfirmModal` для совместимости с использованием в коде
 * Отображает также уведомление об удалении приложеных докуметнов если они есть
 */

const FORM_NAME = 'TRANSPORT_FORM';
class DeleteModal extends BaseModalComponent {


    constructor(props) {
        super(props);
        this.state = {
            chedObjectIdInfo: null,
        };

        this.onRef = this.onRef.bind(this);
        this.confirm = this.confirm.bind(this);
        this.cancel = this.cancel.bind(this);
        this.onClose = this.onClose.bind(this);


    }

    /**
     * Используется для связи с отображаемым списком документов
     * 
     * @param {ChedObjectIdInfo} chedObjectIdInfo
     */
    onRef(chedObjectIdInfo) {
        this.setState({
            chedObjectIdInfo: chedObjectIdInfo,
        });
    }

    confirm() {
        if (this.props.onConfirmCallback) {
            this.props.onConfirmCallback(this.props.formValues.NEED_DELETE_SUBSCRIPTION ? 1 : 0);
        }
        if (this.props.confirmAction) {
            this.props.dispatch({
                type: this.props.confirmAction,
                payload: { ...this.props.payload }
            });
        }        
        if (this.props.hideOnConfirm) {
            this.onClose();
        }
    }

    cancel() {
        if (this.props.cancelAction) {
            this.props.dispatch({
                type: this.props.cancelAction,
                payload: this.props.payload
            });
        }
        if (this.props.hideOnCancel) {
            this.onClose();
        }
    }

    onClose() {
        if (this.props.onCloseCallback) {
            this.props.onCloseCallback();
        }
        this.props.dispatch(
            hideModal(modalTypes.MODAL_TYPE_DELETE_POPUP)
        );
    }

    hideServerError(event, state) {
        this.setState({})
    }

    render() {
        const {
            payload,
            name,
            title,
            content,
            deleteByCodes,
        } = this.props;

        const buttonsBlock = (
            <ButtonsBlock>
                <Button
                    onClick={this.confirm}
                    variant="primary"
                    size={48}
                >
                    Удалить
                </Button>
                <Button
                    variant="text"
                    onClick={this.cancel}
                    size={48}
                >
                    Отменить
                </Button>
            </ButtonsBlock>
        )
        return (
            <ModalNotification
                isShow={this.props.isShow}
                onHide={this.onClose}
                variant={"warning"}
                title={title}
                customButtonsBlock={buttonsBlock}
                buttonTitle={'Удалить'}
            >
                <form>
                    {content &&
                        <FormBlock>
                            {content}
                        </FormBlock>
                    }
                </form>
            </ModalNotification>
        );
    }

}

const mapStateToProps = function (store) {
    return {
        formValues: getFormValues(FORM_NAME)(store) || '',
    };
};

export default compose(
    connect(mapStateToProps)
)(DeleteModal);


