import React from 'react';
import {success60} from "../../vars.js";

const Insurance = ({width = 17, height = 20, className = "", color = success60}) => {
    return (
        <svg width={width} height={height} className={className} viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.0813509 8.61607C0.776106 14.0644 5.88492 18.8155 8.34028 19.5C10.7956 18.8155 15.9045 14.0644 16.5992 8.61607C16.7822 7.18066 16.5992 3.91071 16.5992 3.91071C16.5992 3.91071 12 3 8.34028 0C5 3 0.0813509 3.91071 0.0813509 3.91071C0.0813509 3.91071 -0.101689 7.18066 0.0813509 8.61607Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M11.5578 7.6136C11.8305 7.34426 12.2727 7.34424 12.5454 7.61355C12.8182 7.88287 12.8182 8.31955 12.5455 8.5889L8.43663 12.6337C8.04902 13.0153 7.42751 13.0171 7.0377 12.6377L4.95452 10.6106C4.68181 10.3413 4.68183 9.90459 4.95457 9.63527C5.2273 9.36595 5.66947 9.36597 5.94218 9.63532L7.73914 11.3695L11.5578 7.6136Z" fill="white"/>
        </svg>
    );
};

export default Insurance;