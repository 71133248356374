import React, { Component, useState, useEffect, useRef, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import * as types from "../../../store/actions/types"
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm, initialize, touch, updateSyncErrors } from "redux-form";
import { useSelector } from 'react-redux';
import TableBlock from '../../../common/tableBlock/TableBlock';
import Button from '../../../common/button/Button';
import BalloonHelp from '../../../common/balloonHelp/BalloonHelp';
import Grid from '../../../common/grid/Grid';
import Select from '../../../common/select/Select';
import Icon from "../../../common/icon/Icon";
import GridBlock from '../../../common/gridBlock/GridBlock';
import Heading from '../../../common/heading/Heading'
import FormBlock from "../../../common/formBlock/FormBlock";
import ButtonsBlock from "../../../common/buttonsBlock/ButtonsBlock";
import TextField from "../../../common/textfield/TextField";
import { deepFind } from '../../../../backend/models/helpers';
import { FormUtilsMask, FormUtilsValidator, } from "../../../utils/FormUtils.js";
import { error60, gray60, gray30, gray90, corpGreen } from '../../../common/vars'
import { userProfile } from "../../../store/actions/ProfileActions";
import Context from '../../../utils/context';
import { hideModal, showModal } from "../../../store/actions/ModalActions";
import * as modals from "../../../store/actions/ModalConstants";
import "../settingsPage.scss"
import { TransitionGroup } from "react-transition-group";
import CollapseItem from "../../../common/collapseItem/CollapseItem.jsx";
import Helper from "../../../common/helpers";


const FORM_NAME = 'TARIFFS_FORM';

const TariffsForm = props => {
    // const { profile } = props    
    const windowWidth = useSelector(store => store.windowWidth)
    const isMobile = windowWidth <= 768;
    const context = useContext(Context)
    const [profile, setProfile] = useState({})
    const [tariffs, setTariffs] = useState([])
    const [saveInProgress, setSaveInProgress] = useState(false)
    const tariffType = {
        1: 'Период',
        2: 'Количество',
    }
    const tariffOptions = [
        { value: 1, title: "Период" },
        { value: 2, title: "Количество" }
    ]
    const startType = {
        1: 'С начала месяца',
        2: 'Со дня оплаты',
    }
    const startTypeOptions = [
        { value: 1, title: "С начала месяца" },
        { value: 2, title: "Со дня оплаты" }
    ]
    useEffect(() => {
        if (deepFind(profile, 'settings.tariffs')) {
            setTariffs(deepFind(profile, 'settings.tariffs'))
            props.dispatch(initialize(FORM_NAME, props.formValues));
        }
    }, [profile])
    useEffect(() => {
        setProfile(context.profile)
    }, [context.profile])

    useEffect(() => {
        if (deepFind(props, 'userState.action') == types.USER_SETTINGS_DELETE) {
            props.userProfile('DELETE', deepFind(props, 'userState.data'), '/delete_profile_settings', [types.USER_PROFILE_UPDATE_REQUEST, types.USER_PROFILE_DELETE_SETTINGS_SUCCESS, types.USER_PROFILE_DELETE_SETTINGS_FAILURE])
        } else if (deepFind(props, 'userState.action') == types.USER_PROFILE_DELETE_SETTINGS_SUCCESS) {
            try {
                let profileData = { ...profile },
                    answer = deepFind(props, 'userState.data')
                profileData.settings.tariffs[answer.index].delete = 1
                setProfile(profileData)
            } catch (err) {
                // window.location.reload()
            }
        } else if (deepFind(props, 'userState.action') == types.USER_PROFILE_UPDATE_SUCCESS) {
            let newTariff = deepFind(props, 'userState.data')
            const { reset, clearSubmit, clearFields } = props;
            reset();
            clearSubmit();
            props.dispatch(initialize(FORM_NAME, {
                NAME: '',
                COUNT: 1,
                TYPE: 1,
                STATUS: 1,
                START_TYPE: 1,
                COUNT_TYPE: 1,
            }));
            setTariffs([...tariffs, newTariff])
            setSaveInProgress(false)
        } else if (
            deepFind(props, 'userState.action') == types.USER_PROFILE_UPDATE_FAILURE ||
            deepFind(props, 'userState.action') == types.USER_PROFILE_DELETE_SETTINGS_FAILURE
        ) {
            setSaveInProgress(false)
            props.dispatch(showModal(modals.MODAL_NOTIFICATION, {
                title: deepFind(props, 'userState.errorMessage') ? 'Ошибка' : 'Ошибка сервера',
                children: deepFind(props, 'userState.errorMessage'),
                variant: 'error',
                buttonTitle: 'Продолжить',
            }));
        }
    }, [props.userState.action])

    const deleteTariff = (id, ind) => {
        props.dispatch(
            showModal(modals.MODAL_TYPE_DELETE_POPUP, {
                title: 'Удалить тариф',
                confirmButton: 'Удалить',
                cancelButton: 'Отменить',
                content: "Все данные филиала будут удалены безвозвратно. Вы уверены, что хотите удалить тариф?",
                confirmAction: types.USER_SETTINGS_DELETE,
                cancelAction: types.PROJECT_DELETE_CANCEL,
                hideOnConfirm: true,
                hideOnCancel: true,
                payload: { id: id, field: 'tariffs', ind: ind }
            })
        );
    }
    const handleFormSubmit = values => {
        if (deepFind(profile, 'settings.tariffs')) {
            let hasStatus = deepFind(profile, 'settings.tariffs').find(el => {
                if (el.name == values.NAME && el.delete == 0) {
                    return el
                }
            })
            if (hasStatus) {
                props.dispatch(showModal(modals.MODAL_NOTIFICATION, {
                    title: 'Ошибка',
                    children: 'Такой тариф уже есть',
                    variant: 'error',
                    buttonTitle: 'Продолжить',
                }));
            } else {
                setSaveInProgress(true)
                let request = {
                    tariffs: {
                        name: values.NAME,
                        count: values.COUNT,
                        type: values.TYPE,
                        start_type: values.START_TYPE,
                        count_type: values.COUNT_TYPE,
                        delete: 0,
                    }
                }
                props.userProfile('PUT', request, '/upd_profile_settings', [types.USER_PROFILE_UPDATE_REQUEST, types.USER_PROFILE_UPDATE_SUCCESS, types.USER_PROFILE_UPDATE_FAILURE])
            }
        }
    }

    return <>
        <Grid column={isMobile ? 1 : 2} gap={10}>
            <GridBlock>
                <form className={FORM_NAME} onSubmit={props.handleSubmit(handleFormSubmit)}>
                    {/* direction={isMobile ? 'column' : "row"} */}
                    <FormBlock customMarginTop={0}>
                        <TextField
                            id={'NAME'}
                            name={'NAME'}
                            label={'Название'}
                            size="fullWidth"
                            labelBalloonContent="Цена тарифа устанавливается в направлении"
                            validate={[FormUtilsValidator.required, FormUtilsValidator.name, FormUtilsValidator.maxLength50]}
                        />
                        <Select
                            label="Тип"
                            name="TYPE"
                            id="TYPE"
                            options={tariffOptions}
                            validate={[FormUtilsValidator.required]}
                        />
                        <FormBlock customMarginTop={0} direction="row">
                            <TextField
                                id={'COUNT'}
                                name={'COUNT'}
                                label={`Количество ${props.formValues['TYPE'] == 2 ? 'занятий' : ''}`}
                                // info="Количество"
                                size="default"
                                validate={[FormUtilsValidator.required, FormUtilsValidator.number]}
                            />
                            <TransitionGroup enter={true} exit={true}>
                                {props.formValues['TYPE'] == 1 &&
                                    <CollapseItem timeout={200}>
                                        <Select
                                            name="COUNT_TYPE"
                                            id="COUNT_TYPE"
                                            label="Тип кол-во"
                                            labelHint="Единица измерения количества в периоде"
                                            // size="halfWidth"
                                            options={[
                                                { value: 1, title: [Helper.declOfNum(props.formValues.COUNT, ['День', 'Дня', 'Дней'])] },
                                                { value: 2, title: [Helper.declOfNum(props.formValues.COUNT, ['Месяц', 'Месяца', 'Месецев'])] }
                                            ]}
                                            validate={[FormUtilsValidator.required]}
                                        />
                                    </CollapseItem>
                                }
                            </TransitionGroup>
                        </FormBlock>

                        <TransitionGroup enter={true} exit={true}>
                            {props.formValues['TYPE'] == 1 &&
                                <CollapseItem timeout={200}>
                                    <Select
                                        label="Действие тарифа с"
                                        name="START_TYPE"
                                        id="START_TYPE"
                                        options={startTypeOptions}
                                        validate={[FormUtilsValidator.required]}
                                    />
                                </CollapseItem>
                            }
                        </TransitionGroup>
                        <Button type="submit" variant="primary" disabled={saveInProgress}>
                            Добавить тариф
                        </Button>
                    </FormBlock>
                </form>
            </GridBlock>
            <GridBlock>
                <TableBlock>
                    <table className='tariffs-table'>
                        <thead>
                            <tr>
                                <th>Название</th>
                                <th>Тип</th>
                                <th>Период / Кол-во занятий</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tariffs && tariffs.map((t, ind) => {
                                return t.delete == 0 && <tr>
                                    <td>
                                        {t.name}
                                    </td>
                                    <td>
                                        {tariffType[t.type]}
                                    </td>
                                    <td>
                                        {t.type == 2 &&
                                            <>
                                                {t.count} {Helper.declOfNum(t.count, ['занятие', 'занятия', 'занятий'])}
                                            </>
                                        }
                                        {t.type == 1 &&
                                            <>
                                                {/* { value: 1, title: [Helper.declOfNum(props.formValues.COUNT, ['День', 'Дня', 'Дней'])] },
                                                { value: 2, title: [Helper.declOfNum(props.formValues.COUNT, ['Месяц', 'Месяца', 'Месецев'])] } */}
                                                {t.count} {t.count_type == 1 ?
                                                    Helper.declOfNum(t.count, ['День', 'Дня', 'Дней']) :
                                                    Helper.declOfNum(t.count, ['Месяц', 'Месяца', 'Месецев'])
                                                }
                                                <span className='start-type'>{startType[t.start_type]}</span>
                                            </>
                                        }
                                    </td>
                                    <td>
                                        <Button variant="ghost" onClick={() => { deleteTariff(t.id, ind) }}>
                                            <Icon icon="Delete" width={20} height={20} color={error60} />
                                        </Button>
                                    </td>
                                </tr>
                            })}
                        </tbody>

                    </table>
                </TableBlock>
            </GridBlock>
        </Grid>
    </>
}
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = store => {
    // const pf = store.userState.size > 0 ? store.userState.get("profile") : null;    
    return {
        // profile: deepFind(store.userState, 'data'),
        userState: store.userState,
        formValues: {
            NAME: selector(store, 'NAME') || '',
            COUNT: selector(store, 'COUNT') || 1,
            TYPE: selector(store, 'TYPE') || 1,
            STATUS: selector(store, 'STATUS') || 1,
            START_TYPE: selector(store, 'START_TYPE') || 1,
            COUNT_TYPE: selector(store, 'COUNT_TYPE') || 1,
        }
    }
}
const mapDispatchToProps = {
    userProfile
};
const RowDetailForm = reduxForm({
    form: FORM_NAME
})(TariffsForm);
export default connect(mapStateToProps, mapDispatchToProps)(RowDetailForm);