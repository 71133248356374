import React, { useContext, useState, useRef, useEffect } from 'react';
import Link from "../../common/link/Link";
import Context from '../../utils/context';
import logo from '../../img/logo-w.svg'
import Button from "../../common/button/Button";
import Icon from "../../common/icon/Icon";
import './header.scss'
import Cookies from 'universal-cookie';
import moment from 'moment';
import sendReqest from "../../middleware/sendRequest"
import { useDispatch } from "react-redux";
import { hideModal, showModal } from "../../store/actions/ModalActions";
import * as modals from "../../store/actions/ModalConstants";
import { deepFind } from '../../../backend/models/helpers';
import { useSelector } from 'react-redux';
import { TransitionGroup } from "react-transition-group";
import CollapseItem from "../../common/collapseItem/CollapseItem.jsx";

const cookies = new Cookies();

const Header = () => {
    const windowWidth = useSelector(store => store.windowWidth)    
    const isMobile = windowWidth <= 768;
    const context = useContext(Context)
    const [showMobMenu, setShowMobMenu] = useState(false)
    const dispatch = useDispatch();
    const menu = useRef();    

    const logout = () => {
        sendReqest('GET', null, '/logout')
            .then(data => {
                cookies.set('token', null, { path: '/', expires: moment().add(-12, 'hours').toDate() })
                window.location.href = "/"
            })
            .catch(err => {
                dispatch(showModal(modals.MODAL_NOTIFICATION, {
                    title: 'Ошибка сервера',
                    children: 'Не получилось разлогировать пользователя',
                    variant: 'error',
                    buttonTitle: 'Продолжить',
                }))
            })
    }
    const handleClickOutside = (e) => {
        if (menu.current && menu.current.contains(e.target)) {
            return;
        }
        setShowMobMenu(false);
    };
    useEffect(() => {
        if (showMobMenu) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [showMobMenu]);

    const handleOpen = (e) => {
        e.preventDefault();
        setShowMobMenu(!showMobMenu)
    }

    return (
        <>
            <div className='header' ref={menu}>
                <section>
                    <div className='logo'>
                        <img src={logo} alt="AG.Platform" />
                    </div>
                    {!isMobile &&
                        <>
                            <nav>
                                {/* exact */}
                                <Link exact={true} to='/profile' onClick={() => { setShowMobMenu(!showMobMenu) }} routerLink={true} activeClassName={'active'} isUnderlined={false} customClass={'color-white-green'}>
                                    Профиль
                                </Link>
                                <Link to='/profile/projects' onClick={() => { setShowMobMenu(!showMobMenu) }} routerLink={true} activeClassName={'active'} isUnderlined={false} customClass={'color-white-green'}>
                                    {/* style={{ padding: '5px' }} */}
                                    Филиалы
                                </Link>
                                <Link to='/profile/settings' onClick={() => { setShowMobMenu(!showMobMenu) }} routerLink={true} activeClassName={'active'} isUnderlined={false} customClass={'color-white-green'}>
                                    Настройки
                                </Link>
                            </nav>
                            <Button
                                size={40}
                                variant="ghost"
                                onClick={() => { logout() }}
                                customClass={'logout'}
                            >
                                Выйти
                            </Button>
                        </>
                    }

                    {isMobile &&
                        <Button
                            size={40}
                            variant="ghost"
                            onClick={handleOpen}
                            customClass={'mobmenu-btn'}
                        >
                            <Icon icon={`${showMobMenu ? 'Close' : 'Menu'}`} color="#fff" width={24} height={24} />
                        </Button>
                    }
                </section>
                {/* {!context.authState
                ? <button onClick={() => context.authObj.login()}>Login</button>
                : <button onClick={() => context.authObj.logout()}>Logout</button>
            } */}

                {isMobile &&
                    <TransitionGroup enter={true} exit={true}>
                        {showMobMenu ?
                            <CollapseItem timeout={200}>
                                <div className='mob-menu'>
                                    <ul>
                                        <li>
                                            <Link exact={true} to='/profile' routerLink={true} activeClassName={'active'} isUnderlined={false} customClass={'color-white-green'}>
                                                Профиль
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/profile/projects' routerLink={true} activeClassName={'active'} isUnderlined={false} customClass={'color-white-green'}>
                                                Филиалы
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/profile/settings' routerLink={true} activeClassName={'active'} isUnderlined={false} customClass={'color-white-green'}>
                                                Настройки
                                            </Link>
                                        </li>
                                    </ul>
                                    <Button
                                        size={40}
                                        variant="ghost"
                                        onClick={() => { logout() }}
                                        customClass={'logout'}
                                    >
                                        Выйти
                                    </Button>
                                </div>
                            </CollapseItem> : null
                        }
                    </TransitionGroup>
                }
            </div>
        </>
    )
};





export default Header;
