import React from 'react';
import {blue60} from "../../vars";

const RatingFull = ({width = 18, height = 18, color = blue60, className = ''}) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.42721 0.776103L11.7798 5.50037C11.8497 5.64623 11.9829 5.74745 12.1368 5.77027L17.0932 6.65241C17.4833 6.71095 17.6382 7.21603 17.3548 7.5028L13.8453 11.2378C13.7329 11.3529 13.681 11.5196 13.7074 11.6813L14.4743 16.9166C14.5414 17.3224 14.1362 17.633 13.7886 17.4425L9.22416 15.1136C9.08721 15.0392 8.92382 15.0392 8.78688 15.1136L4.21768 17.4425C3.86918 17.633 3.46496 17.3224 3.53202 16.9166L4.30835 11.6783C4.33574 11.5156 4.28379 11.3499 4.17046 11.2348L0.645809 7.5028C0.361533 7.21603 0.516421 6.71095 0.907419 6.65241L5.86101 5.75935C6.01495 5.73653 6.14812 5.63532 6.21706 5.48945L8.5791 0.776103C8.75383 0.407966 9.25249 0.407966 9.42721 0.776103Z" fill={color} />
        </svg>
    );
};

export default RatingFull;