import React from 'react';
import PropTypes from "prop-types";

import Button from "../button/Button";
import Icon from "../icon/Icon";
import {gray80} from "../vars";

import './Notification.scss';

const Notification = (props) => {
    const {
        type,
        header,
        content,
        withButton,
        buttonContent,
        onButtonClick,
        validation,
        withCloseButton,
        onCloseButtonClick,
    } = props;

    let icon = null;
    switch(type) {
        case 'error':
            icon = validation ? 'Notification/Error' : 'Notification/ErrorWithOval';
            break;
        case 'warning':
            icon = validation ? 'Notification/Warning' : 'Notification/WarningWithOval';
            break;
        case 'success':
            icon = validation ? 'Notification/Success' : 'Notification/SuccessWithOval';
            break;
        case 'information':
            icon = validation ? 'Notification/Information' : 'Notification/Information';
            break;
    }

    const iconContent = (
        <Icon
            icon={icon}
            width={validation ? 18 : 28}
            height={validation ? 18 : 28}
            className="nlk-notification__icon"
        />
    );

    const closeButtonContent = withCloseButton ?
        <div className="nlk-notification__closeButton" onClick={onCloseButtonClick}>
            <Icon icon="Mini/Close" height={11} width={11} color={gray80}/>
        </div> :
        null


    return (
        <div
            className={`nlk-notification nlk-notification_${type} ${validation ? 'nlk-notification_validation': ''} ${!header ? 'nlk-notification_headless': ''}`}
        >
            <div className="nlk-notification__body">
                {header ?
                    <div className="nlk-notification__header">
                        <div className="nlk-notification__iconWrapper">
                            {iconContent}
                        </div>
                        <div className="nlk-notification__headerContent">{header}</div>
                        {closeButtonContent}
                    </div> :
                    <div>
                        <div>{iconContent}</div>
                    </div>
                }
                <div className="nlk-notification__content">{content}</div>
                { withButton
                    ? <div className="nlk-notification__button">
                        <Button variant={validation ? 'whiteBordered' : 'secondary'} size={32} onClick={onButtonClick}>{buttonContent}</Button>
                    </div>
                    : null
                }
                {!header && closeButtonContent}
            </div>
        </div>
    );
};

Notification.defaultProps = {
    header: '',
    content: '',

    withButton: false,
    buttonContent: null,
    onButtonClick: () => {},
    validation: false,

    withCloseButton: false,
    onCloseButtonClick: () => {},
};

Notification.propTypes = {
    type: PropTypes.oneOf(['error', 'warning', 'success', 'information',]).isRequired,
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),

    withButton: PropTypes.bool,
    buttonContent: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    onButtonClick: PropTypes.func,
    validation: PropTypes.bool,

    withCloseButton: PropTypes.bool,
    onCloseButtonClick: PropTypes.func,
};

export default Notification;
