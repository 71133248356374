import React from 'react';
import { gray60 } from "../../vars.js";

export const MoreVertical = ({ width = 20, height = 20, color = gray60, className = '' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class={className}>
            <circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle>
        </svg>
    );
};

export default MoreVertical;