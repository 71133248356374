import React, { useEffect, useRef, useState } from 'react';
import PropTypes from "prop-types";
import InputMask from 'react-input-mask';
import { Field } from "redux-form";
import FieldError from "../fiedlError/FieldError";
import BalloonHelp from "../balloonHelp/BalloonHelp";
import Icon from "../icon/Icon";
import DatePicker from "react-datepicker";
import moment from "moment";
import Button from "../button/Button";
import { gray40, gray60, black } from "../vars.js";
import ru from "date-fns/locale/ru";
import 'moment/locale/ru';
import './react-datepicker.min.css';
import './DatepickerReload.scss';
import "./Datepicker.scss";


const validateDate = value => {
    if (
        !value ||
        (/^\d{2}\.\d{2}\.\d{4}$/.test(value) && moment(value, 'DD.MM.YYYY').isValid())
    ) {
        return false;
    }
    return 'Неверный формат даты';
}

const renderHeaderDecorator = ({ increaseCalendarMode, calendarMode, minDate, maxDate }) => (props) => {
    const {
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
    } = props;

    const momentDate = moment(date);
    const year = momentDate.format('YYYY');
    let increaseFunc = null;
    let decreaseFunc = null;
    let headerText = null;

    if (calendarMode === 'year') {
        increaseFunc = () => changeYear(parseInt(year, 10) + 20);
        decreaseFunc = () => changeYear(parseInt(year, 10) - 20);
        const remainder = (momentDate.year() % 20) - 1;
        const yearFrom = moment(momentDate).year(momentDate.year() - remainder).format('YYYY');
        const yearTo = moment(momentDate).year(momentDate.year() - remainder + 19).format('YYYY');

        headerText = (
            <div>{yearFrom} - {yearTo} </div>
        );

    } else if (calendarMode === 'month') {
        increaseFunc = () => changeYear(parseInt(year, 10) + 1);
        decreaseFunc = () => changeYear(parseInt(year, 10) - 1);
        headerText = (
            <div>
                {momentDate.format('YYYY')}
            </div>
        );
    } else {
        increaseFunc = increaseMonth;
        decreaseFunc = decreaseMonth;
        headerText = (
            <>
                <div>{momentDate.locale('ru').format('MMMM')}</div>
                <div>{momentDate.format('YYYY')}</div>
            </>
        );
    }

    function getRange(startDate, endDate, type) {
        let fromDate = moment(startDate)
        let toDate = moment(endDate)
        let diff = toDate.diff(fromDate, type)
        return diff;
    }


    return (
        <div className='nlk-datepicker__header'>
            <div>
                <Button variant="secondary" onClick={decreaseFunc} size={32} customClass="nlk-datepicker__header-button">
                    <Icon icon="Arrow/Back" width={12} height={11} color={black} />
                </Button>
            </div>
            <div className="nlk-datepicker__header-links" onClick={increaseCalendarMode}>
                {headerText}
            </div>
            <div>
                <Button variant="secondary" onClick={increaseFunc} size={32} customClass="nlk-datepicker__header-button">
                    <Icon icon="Arrow/Forward" width={12} height={11} color={black} />
                </Button>
            </div>
        </div>
    )
}

const RenderField = (props) => {
    const {
        input,
        meta,
        id,
        label,
        disabled,
        info,
        labelBalloonContent,
        infoBalloonContent,
        minDate,
        maxDate,
        withPortal,
        onChangeCallback,
    } = props;
    const { error, touched } = meta;
    const [calendarIsShow, setCalendarIsShow] = useState(false);
    const [calendarMode, setCalendarMode] = useState(null);

    const wrapper = useRef(null);
    const dateValue = input.value && moment(input.value, 'DD.MM.YYYY').isValid() ? moment(input.value, 'DD.MM.YYYY').toDate() : null

    const toggleCalendar = () => {
        setCalendarIsShow(prev => !prev);
    }

    const hideCalendar = () => {
        setCalendarIsShow(false);
    }

    const onSelect = (date) => {
        if (
            dateValue &&
            date &&
            moment(dateValue).isSame(moment(date), 'days')
        ) {
            decreaseCalendarMode();
        }
    }

    const handleCalendarChange = (value) => {
        const dateString = moment(value).format('DD.MM.YYYY');
        if (!calendarMode) {
            input.onChange(dateString);
        }
        decreaseCalendarMode();
    }

    const increaseCalendarMode = () => {
        if (!calendarMode) {
            setCalendarMode('month');
        } else if (calendarMode === 'month') {
            setCalendarMode('year');
        }
    }

    const decreaseCalendarMode = () => {
        if (calendarMode === 'year') {
            setCalendarMode('month');
        } else if (calendarMode === 'month') {
            setCalendarMode(null);
        } else {
            hideCalendar();
        }
    }

    useEffect(() => {
        const handleClickOutside = event => {
            if ((wrapper.current && !wrapper.current.contains(event.target))
                || (event.target && event.target.className === 'react-datepicker__portal' || event.target.className === 'nlk-datepicker__input-wrapper')) {
                hideCalendar();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapper]);

    useEffect(() => {
        if (onChangeCallback instanceof Function) {
            onChangeCallback(input.value);
        }
    }, [input.value]);

    return (
        <div className='nlk-datepicker__wrapper' ref={wrapper}>
            <div className={`nlk-datepicker ${disabled ? 'nlk-datepicker_disabled' : ''} ${touched && error ? 'nlk-datepicker_error' : ''}`}
            >
                <label
                    htmlFor={id}
                    className="nlk-datepicker__label"
                >
                    {label} {labelBalloonContent ? <BalloonHelp content={labelBalloonContent} /> : null}
                </label>
                {info ? <div className="nlk-datepicker__info">{info} {infoBalloonContent ? <BalloonHelp content={infoBalloonContent} /> : null}</div> : null}
                {touched && error
                    ? <FieldError error={error} />
                    : null
                }
                <div className="nlk-datepicker__input-wrapper">
                    <InputMask
                        {...input}
                        id={id}
                        className="nlk-datepicker__input"
                        disabled={disabled}
                        mask={'99.99.9999'}
                        maskChar={null}
                    />
                    <Button variant={'text'}
                        customClass={`nlk-datepicker__endIcon ${disabled ? 'nlk-datepicker__endIcon-disabled' : ''}`}
                        startIcon={<Icon icon="Calendar/Calendar" color={!disabled ? gray60 : gray40} />}
                        onClick={!disabled ? toggleCalendar : undefined}

                    />



                </div>
            </div>
            {calendarIsShow &&
                //${withPortal? 'nlk-datepicker__calendar-withPortal': ''}
                <div className={`nlk-datepicker__calendar`}>
                    <DatePicker
                        selected={dateValue}
                        inline
                        onChange={handleCalendarChange}
                        dropdownMode="select"
                        renderCustomHeader={renderHeaderDecorator({ increaseCalendarMode, calendarMode, minDate, maxDate })}
                        locale={ru}
                        onSelect={onSelect}
                        openToDate={dateValue}
                        showMonthYearPicker={calendarMode === 'month'}
                        showYearPicker={calendarMode === 'year'}
                        showFullMonthYearPicker={calendarMode === 'month'}
                        yearItemNumber={20}
                        minDate={minDate || undefined}
                        maxDate={maxDate || undefined}
                        peekNextMonth
                        withPortal={withPortal}
                    />
                </div>
            }
        </div>
    )
}


const Datepicker = (props) => {
    const {
        id,
        name,
        label,
        onFocus,
        onBlur,
        validate,
        disabled,
        info,
        labelBalloonContent,
        infoBalloonContent,
        minDate,
        maxDate,
        withPortal,
        onChangeCallback,
    } = props;

    const handlerFocus = e => {
        if (onFocus instanceof Function) {
            onFocus(e);
        }
    }

    const handleBlur = e => {
        if (onBlur instanceof Function) {
            onBlur(e);
        }
    }
    return (
        <Field
            component={RenderField}
            id={id}
            name={name}
            label={label}
            onFocus={handlerFocus}
            onBlur={handleBlur}
            validate={[validateDate, ...validate]}
            disabled={disabled}
            info={info}
            labelBalloonContent={labelBalloonContent}
            infoBalloonContent={infoBalloonContent}
            minDate={minDate}
            maxDate={maxDate}
            withPortal={withPortal}
            onChangeCallback={onChangeCallback}

        />
    );
};


Datepicker.defaultProps = {
    onFocus: undefined,
    onBlur: undefined,
    validate: [],
    disabled: false,
    info: null,
    labelBalloonContent: null,
    withPortal: false,

};

Datepicker.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    labelBalloonContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    validate: PropTypes.arrayOf(PropTypes.func), // TODO не удалось найти правильный тип для этого свойства, но вроде и так неплохо
    disabled: PropTypes.bool,
    info: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    infoBalloonContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    onChangeCallback: PropTypes.func,

    onFocus: PropTypes.func,
    onBlur: PropTypes.func,

    withPortal: PropTypes.bool,
};

export default Datepicker;
