import React from 'react';
import {blue60} from "../../vars";

const Calendar = ({ width = 18, height = 17, color = blue60, className }) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2 1.99998C2 3.10455 2.89543 3.99998 4 3.99998C5.05436 3.99998 5.91817 3.18411 5.99451 2.14925L6 1.99998H12C12 3.10455 12.8954 3.99998 14 3.99998C15.0544 3.99998 15.9182 3.18411 15.9945 2.14925L16 1.99998H17C17.5523 1.99998 18 2.4477 18 2.99998V16C18 16.5523 17.5523 17 17 17H1C0.447715 17 0 16.5523 0 16V2.99998C0 2.4477 0.447715 1.99998 1 1.99998H2ZM16 5.49998H2C1.75454 5.49998 1.55039 5.67686 1.50806 5.91011L1.5 5.99998V14.6C1.5 14.8454 1.67688 15.0496 1.91012 15.0919L2 15.1H16C16.2455 15.1 16.4496 14.9231 16.4919 14.6899L16.5 14.6V5.99998C16.5 5.75452 16.3231 5.55038 16.0899 5.50804L16 5.49998ZM7 7.99998C7.55228 7.99998 8 8.4477 8 8.99998V9.99998C8 10.5523 7.55228 11 7 11H5C4.44772 11 4 10.5523 4 9.99998V8.99998C4 8.4477 4.44772 7.99998 5 7.99998H7ZM4 -1.52588e-05C4.55228 -1.52588e-05 5 0.4477 5 0.999985V1.99998C5 2.55227 4.55228 2.99998 4 2.99998C3.44772 2.99998 3 2.55227 3 1.99998V0.999985C3 0.4477 3.44772 -1.52588e-05 4 -1.52588e-05ZM14 -1.52588e-05C14.5523 -1.52588e-05 15 0.4477 15 0.999985V1.99998C15 2.55227 14.5523 2.99998 14 2.99998C13.4477 2.99998 13 2.55227 13 1.99998V0.999985C13 0.4477 13.4477 -1.52588e-05 14 -1.52588e-05Z" fill={color} />
        </svg>
    );
};

export default Calendar;