import React, { Component, useState, useEffect } from 'react';

// import { connect } from 'react-redux';
// import { loginUser } from "../../../actions/AuthUserActions";
// import { Button, Alert } from 'react-bootstrap';
// import '../../../css/Application.scss';
import './mainPege.scss'
import logo from '../../img/logo.svg'
import LoginForm from '../../form/LoginForm';
import RegisterForm from '../../form/RegisterForm';
import ForgotForm from '../../form/ForgotForm';
import ButtonsBlock from "../../common/buttonsBlock/ButtonsBlock";
import Button from "../../common/button/Button";
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

const MainPage = props => {
    const cookies = new Cookies();
    const navigate = useNavigate();
    const [form, setForm] = useState('login');
    const changeForm = (formName) => {
        setForm(formName)
    }

    useEffect(() => {
        if (cookies.get('token')) {
            navigate('/profile')
            // window.location.href='https://ag-platform.ru/profile'
        }
    }, [])

    return <>
        <div id='main-page'>
            <img src={logo} alt="AG.Platform" className='logo-half' />
            <div className='form-block'>
                <div className='form-block__border'>
                    {form == 'login' &&
                        <LoginForm navigate={navigate} />
                    }
                    {form == 'registration' &&
                        <RegisterForm />
                    }
                    {form == 'forgot' &&
                        <ForgotForm />
                    }
                    <div className='change-form-links'>
                        {form == 'login' &&
                            <>
                                <ButtonsBlock justify={true} customMarginTop={5}>
                                    <Button
                                        size={40}
                                        variant="text"
                                        onClick={() => { changeForm('registration') }}
                                    >
                                        Регистрация
                                    </Button>
                                    <Button
                                        size={40}
                                        variant="text"
                                        onClick={() => { changeForm('forgot') }}
                                    >
                                        Забыл пароль
                                    </Button>
                                </ButtonsBlock>
                            </>
                        }
                        {form == 'registration' &&
                            <>
                                <ButtonsBlock justify={true} customMarginTop={5}>
                                    <Button
                                        size={40}
                                        variant="text"
                                        onClick={() => { changeForm('login') }}
                                    >
                                        Войти
                                    </Button>
                                    <Button
                                        size={40}
                                        variant="text"
                                        onClick={() => { changeForm('forgot') }}
                                    >
                                        Забыл пароль
                                    </Button>
                                </ButtonsBlock>
                            </>
                        }
                        {form == 'forgot' &&
                            <>
                                <ButtonsBlock justify={true} customMarginTop={5}>
                                    <Button
                                        size={40}
                                        variant="text"
                                        onClick={() => { changeForm('registration') }}
                                    >
                                        Регистрация
                                    </Button>
                                    <Button
                                        size={40}
                                        variant="text"
                                        onClick={() => { changeForm('login') }}
                                    >
                                        Войти
                                    </Button>
                                </ButtonsBlock>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default MainPage