import React from 'react';
import {blue60} from "../../vars";

const ArrowBack = ({width = '15', height = '14', color=blue60, className}) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.77505 0.227834C8.075 0.53159 8.07498 1.02405 7.775 1.32778L2.91343 6.25H14.25C14.6642 6.25 15 6.58579 15 7C15 7.41421 14.6642 7.75 14.25 7.75H2.91343L7.775 12.6722C8.07498 12.9759 8.075 13.4684 7.77505 13.7722C7.4751 14.0759 6.98876 14.0759 6.68877 13.7722L0 7L6.68877 0.227778C6.98876 -0.0759478 7.4751 -0.0759228 7.77505 0.227834Z" fill={color} />
        </svg>
    );
};

export default ArrowBack;