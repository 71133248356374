import React, { useState, useEffect, useContext } from 'react';
import BalloonHelp from '../../../common/balloonHelp/BalloonHelp';
import Checkbox from '../../../common/checkbox/Checkbox';
import { connect, useDispatch } from 'react-redux';
import { formValueSelector, reduxForm, initialize } from "redux-form";
import TableBlock from '../../../common/tableBlock/TableBlock';
import { userProfile } from "../../../store/actions/ProfileActions";
import Link from "../../../common/link/Link.jsx";
import Context from '../../../utils/context';
import * as types from "../../../store/actions/types"

import "../settingsPage.scss"

const FORM_NAME = 'NOTIFICATIONS_SETTINGS_TABLE';

const NotificationsSettingsTable = props => {
    const { formValues } = props
    const context = useContext(Context)
    const dispatch = useDispatch()
    const [profile, setProfile] = useState({})
    const [tgDisabled, setTgDisabled] = useState(false)

    useEffect(() => {
        setProfile(context.profile)
    }, [context.profile])
    useEffect(() => {
        if (profile?.settings?.notifications) {
            let formValues = {}
            Object.entries(profile?.settings?.notifications).forEach(([group, types]) => {
                Object.entries(types).forEach(([type, value]) => {
                    formValues[`${group}-${type}`] = value
                })
            })
            dispatch(initialize(FORM_NAME, formValues))
        }
        if (!profile?.info?.tg) {
            setTgDisabled(true)
        } else {
            setTgDisabled(false)
        }
    }, [profile])

    const group = {
        'new_application': 'Новая заявка',
        'new_order': 'Новый заказ',
    }
    const items = {
        'email': 'Email',
        'tg': 'Телеграмм',
    }

    const handleChange = (value, meta, group, key) => {
        const { dirty } = meta;
        if (dirty) {
            let request = {
                notifications: {
                    group,
                    key,
                    value
                }
            }
            context.profile.settings.notifications[group][key] = value
            dispatch(userProfile('PUT', request, '/upd_profile_settings', [types.USER_PROFILE_UPDATE_REQUEST, types.USER_PROFILE_UPDATE_SUCCESS, types.USER_PROFILE_UPDATE_FAILURE]))
        }
    };
    return <>
        <form className={FORM_NAME}>
            <TableBlock>
                <table className='settings-table manu-table'>
                    <thead>
                        <tr>
                            <th></th>
                            {items && Object.entries(items).map(([key, name]) => {
                                return <>
                                    <th>
                                        <div>
                                            {name}
                                            {key == 'tg' && tgDisabled &&
                                                <BalloonHelp position={'bottom-right'} content={<>Вам нужно привязать ваш Telegram.<br /><Link to="/profile" routerLink={true}>Привязать</Link></>} type={'primary'} />
                                            }
                                        </div>
                                    </th>
                                </>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(group).map(([groupKey, groupName]) => {
                            return <>
                                <tr>
                                    <td>
                                        <b>{groupName}</b>
                                    </td>
                                    {items && Object.entries(items).map(([key, name]) => {
                                        return <>
                                            <td>
                                                <div>
                                                    <Checkbox
                                                        key={`${groupKey}-${key}`}
                                                        name={`${groupKey}-${key}`}
                                                        id={`${groupKey}-${key}`}
                                                        onChangeCallback={(value, meta) => { handleChange(value, meta, groupKey, key) }}
                                                        checked={formValues[`${groupKey}-${key}`]}
                                                        disabled={key == 'tg' && tgDisabled}
                                                    // onChangeCallback={(check, meta) => { selectedAll(check, meta, key) }}
                                                    />
                                                </div>
                                            </td>
                                        </>
                                    })}
                                </tr>
                            </>
                        })}
                    </tbody>
                </table>
            </TableBlock>
        </form>
    </>
}
const selector = formValueSelector(FORM_NAME);

const mapStateToProps = store => {
    // const pf = store.userState.size > 0 ? store.userState.get("profile") : null;    
    return {
        formValues: {
            'new_application-email': selector(store, 'email') || false,
            'new_application-tg': selector(store, 'tg') || false,
            'new_order-email': selector(store, 'email') || false,
            'new_order-tg': selector(store, 'tg') || false,
        }
    }
}

const RowDetailForm = reduxForm({
    form: FORM_NAME
})(NotificationsSettingsTable);

export default connect(mapStateToProps)(RowDetailForm);;