import { CHECK_AUTH, AUTH_SUCCESS, AUTH_FAILURE, API } from './types';
// import {MAKE_REQUEST_TO_API_SERVER} from "../middleware/Api";
import apiMiddleware from "../../middleware/index";
import Cookies from 'universal-cookie';
import moment from 'moment';
const cookies = new Cookies();
// import {Schemas} from "../middleware/schemas";
// import {deepFind} from "../Helpers/Common";


/**
 *
 * @param request
 */
const checkAuth = (request) => (dispatch, getState) => {
    dispatch({ type: CHECK_AUTH })
    apiMiddleware('GET', null, '/verify_token', [CHECK_AUTH, AUTH_SUCCESS, AUTH_FAILURE])
        .then(data => {
            dispatch(data)
        })
        .catch(err => {
            cookies.set('token', null, { path: '/', expires: moment().add(-12, 'hours').toDate() })
            dispatch(err)
        })
};

export default checkAuth