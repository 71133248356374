import React from 'react';
import {blue60} from "../../vars";

const NotificationInformation = ({width = '16', height = '16', className = '', color = blue60}) => {
    return (
        <svg className={className}  width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8 16C3.5888 16 0 12.4112 0 8C0 3.5888 3.5888 0 8 0C12.4112 0 16 3.5888 16 8C16 12.4112 12.4112 16 8 16ZM7 4.50313C7 4.3652 7.02556 4.23459 7.07669 4.11129C7.12781 3.98798 7.19836 3.8814 7.28834 3.79154C7.37832 3.70167 7.48364 3.63062 7.60429 3.57837C7.72495 3.52612 7.85685 3.5 8 3.5C8.13906 3.5 8.26994 3.52612 8.39264 3.57837C8.51534 3.63062 8.62168 3.70167 8.71166 3.79154C8.80164 3.8814 8.87219 3.98798 8.92331 4.11129C8.97444 4.23459 9 4.3652 9 4.50313C9 4.64525 8.97444 4.77691 8.92331 4.89812C8.87219 5.01933 8.80164 5.12487 8.71166 5.21473C8.62168 5.3046 8.51534 5.37461 8.39264 5.42476C8.26994 5.47492 8.13906 5.5 8 5.5C7.85685 5.5 7.72495 5.47492 7.60429 5.42476C7.48364 5.37461 7.37832 5.3046 7.28834 5.21473C7.19836 5.12487 7.12781 5.01933 7.07669 4.89812C7.02556 4.77691 7 4.64525 7 4.50313ZM7.25 7.25C7.25 6.83579 7.58579 6.5 8 6.5C8.41421 6.5 8.75 6.83579 8.75 7.25V11.75C8.75 12.1642 8.41421 12.5 8 12.5C7.58579 12.5 7.25 12.1642 7.25 11.75V7.25Z" fill={color}/>
        </svg>
    );
};

export default NotificationInformation;