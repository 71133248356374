import React, { Component, useState, useEffect } from 'react';
import * as type from "../../store/actions/types"
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm, initialize, change } from 'redux-form';
// import { loginUser } from "../../../actions/AuthUserActions";
// import { Button, Alert } from 'react-bootstrap';
// import '../../../css/Application.scss';
// import './page404Page.scss'
import * as types from "../../store/actions/types"
import logo from '../../img/logo.svg'
import Link from "../../common/link/Link";
import { deepFind } from '../../../backend/models/helpers';
import Template from '../../components/Template';
import Grid from '../../common/grid/Grid';
import GridBlock from '../../common/gridBlock/GridBlock';
import Heading from '../../common/heading/Heading'
import Button from '../../common/button/Button'
import FormBlock from "../../common/formBlock/FormBlock.jsx";
import TableBlock from '../../common/tableBlock/TableBlock'
import TableControll from '../../common/tableControll/TableControll'
import Icon from '../../common/icon/Icon'
import Checkbox from "../../common/checkbox/Checkbox"
import { error60, corpGreen } from '../../common/vars'
import { hideModal, showModal } from "../../store/actions/ModalActions";
import * as modals from "../../store/actions/ModalConstants";
import { generalRequest } from "../../store/actions/GeneralActions";
import { useNavigate } from 'react-router-dom';
import Helpers from '../../common/helpers'
import "./projectsPage.scss"
import { useRef } from 'react';
import { useSelector } from 'react-redux';

const FORM_NAME = 'PROJECTS_TABLE'

const ProjectsPage = props => {
    
    const windowWidth = useSelector(store => store.windowWidth)
    const isMobile = windowWidth <= 768;
    const navigate = useNavigate();
    const createProjectData = (pr) => {
        return Helpers.objectChunk(pr, 10)
    }

    const { profile } = props
    const [deleteID, setDeleteID] = useState([])
    const [projects, setProjects] = useState(createProjectData(profile.projects) || [])
    const [selectedProjects, setSelectedProjects] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const totalPage = useRef(projects.length || 1)

    const deleteProject = (id) => {
        setDeleteID([...deleteID, id])
        props.dispatch(
            showModal(modals.MODAL_TYPE_DELETE_POPUP, {
                title: 'Удалить филиал',
                confirmButton: 'Удалить',
                cancelButton: 'Отменить',
                content: "Все данные филиала будут удалены безвозвратно. Вы уверены, что хотите удалить филиал?",
                confirmAction: types.PROJECT_DELETE,
                cancelAction: types.PROJECT_DELETE_CANCEL,
                hideOnConfirm: true,
                hideOnCancel: true,
                payload: {}
            })
        );
    }
    const deleteSelectedProject = () => {
        if (selectedProjects.length > 0) {
            setDeleteID(selectedProjects)
            props.dispatch(
                showModal(modals.MODAL_TYPE_DELETE_POPUP, {
                    title: 'Удалить филиалы',
                    confirmButton: 'Удалить',
                    cancelButton: 'Отменить',
                    content: "Все данные филиалов будут удалены безвозвратно. Вы уверены, что хотите удалить филиалы?",
                    confirmAction: types.PROJECT_DELETE,
                    cancelAction: types.PROJECT_DELETE_CANCEL,
                    hideOnConfirm: true,
                    hideOnCancel: true,
                    payload: {}
                })
            );
        } else {
            props.dispatch(showModal(modals.MODAL_NOTIFICATION, {
                title: 'Ошибка',
                children: 'Вам нужно выбрать хотя бы один филиал',
                variant: 'error',
                buttonTitle: 'Продолжить',
            }));
        }
    }

    useEffect(() => {
        // let values = {}
        projects[currentPage] && projects[currentPage].map(project => {
            const [key] = Object.entries(project)[0]
            let value = 0
            if (selectedProjects.includes(key)) {
                value = 1
            }
            props.dispatch(change(FORM_NAME, `SELECT_${key}`, value))
        })
        if (selectedProjects.length == 0) {
            props.dispatch(change(FORM_NAME, `SELECT_ALL`, false))
        }
        // initialize(values)
    }, [selectedProjects])

    useEffect(() => {
        // let values = {}
        setSelectedProjects([])
    }, [currentPage])

    useEffect(() => {
        if (deepFind(props, 'generalRequestState.action') == types.PROJECT_DELETE) {
            const request = {
                id: deleteID,
            }
            props.generalRequest('DELETE', request, '/del_project', [types.DELETE_PROJECT_REQUEST, types.DELETE_PROJECT_SUCCESS, types.DELETE_PROJECT_FAILURE])
        } else if (deepFind(props, 'generalRequestState.action') == types.DELETE_PROJECT_SUCCESS) {
            setDeleteID([])
            setSelectedProjects([])
            setTimeout(() => {
                let updProjects = { ...profile.projects }
                deleteID.map(id => {
                    delete updProjects[id]
                })
                setProjects(createProjectData(updProjects))
            }, 200)

        } else if (deepFind(props, 'generalRequestState.action') == types.DELETE_PROJECT_FAILURE) {
            props.dispatch(showModal(modals.MODAL_NOTIFICATION, {
                title: deepFind(props, 'generalRequestState.errorMessage') || 'Ошибка сервера',
                variant: 'error',
                buttonTitle: 'Продолжить',
            }));
        }
    }, [deepFind(props, 'generalRequestState.action')]);


    const customCaption = () => {
        return <div className='row-center-justify'>
            <h1>Мои филиалы</h1>
            <Link to='/profile/projects/new' routerLink={true} isUnderlined={false} customClass="row-center button-add">
                <Icon icon="Plus" color={corpGreen} width={16} height={18} /> Добавить
            </Link>
            {/* <Button
                customClass={'delete-button'}
                variant="text"
                startIcon={<Icon icon="Plus" color={corpGreen} width={16} height={18} />}
                onClick={onDeleteButtonClick}
                size={40}
            >
                Добавить
            </Button> */}
        </div>
    }
    const createProject = () => {
        navigate('/profile/projects/new');
    }
    const paginationPageChange = (page) => {
        setCurrentPage(page)
    }
    const settingsBlock = <>
        <ul className='options'>
            <li className='option'>
                <Button
                    onClick={() => {
                        deleteSelectedProject()
                    }}
                    fullWidth={true}
                    textLeft={true}
                    customClass="option__button"
                    variant="text"
                    size={40}
                    disabled={selectedProjects.length == 0}
                >
                    Удалить выбранные
                </Button>
            </li>
        </ul>
    </>

    const changeSelectProject = (status, pID) => {
        if (status) {
            let projectList = [...selectedProjects, pID]
            projectList = projectList.reduce((result, item) => {
                return result.includes(item) ? result : [...result, item];
            }, []);
            setSelectedProjects(projectList)
        } else {
            let pr = [...selectedProjects],
                index = pr.indexOf(pID)
            pr.splice(index, 1);
            setSelectedProjects(pr)
        }
    }
    const selectedAll = (status) => {
        if (status) {
            let pr = []
            projects[currentPage].map(project => {
                const [key, value] = Object.entries(project)[0]
                pr.push(key)
            })
            pr = pr.reduce((result, item) => {
                return result.includes(item) ? result : [...result, item];
            }, []);
            setSelectedProjects(pr)
        } else {
            setSelectedProjects([])
        }
    }

    return <>
        <Template
            caption={'Мои филиалы'}
            customClass="work_area__grid"
            // customCaption={customCaption()}
            colorVariant={'gray-white'}
        >
            <TableControll
                widthAddButton={true}
                addButtonClick={createProject}
                withPagination={true}
                paginationPageCount={projects.length || 1}
                paginationPageNow={currentPage}
                paginationPageChange={paginationPageChange}
                withSettings={true}
                settingsBlock={settingsBlock}
                withSelectedInfo={true}
                countSelectedItems={selectedProjects.length}
                countAllItems={projects[currentPage] && projects[currentPage].length}
            />
            <TableBlock>
                <table className='projects-table'>
                    {projects && projects.length > 0 ?
                        <>
                            <thead>
                                <tr>
                                    <th>
                                        <Checkbox
                                            name={'SELECT_ALL'}
                                            id={'SELECT_ALL'}
                                            onChangeCallback={(status) => { selectedAll(status) }}
                                        />
                                    </th>
                                    <th className='name'>Название</th>
                                    {!isMobile &&
                                        <th className='address'>Адрес</th>
                                    }
                                    <th className='td-btn'></th>
                                    <th className='td-btn'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {projects[currentPage] && projects[currentPage].map(project => {
                                    const [key, value] = Object.entries(project)[0]
                                    return (
                                        <>
                                            <tr>
                                                <td>
                                                    <Checkbox
                                                        name={`SELECT_${key}`}
                                                        id={`SELECT_${key}`}
                                                        onChangeCallback={(status) => { changeSelectProject(status, key) }}
                                                    />
                                                </td>
                                                <td className='name'>{value.name}</td>
                                                {!isMobile &&
                                                    <td className='address'>{deepFind(value, 'address.address_label') || deepFind(value, 'address.hand_address')}</td>
                                                }
                                                <td className='td-btn'>
                                                    <Link to={`/profile/projects/${key}`} routerLink={true}>
                                                        <Icon icon="Edit" width={20} height={20} color={corpGreen} />
                                                    </Link>
                                                </td>
                                                <td className='td-btn'>
                                                    <Button type="submit" variant="ghost" onClick={() => { deleteProject(key) }}>
                                                        <Icon icon="Delete" width={20} height={20} color={error60} />
                                                    </Button>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })}
                            </tbody>
                        </> :
                        <>
                            <tbody>
                                <tr>
                                    <td align='center'>
                                        У вас еще нет филиалов.
                                    </td>
                                </tr>
                            </tbody>
                        </>
                    }
                </table>
            </TableBlock>
        </Template>
    </>
}
const mapStateToProps = store => {
    return {
        profile: deepFind(store.userState, 'data'),
        generalRequestState: store.generalRequestState,
    }
}
const mapDispatchToProps = {
    generalRequest,
};

const RowDetailForm = reduxForm({
    form: FORM_NAME
})(ProjectsPage);

export default connect(mapStateToProps, mapDispatchToProps)(RowDetailForm);
// export default ProfilePage