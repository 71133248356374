import * as types from "../actions/types";
import { deepFind } from "../../Helpers/Common";

const INITIAL_STATE = {
    notification: {},
    initialized: false,
};

const notificationPushReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.NOTIFICATION_PUSH_HIDE:
            return {
                ...state,
                action: action.type,
                notification: {}
            };
        case types.NOTIFICATION_PUSH_SHOW:
            return {
                ...state,
                action: action.type,
                notification: action.payload
            };
            break;
        default:
            return state
    }
};

export default notificationPushReducer;
