import React from 'react';
import {blue60} from "../../vars.js";

const ArrowUp = ({width = '14', height = '8', color = blue60, className}) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.6722 7.775C12.9759 8.07498 13.4684 8.075 13.7722 7.77505C14.0759 7.4751 14.0759 6.98876 13.7722 6.68877L7 0L0.227777 6.68877C-0.0759478 6.98876 -0.075923 7.4751 0.227834 7.77505C0.53159 8.075 1.02405 8.07498 1.32778 7.775L7 2.17267L12.6722 7.775Z" fill={color}/>
        </svg>
    );
};

export default ArrowUp;