import * as types from "../actions/types";

const INITIAL_STATE = { failure: false, errorMessage: "", action: false, data: [] };

const regUserReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.REG_REQUEST:
            return {
                ...state,
                failure: false,
                errorMessage: "",
                action: action.type,
                data: action.payload
            };

        case types.REG_SUCCESS:
            return {
                ...state,
                failure: false,
                errorMessage: '',
                action: action.type,
                data: action.payload
            };

        case types.REG_FAILURE:
            let errorMessage = ''
            if (action.error) {
                if (action.error.response.status == 500) {
                    errorMessage = 'Ошибка сервера'
                } else {
                    if (action.error.response && action.error.response.data && action.error.response.data.message) {
                        errorMessage = action.error.response.data.message
                    } else {
                        errorMessage = action.error.message
                    }
                }
            }
            return {
                ...state,
                failure: true,
                errorMessage: errorMessage,
                action: action.type,
                data: action.payload
            };

        default:
            return state;
    }
};

export default regUserReducer;
