import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as types from "../store/actions/types"
import Context from '../utils/context';

const WsClient = () => {
    const context = useContext(Context)
    const dispatch = useDispatch()

    useEffect(() => {
        if (Object.keys(context.profile).length > 0) {
            let userID = context.profile['_id']
            const wssUrl = process.env.NODE_ENV !== 'production' ? `ws://localhost:9310?id=${userID}` : `wss://ag-platform.ru/ws_main?id=${userID}`
            const socket = new WebSocket(wssUrl); // замените на ваш URL WebSocket    

            socket.onopen = () => {
                console.log('WebSocket connection established.');
            };

            // Обработчик события получения сообщения от сервера
            socket.onmessage = (event) => {
                let obj = {}
                try {
                    obj = JSON.parse(event.data)
                } catch (err) { }

                switch (obj.action) {
                    case 'profile_reload':
                        dispatch({ type: types.PROFILE_RELOAD })
                        break
                }
            };

            // Обработчик события закрытия соединения
            socket.onclose = () => {
                console.log('WebSocket connection closed.');
            };

            // При необходимости можно отправить сообщение на сервер
            // socket.send('Hello, server!');

            // Закрываем соединение WebSocket при размонтировании компонента
            return () => {
                // socket.close();
            };
        }
    }, [context.profile]);

    // useEffect(() => {
    //     if (ownerState.action == types.GET_BRANCH_SUCCESS) {
    //         // Создаем новый экземпляр WebSocket
    //         socket.onopen = () => {
    //             console.log('WebSocket connection established.');
    //         };

    //         // Обработчик события получения сообщения от сервера
    //         socket.onmessage = (event) => {
    //             console.log(222222222222);
    //             console.log(222222222222);
    //             console.log(222222222222);
    //             console.log(222222222222);
    //             console.log(event.data);
    //             console.log('Received message:', event.data);
    //         };

    //         // Обработчик события закрытия соединения
    //         socket.onclose = () => {
    //             console.log('WebSocket connection closed.');
    //         };

    //         // При необходимости можно отправить сообщение на сервер
    //         // socket.send('Hello, server!');

    //         // Закрываем соединение WebSocket при размонтировании компонента
    //         return () => {
    //             console.log(77777777)
    //             console.log(77777777)
    //             console.log(77777777)
    //             // socket.close();
    //         };
    //     }
    // }, [ownerState.action]);

    return '';
};

export default WsClient;