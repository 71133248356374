import React from 'react';

import "./GridBlock.scss";

const GridBlock = (props) => {
    const {
        children,

    } = props;

    return (
        <div
            className={`grid-block`}
        >
            {children}
        </div>
    );
};

export default GridBlock;