import React from 'react';
import {gray60} from "../../vars.js";

const Update = ({width = 20, height = 20, color = gray60, className = ''}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.5918 3.38741C11.9029 3.19996 12.3077 3.32058 12.4655 3.6477C12.6038 3.93445 12.501 4.27933 12.2284 4.44365L10.9693 5.20238C12.0212 5.38936 13 5.87435 13.8008 6.6058C14.9291 7.63645 15.6206 9.07995 15.7031 10.6534C15.8718 13.8726 13.4536 16.6161 10.302 16.7813C7.15037 16.9465 4.4587 14.4707 4.28999 11.2516C4.27124 10.8939 4.53992 10.5891 4.8901 10.5707C5.24029 10.5523 5.53936 10.8274 5.55811 11.1851C5.68932 13.6889 7.78285 15.6145 10.2341 15.486C12.6854 15.3576 14.5662 13.2237 14.4349 10.7199C14.3708 9.49506 13.8338 8.37405 12.9554 7.57172C12.2508 6.92815 11.371 6.53033 10.431 6.43516L11.2148 7.97837C11.3589 8.26221 11.2633 8.60913 10.9941 8.77903C10.687 8.97285 10.2797 8.8606 10.1153 8.5368L8.755 5.8585C8.67237 5.69581 8.66852 5.5124 8.73098 5.35347C8.76223 5.18559 8.86269 5.03209 9.01897 4.93791L11.5918 3.38741Z" fill={color}/>
        </svg>

    );
};

export default Update;