import React from 'react';
import {blue60} from "../../vars";

const Dash = ({width, height, color= blue60, className}) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.5 0H9.5C9.77614 0 10 0.223858 10 0.5V1.5C10 1.77614 9.77614 2 9.5 2H0.5C0.223858 2 0 1.77614 0 1.5V0.5C0 0.223858 0.223858 0 0.5 0Z" fill={color}/>
        </svg>
    );
};

export default Dash;