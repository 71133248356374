import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import regUserReduce from "../reducers/RegUserReducer";
import loginUserReduce from "../reducers/LoginUserReducer";
import forgotUserReduce from "../reducers/ForgotUserReduce";
import modalReducer from "../reducers/ModalReducer";
import authUserReducer from "../reducers/AuthUserReducer";
import profileReducer from "../reducers/ProfileReducer";
import preloaderReducer from "../reducers/PreloaderReduser";
import generalReducer from "../reducers/GeneralReducer";
import notificationPushReducer from "../reducers/NotificationPushReducer";
import metaReducer from "../reducers/MetaReducer";
import wsReducer from "./WSReducer";
// import trusteeReducer from "./TrusteeReducer";

// import gibddFinesReducer from "./GibddFinesReducer";
// import widgetReducer from "./WidgetReducer";
// import widgetItemReducer from "./WidgetItemReducer";
// import { widgetSettingsReducer } from "./WidgetSettingsReducer";
// import { guisReducer, guisPostReducer } from "../components/Widgets/Dashboard/Zkhu/components/GuisReducer";
// import { mosenergoReducer, mosenergoPostReducer, mosenergoPaymentReducer } from "../components/Widgets/Dashboard/Zkhu/components/MosenergoReducer";
// import ISPP from "../components/Widgets/Dashboard/Ispp";
// import AllchargesReducer from "./AllchargesReducer";
// import widgetNavReducer from "./WidgetNavReducer";
// import widgetFlatReducer from "./WidgetFlatReducer";
// import modalReducer from "./ModalReducer";
// import epdReducer from "../components/Widgets/Dashboard/Zkhu/components/EPDReducer";
// import {
//     subscriptionItemsReducer,
//     SubscriptionItemsProfileReducer,
//     SubscriptionItemsProfileBufferReducer
// } from "./SubscriptionItemsReducer";
// import desktopReducer from "./DesktopReducer";
// import { mgtsReducer, mgtsPaymentReducer } from "../components/Widgets/Dashboard/Zkhu/components/MGTSReducer";
// import { ezhdContractReducer, ezhdReducer, ezhdHomeworkReducer, ezhdScheduleReducer } from "./EzhdReducer";
// import flatReducer from "./FlatReducer";
// import { SubscriptionReducer, SubscriptionRecReducer, SubscriptionReceiptReducer, SubscriptionReducerECUINFO, SubscriptionReducerRecoshowed, SubscriptionReducerRecocanceled } from "./SubscriptionReducer";
// import nonresidentialReducer from "../components/Widgets/Main/Settings/Nonresidential/reducer";
// import landReducer from "../components/Widgets/Main/Settings/Land/reducer";
// import workplaceReducer from "../components/Widgets/Main/Settings/Workplace/reducer";
// import petReducer from "../components/Widgets/Main/Settings/Pets/reducer";
// import troykaReducer from "../components/Widgets/Main/Settings/Troyka/reducer";
// import gibddReducer from "./GibddReducer";
// import carReducer from "./CarReducer";
// import documentsReducer from "./DocumentsReducer";
// import familyReducer from "./FamilyReducer";
// import educationReducer from "./EducationReducer";
// import { empReducer, empSaveReducer } from './EMPReducer';

// import bigDataReducer from './bigData/bigDataReducer';
// import bigDataSaveReducer from './bigData/bigdataSaveReducer';

// import RecoReducer from "./RecoReducer";
// import { NotificationReducer, NotificationSaveReducer } from './notifications/NotificationsReducer';
// import esiaReducer from "./EsiaReducer";
// import userMetaReducer from "./UserMetaReducer";
// import { ajaxreducers } from '../actions/AjaxAction';
// import { pfrReducer } from "../components/Widgets/Dashboard/Pfr/redux/reducer";
// import { advertisingSmallNewsReducer } from '../reducers/AdvertisingSmallNewsReducer';
// import { advertisingNewsReducer } from './AdvertisingReducer';
// import { sportsClubsReducer } from 'frontend/components/Widgets/Dashboard/SportsClubs/redux/reducer';
// import CDP from './../components/Widgets/CDP'
// import statusesReducer from '../components/Widgets/Main/StatusBlock/reducer.js';
// import chedReducer from '../components/Common/Form/Ched/reducer';
// import ISPK from '../components/Decorators/ISPK'
// import VerificationStatus from '../components/Decorators/VerificationStatus'
// import { olympiadAppIdReducer, giaAppIdReducer, olympiadDataReducer, giaDataReducer } from "../components/Widgets/Dashboard/Education/components/reducer";
// import ActiveCitizen from "../components/Widgets/Dashboard/ActiveCitizen";
// import Zkhu from '../components/Widgets/Dashboard/Zkhu';
// import { favouritesReducer } from 'frontend/components/Widgets/Dashboard/Favourites/redux/reducer';
// import Car from "../components/Widgets/Dashboard/Car";
// import { crowdsourcingReducer } from 'frontend/components/Widgets/Dashboard/Crowdsourcing/redux/reducer';
// import EventReducer from '../modules/Events/reducer'
// import metaReducer from "reducers/MetaReducer";
// import legalizationReducer from "components/Widgets/Main/Settings/Legalization/redux/reducer";
// import profileStatusReducer from "./ProfileStatusReducer.js";
// import userDeleteValidationReducer from "./userDeleteValidation.js";
// import updateProfilePopupReducer from 'frontend/modules/Events/Popups/updateProfilePopupReducer.js';
// import {AddressReducer} from "components/Common/Form/Address/api/redux/reducer";
// import degReducer from '../components/Widgets/Dashboard/Deg/redux/reducer';
// import profileLoadingReducer from "reducers/ProfileLoadingReducer";


// const baseAjaxReducers = ajaxreducers(['saveOms', 'getHealthData', 'cancelHealthRec', 'getFavorites']);

/**
 *
 * @type {Reducer<any>}
 */
let listOfReducers = {
    form: formReducer,
    regUserState: regUserReduce,
    modalState: modalReducer,
    loginUserState: loginUserReduce,
    forgotUserState: forgotUserReduce,
    authState: authUserReducer,
    userState: profileReducer,
    preloaderState: preloaderReducer,
    generalRequestState: generalReducer,
    notificationPushState: notificationPushReducer,
    metaState: metaReducer,
    wsState: wsReducer,
    // profileLoadingState:profileLoadingReducer,

    // userTrustee: trusteeReducer,
    // gibddFines: gibddFinesReducer,
    // gibddEvacuation: Car.reducer,
    // widgetState: widgetReducer,
    // widgetItemsState: widgetItemReducer,
    // widgetSettingsState: widgetSettingsReducer,
    // guisState: Zkhu.reducers.GuisReducer.guisReducer,
    // guisPostState: Zkhu.reducers.GuisReducer.guisPostReducer,
    // mosenergoState: Zkhu.reducers.MosenergoReducer.mosenergoReducer,
    // mosenergoPostState: Zkhu.reducers.MosenergoReducer.mosenergoPostReducer,
    // mosenergoPaymentState: Zkhu.reducers.MosenergoReducer.mosenergoPaymentReducer,
    // isppState: ISPP.reducer,
    // allchargesState: AllchargesReducer,
    // widgetNavState: widgetNavReducer,
    // widgetFlatState: widgetFlatReducer,
    // epdState: Zkhu.reducers.EPDReducer,
    // subscriptionItemsState: subscriptionItemsReducer,
    // subscriptionItemsProfileState: SubscriptionItemsProfileReducer,
    // SubscriptionItemsProfileBufferState : SubscriptionItemsProfileBufferReducer,
    // desktopState: desktopReducer,
    // mgtsState: Zkhu.reducers.MGTSReducer.mgtsReducer,
    // mgtsPaymentState: Zkhu.reducers.MGTSReducer.mgtsPaymentReducer,
    // form: formReducer,
    // ezhdState: ezhdReducer,
    // ezhdContractState: ezhdContractReducer,
    // ezhdScheduleState: ezhdScheduleReducer,
    // ezhdHomeworkState: ezhdHomeworkReducer,
    // flatState: flatReducer,
    // subscriptionState: SubscriptionReducer,
    // subscriptionReceiptState: SubscriptionReceiptReducer,
    // subRecommendationState: SubscriptionRecReducer,
    // gibddState: gibddReducer,
    // carState: carReducer,
    // documentsState: documentsReducer,
    // familyState: familyReducer,
    // educationState: educationReducer,
    // empState: empReducer,
    // empSaveState: empSaveReducer,
    // bigDataState: bigDataReducer,
    // bigDataSaveState: bigDataSaveReducer,
    // recoState: RecoReducer,
    // notificationsState: NotificationReducer,
    // notifySaveState: NotificationSaveReducer,
    // esiaState: esiaReducer,
    // nonresidentialState: nonresidentialReducer,
    // landState: landReducer,
    // workplaceState: workplaceReducer,
    // petState: petReducer,
    // troykaState: troykaReducer,
    // subscriptionStateECUINFO: SubscriptionReducerECUINFO,
    // advertisingSmallNewsState: advertisingSmallNewsReducer,
    // advertisingNewsState: advertisingNewsReducer,
    // subscriptionStateRecoshowed: SubscriptionReducerRecoshowed,
    // userMetaReducer,
    // subscriptionStateRecocanceledState: SubscriptionReducerRecocanceled,
    // sportsClubsState: sportsClubsReducer,
    // CDPState: CDP.reducer.CDPData,
    // CDPQueueState: CDP.reducer.CDPCards,
    // statusesState: statusesReducer,
    // chedState: chedReducer,
    // ispkState: ISPK.reducer,
    // verificationState: VerificationStatus.reducer,
    // olympiadAppIdState: olympiadAppIdReducer,
    // giaAppIdState: giaAppIdReducer,
    // olympiadDataState: olympiadDataReducer,
    // giaDataState: giaDataReducer,
    // activeCitizenState: ActiveCitizen.reducer,
    // pfrState: pfrReducer,
    // favouritesState: favouritesReducer,
    // crowdsourcingState: crowdsourcingReducer,
    // eventState: EventReducer,
    // metaState: metaReducer,
    // legalizationState: legalizationReducer,
    // profileStatusState: profileStatusReducer,
    // userDeleteValidationState: userDeleteValidationReducer,
    // updateProfilePopupState: updateProfilePopupReducer,
    // addressesState: AddressReducer,
    // degState: degReducer,
};

// for (let name in baseAjaxReducers) {
//     listOfReducers[name] = baseAjaxReducers[name];
// }

const reducers = combineReducers(listOfReducers);

export default reducers;
