import React from 'react';
import {blue60} from "../../vars";

const MiniInfo = ({ width = 2, height = 9, className, color = blue60 }) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 2 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.0766871 0.611285C0.0255621 0.734588 0 0.865203 0 1.00313C0 1.14525 0.0255621 1.27691 0.0766871 1.39812C0.127812 1.51933 0.198364 1.62487 0.288344 1.71473C0.378324 1.8046 0.483639 1.87461 0.604294 1.92476C0.724949 1.97492 0.85685 2 1 2C1.13906 2 1.26994 1.97492 1.39264 1.92476C1.51534 1.87461 1.62168 1.8046 1.71166 1.71473C1.80164 1.62487 1.87219 1.51933 1.92331 1.39812C1.97444 1.27691 2 1.14525 2 1.00313C2 0.865203 1.97444 0.734588 1.92331 0.611285C1.87219 0.487983 1.80164 0.381401 1.71166 0.291536C1.62168 0.201671 1.51534 0.130617 1.39264 0.0783699C1.26994 0.026123 1.13906 0 1 0C0.85685 0 0.724949 0.026123 0.604294 0.0783699C0.483639 0.130617 0.378324 0.201671 0.288344 0.291536C0.198364 0.381401 0.127812 0.487983 0.0766871 0.611285ZM1 3C0.585786 3 0.25 3.33579 0.25 3.75V8.25C0.25 8.66421 0.585786 9 1 9C1.41421 9 1.75 8.66421 1.75 8.25V3.75C1.75 3.33579 1.41421 3 1 3Z" fill={color}/>
        </svg>
    );
};

export default MiniInfo;