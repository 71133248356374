export const  gray90 = '#0E0E0F';
export const  gray80 = '#3C3E41';
export const  gray60 = '#696C71';
export const  gray40 = '#B2B5BB';
export const  gray30 = '#C4C8D0';
export const  gray20 = '#DFE1E6';
export const  gray10 = '#EEEFF2';
export const  gray05 = '#F3F5F7';
export const  blue80 = '#006aba';
export const  blue70 = '#0091ff';
export const  blue60 = '#45afff';
export const  blue30 = '#8FB4FF';
export const  blue20 = '#BBCFFF';
export const  blue10 = '#DDE7FF';
export const  blue05 = '#EBF1FF';
export const  brand = '#CC2222';
export const  blackDark = '#000000';
export const  black = '#221f20';
export const  white = '#ffffff';
export const  beige05 = '#F7F5EE';
export const  beige80 = '#5B422C';
export const  beige60 = '#C5AD8D';
export const  beige25 = '#E1D0B9';
export const  green = '#6cbba4';
export const  corpGreen = '#4ABEA3';
export const  green25 = '#CFDE64';
export const  green05 = '#F2FDA5';
export const  red25 = '#FFABAB';
export const  red05 = '#FFD8D8';
export const  blue25 ='#8AC0FF';
export const  success80 = '#003311';
export const  success70 = '#006633';
export const  success60 = '#008844';
export const  success30 = '#7CDE8D';
export const  success20 = '#97EDAC';
export const  success10 = '#AFF8C7';
export const  success05 = '#E6FAF0';
export const  warning80 = '#CC6600';
export const  warning70 = '#EE8800';
export const  warning60 = '#FFAA00';
export const  warning30 = '#FFDE77';
export const  warning20 = '#FFE8AA';
export const  warning10 = '#FFF1CC';
export const  error80 ='#660000';
export const  error70 = '#880000';
export const  error60 ='#AA0000';
export const  error30 = '#FFA28F';
export const  error20 = '#FFC3BB';
export const  error10 = '#FFDEDD';
export const  error05 = '##FFF2F2';
export const  focus = '#6688DD';
export const  body = '#CED0D2';
//icons
export const iconzky = '#ED9821';
export const iconSchool = '#65C261';
export const iconCityIdea = '#4D9CDA';
export const iconHealth = '#2DAB9C';
export const iconTransport = '#7A44CA';
export const iconCharge = '#4A78D4';
export const iconActiveCitizen = '#007A6B';
export const iconSportSchool = '#E95C30';


export const xs = 575;
export const sm = 576;
export const md = 768;
export const lg = 992;
export const xl = 1200;
export const xxl = 1400;
