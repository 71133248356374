import React from 'react';
import {iconzky} from "../../vars.js";

const Mgts = ({width = 24, height = 24, className = "", color = iconzky}) => {
    return (
        <svg width={width} height={height} className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.23178 15.7219C3.08202 15.9016 3 16.1281 3 16.3621V19.5C3 20.3284 3.67157 21 4.5 21H19.5C20.3284 21 21 20.3284 21 19.5V16.3621C21 16.1281 20.918 15.9016 20.7682 15.7219L16.2318 10.2781C16.082 10.0984 16 9.87189 16 9.63795V9.5C16 9.22386 15.7761 9 15.5 9H14.5C14.2239 9 14 9.22386 14 9.5V10H10V9.5C10 9.22386 9.77614 9 9.5 9H8.5C8.22386 9 8 9.22386 8 9.5V9.63795C8 9.87189 7.91798 10.0984 7.76822 10.2781L3.23178 15.7219ZM12 12C10.3431 12 9 13.3431 9 15C9 16.6569 10.3431 18 12 18C13.6569 18 15 16.6569 15 15C15 13.3431 13.6569 12 12 12Z" fill={color}/>
            <path d="M23.0465 10.7354C22.9286 8.25695 21.5525 3 11.9995 3C2.44656 3 1.07049 8.25694 0.952575 10.7354C0.917728 11.4679 1.537 12 2.27029 12H4.99953C5.55182 12 5.99953 11.5523 5.99953 11V10C5.99953 6.68629 8.49907 5 11.9995 5C15.5 5 17.9995 6.68629 17.9995 10V11C17.9995 11.5523 18.4472 12 18.9995 12H21.7288C22.4621 12 23.0813 11.4679 23.0465 10.7354Z" fill={color}/>
        </svg>
    );
};

export default Mgts;