import React, { useState } from 'react';

import './Clipboard.scss';
import PropTypes from 'prop-types';
import Icon from "../icon/Icon";
import BalloonHelp from "../balloonHelp/BalloonHelp";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch } from "react-redux";
import * as types from "../../store/actions/types"
import { showNotificationPush } from '../../store/actions/NotificationPushAction';

const Clipboard = ({ text, disabled, label, labelBalloonContent }) => {
    const dispatch = useDispatch();
    const copy = () => {
        dispatch(showNotificationPush({
            content: 'Ссылка скопированна!'
        }))
        // console.log(1111111)
        // console.log(1111111)
        // console.log(1111111)
        // console.log(1111111)
        // console.log(text)

    }
    return <>

        <div
            className={`nlk-textfield ${disabled ? 'nlk-textfield_disabled' : ''}`}
        >
            <label
                className={`nlk-textfield__label`}
            >
                {label} {labelBalloonContent ? <BalloonHelp content={labelBalloonContent} /> : null}
            </label>
            <CopyToClipboard text={text} onCopy={() => copy()}>
                <div className='clipboard-field'>
                    <div className='icon'>
                        <Icon icon="Copy" width="24" color={'#fff'}
                            height="24" />
                    </div>
                    <input
                        className="nlk-textfield__input"
                        disabled={disabled}
                        type="text "
                        readOnly="true"
                        value={text}
                    />
                </div>
            </CopyToClipboard>
        </div>
    </>
};

export default Clipboard;


Clipboard.propTypes = {
    text: PropTypes.string,
    labelBalloonContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

Clipboard.defaultProps = {
    customClass: '',
    label: '',
    labelBalloonContent: '',
    disabled: false
};
