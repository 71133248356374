import * as ACTION_TYPES from '../actions/types'
import Cookies from 'universal-cookie';
import moment from 'moment';
const cookies = new Cookies();

export const initialState = {
    isAuthenticated: false
}

export const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        // case ACTION_TYPES.LOGIN_SUCCESS:
        //     return {
        //         ...state,
        //         is_authenticated: true
        //     }
        // case ACTION_TYPES.LOGIN_FAILURE:
        //     return {
        //         ...state,
        //         is_authenticated: false
        //     }
        // case ACTION_TYPES.ADD_PROFILE:
        //     return {
        //         ...state,
        //         profile: action.payload
        //     }
        // case ACTION_TYPES.REMOVE_PROFILE:
        //     return {
        //         ...state,
        //         profile: null
        //     }
        case ACTION_TYPES.CHECK_AUTH:
            console.log(444444)
            console.log(444444)
            console.log(444444)
            console.log(444444)
            console.log(444444)
            return {
                ...state,
                isFetching: true,
                isAuthenticated: false,
                token: false,
                errorMessage: ""
            };
        case ACTION_TYPES.AUTH_SUCCESS:
            return {
                ...state,
                isFetching: true,
                isAuthenticated: true,
                token: false,
                errorMessage: ""
            };
        case ACTION_TYPES.CHECK_AUTH:
            cookies.set('token', null, { path: '/', expires: moment().add(-12, 'hours').toDate() })
            return {
                ...state,
                isFetching: true,
                isAuthenticated: false,
                token: false,
                errorMessage: ""
            };
        default:
            return state
    }
}