import React, { useState, useEffect, useMemo } from 'react';
import Autosuggest from 'react-autosuggest';
import { debounce } from 'lodash'
import BalloonHelp from "../balloonHelp/BalloonHelp";
import PropTypes from "prop-types";
import FieldError from "../fiedlError/FieldError";
import "./AutosuggestField.scss"
import Loader from "../loader/Loader";
import { green } from "../vars.js";
const AutosuggestField = (props) => {
    const {
        id,
        label,
        labelBalloonContent,
        info,
        placeholder,
        isError,
        error,
        customError,
        suggestions,
        isFetching,
        defaultValue,
    } = props

    const [value, setValue] = useState(defaultValue);
    const [firstLoad, setFirstLoad] = useState(true);

    const [changes] = useState(() => {
        return debounce((value) => {
            props.getSuggestions(value)
        }, 1000);
    });
    useEffect(() => {
        if (defaultValue && firstLoad) {
            setFirstLoad(false)
        } else {
            if (value.length > 0) {
                changes(value)
            }
        }
    }, [value])

    const onSuggestionsFetchRequested = (suggest) => {

        // setValue(value)
    };
    const onSuggestionsClearRequested = () => {

        // setSuggestions([])
    };
    const getSuggestionValue = suggestion => suggestion.value
    const renderSuggestion = suggestion => (
        suggestion.value
    );
    const onChange = (event, { newValue }) => {
        setValue(newValue)

    };
    const inputProps = {
        placeholder: placeholder,
        value,
        onChange: onChange
    };
    return (
        <div className='autosuggest-field'>
            <label
                htmlFor={id}
                className={`nlk-textfield__label ${info ? 'nlk-textfield__label-margin-4' : 'nlk-textfield__label-margin-8'}`}
            >
                {label} {labelBalloonContent ? <BalloonHelp content={labelBalloonContent} /> : null}
                {/* {isValid ? <span className="nlk-textfield__isValid"><Icon icon="Checkbox/Check" color={blue60} width={10} height={8} /></span> : null} */}
            </label>
            {isError
                ? <FieldError error={error || customError} />
                : null
            }
            <div className={`autosuggest-field__content ${isFetching ? 'disabled' : ''}`}>
                {isFetching ? <Loader className="nlk-button__loader" color={green} height={18} width={18} /> : null}
                <Autosuggest
                    id={id}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    onSuggestionSelected={props.onSuggestionSelected}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                />
            </div>
        </div>

    );
};
AutosuggestField.defaultProps = {
    info: null,
    labelBalloonContent: null,
    placeholder: 'Начните вводить текст ...',
    isError: false,
    isFetching: false,
    suggestions: [],
    defaultValue: ''
};

AutosuggestField.propTypes = {
    id: PropTypes.string.isRequired,
    labelBalloonContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    info: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    isError: PropTypes.bool,
    isFetching: PropTypes.bool,
    suggestions: PropTypes.array,
};

export default AutosuggestField;