import * as types from "../actions/types";
import { deepFind } from '../../../backend/models/helpers';
import Cookies from 'universal-cookie';
import moment from 'moment';
const cookies = new Cookies();

const profileReducer = (state = {}, action) => {
    switch (action.type) {
        case types.USER_SETTINGS_DELETE:
        case types.USER_PROFILE_REQUEST:
        case types.USER_PROFILE_UPDATE_REQUEST:
        case types.USER_TG_LINK_REQUEST:
            return {
                ...state,
                failure: false,
                errorMessage: "",
                action: action.type,
                data: action.payload
            };
            break;
        case types.USER_PROFILE_FAILURE:
            cookies.set('token', null, { path: '/', expires: moment().add(-12, 'hours').toDate() })
            return {
                ...state,
                failure: true,
                action: types.USER_PROFILE_FAILURE,
                errorMessage: action.errorMessage,
                data: []
            };
            break;
        case types.USER_PROFILE_UPDATE_FAILURE:
        case types.USER_PROFILE_DELETE_SETTINGS_FAILURE:
        case types.USER_TG_LINK_FAILURE:
            return {
                ...state,
                failure: true,
                action: types.USER_PROFILE_UPDATE_FAILURE,
                errorMessage: deepFind(action,'error.errorMessage'),
                data: []
            };
            break;
        case types.USER_TG_LINK_SUCCESS:
        case types.USER_PROFILE_SUCCESS:
        case types.USER_PROFILE_UPDATE_SUCCESS:
        case types.USER_PROFILE_DELETE_SETTINGS_SUCCESS:
            return {
                ...state,
                failure: false,
                errorMessage: "",
                action: action.type,
                data: action.payload
            };
        // let statelessProfile = {};
        // // for (let i = 0; i < deepFind(action, 'data.response.length'); i++) {
        // //     if (action.data.response[i].code == "RELATIVES") {
        // //         if (!Array.isArray(action.data.response[i].data)) {
        // //             action.data.response[i].data = [action.data.response[i].data];
        // //         }
        // //         action.data.response[i].data = action.data.response[i].data.filter(value => value != null);
        // //     }
        // //     statelessProfile[action.data.response[i].code] = action.data.response[i];
        // // }
        // state.delete('profile');
        // return state.set("profile", statelessProfile);

        case types.USER_PROFILE_RELOAD:
            return Map();
    }
    return state;
};

export default profileReducer;
