import React from 'react';
import {blue60} from "../../vars";

const Download = ({ width = 18, height = 18, className = '', color = blue60 }) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.1639 0C13.3703 0 15.9915 2.58848 16.1089 5.81593L16.1129 6.05838C17.2488 6.54648 18 7.68727 18 8.94661C18 10.6754 16.615 12.0822 14.9107 12.0822H12.3957C12.009 12.0822 11.696 11.7643 11.696 11.3735C11.696 10.9826 12.009 10.6648 12.3957 10.6648H14.9107C15.8419 10.6648 16.6006 9.89425 16.6006 8.94661C16.6006 8.183 16.1005 7.5134 15.3928 7.30028L15.1552 7.23784C14.8543 7.13093 14.6589 6.82764 14.6918 6.49867C14.7064 6.35146 14.7137 6.19858 14.7137 6.0411C14.7137 3.49102 12.6723 1.4174 10.1639 1.4174C8.14407 1.4174 6.34523 2.79669 5.79037 4.77214C5.73889 4.95441 5.61766 5.10871 5.45446 5.19938C5.2898 5.2915 5.09573 5.31148 4.91397 5.25567C4.61332 5.16327 4.36462 5.12095 4.1268 5.12095C2.62383 5.12095 1.3994 6.3645 1.3994 7.89287C1.3994 9.42175 2.62341 10.6648 4.1268 10.6648H6.04417C6.43143 10.6648 6.74387 10.9822 6.74387 11.3735C6.74387 11.7647 6.43143 12.0822 6.04417 12.0822H4.1268C1.85037 12.0822 0 10.2029 0 7.89287C0 5.58281 1.85037 3.70355 4.1268 3.70355C4.30129 3.70355 4.47902 3.71758 4.66265 3.74581L4.7581 3.52331C5.72106 1.39733 7.84029 0 10.1639 0ZM10.1096 10.2329C10.1096 9.75625 9.7232 9.36986 9.24658 9.36986C8.76995 9.36986 8.38356 9.75625 8.38356 10.2329L8.38335 15.2747L7.25503 14.2027C6.92692 13.891 6.41375 13.8857 6.07927 14.1906L6.02055 14.2468L5.94665 14.337C5.72606 14.6508 5.76265 15.0872 6.04908 15.3593L8.57638 17.7602C8.75846 17.9332 9.00332 18.0163 9.24872 17.9941C9.47205 18.0161 9.71645 17.933 9.89832 17.7602L12.4256 15.3593L12.4652 15.3189C12.758 14.9977 12.7349 14.5 12.4137 14.2073L12.3 14.1151C11.9692 13.8892 11.5179 13.9193 11.2197 14.2027L10.1094 15.2562L10.1096 10.2329Z" fill={color} />
        </svg>
    );
};

export default Download;