import React, {Component} from 'react';
import {hideModal} from '../../../store/actions/ModalActions';


export class BaseModalComponent extends Component {

    constructor(props) {
        super(props);
        this.onClose = this.onClose.bind(this);
    }

    sync(gen) {
        var iterable, resume;

        resume = function(err, retVal) {
            if (err) iterable.raise(err);
            iterable.next(retVal);
        };

        iterable = gen(resume);
        iterable.next();
    }

    onClose() {
        typeof(this.props.onCloseCallback) === 'function' && this.props.onCloseCallback();

        if(this.props.onHide instanceof Function) {
            this.props.onHide();
        } else {
            this.props.dispatch(
                hideModal()
            );
        }
    }
}
