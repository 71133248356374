import React from 'react';
import {blue60} from "../../vars";

const ArrowForward = ({ width = 15, height = 14, color = blue60, className }) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.22495 0.227834C6.925 0.53159 6.92502 1.02405 7.225 1.32778L12.0866 6.25H0.75C0.335786 6.25 0 6.58579 0 7C0 7.41421 0.335787 7.75 0.75 7.75H12.0866L7.225 12.6722C6.92502 12.9759 6.925 13.4684 7.22495 13.7722C7.5249 14.0759 8.01124 14.0759 8.31123 13.7722L15 7L8.31123 0.227778C8.01124 -0.0759478 7.5249 -0.0759228 7.22495 0.227834Z" fill={color}/>
        </svg>
    );
};

export default ArrowForward;