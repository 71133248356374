import React from 'react';
import {gray60} from "../../vars.js";

const ArrowRight = ({width = 24, height = 24, color = gray60, className}) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.68734 7.27317C9.43735 7.02006 9.43733 6.60968 9.68729 6.35655C9.93726 6.10342 10.3425 6.1034 10.5925 6.3565L15.4725 11.2973C15.8572 11.6868 15.8572 12.3132 15.4725 12.7027L10.5925 17.6435C10.3425 17.8966 9.93726 17.8966 9.68729 17.6435C9.43733 17.3904 9.43735 16.98 9.68734 16.7269L14.3559 12L9.68734 7.27317Z" fill={color}/>
        </svg>


    );
};

export default ArrowRight;