import React from 'react';
import {blue60} from "../../vars";

const FavoriteFull = ({ width = 12, height = 16, className = '', color = blue60 }) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1V15.191C12 15.5627 11.6088 15.8044 11.2764 15.6382L6.22361 13.1118C6.08284 13.0414 5.91716 13.0414 5.77639 13.1118L0.723607 15.6382C0.391156 15.8044 0 15.5627 0 15.191V1Z" fill={color} />
        </svg>

    );
};

export default FavoriteFull;