import * as types from './types';
import apiMiddleware from "../../middleware/index";


export const generalRequest = (method, request, url, typesList, hidePreloader = false) => (dispatch, getState) => {
    dispatch({ type: typesList[0] })
    if (!hidePreloader) {
        dispatch({ type: types.PRELOADER_SHOW })
    }    
    apiMiddleware(method, request, url, typesList)
        .then(data => {
            dispatch(data)
        })
        .catch(err => {
            dispatch(err)
        })
        .finally(() => {
            if (!hidePreloader) {
                setTimeout(() => {
                    dispatch({ type: types.PRELOADER_HIDE })
                }, 100)
            }
        })
    // const user = getState().authState;

    // if (user.isAuthenticated && user.token) {
    //     dispatch(fetchProfile(noCacheRequest(request), user.token));
    // }
};