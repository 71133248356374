import React, { Component, useState } from 'react';
// import { Link } from 'react-router-dom';
// import { connect } from 'react-redux';
// import { loginUser } from "../../../actions/AuthUserActions";
// import { Button, Alert } from 'react-bootstrap';
// import '../../../css/Application.scss';
import './page404Page.scss'
import logo from '../../img/logo.svg'
import Link from "../../common/link/Link";

const Page404 = props => {
    return <>
        <div id='page404'>
            <div className='page-block'>
                <img src={logo} alt="AG.Platform" className='logo-half' />
                <h1>Ошибка 404</h1>
                <p>Данная страница не найдена</p>
                <Link to='/' routerLink={true}>Вернуться на главную</Link>
            </div>

        </div>
    </>
}
export default Page404