import React from 'react';
import {blue60} from "../../vars";

const Sort = ({width = 16, height = 10, color = blue60, className = ''}) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 1C0 0.447715 0.447715 0 1 0H15C15.5523 0 16 0.447715 16 1C16 1.55228 15.5523 2 15 2H1C0.447715 2 0 1.55228 0 1ZM3 5C3 4.44772 3.44772 4 4 4H12C12.5523 4 13 4.44772 13 5C13 5.55228 12.5523 6 12 6H4C3.44772 6 3 5.55228 3 5ZM6 8C5.44772 8 5 8.44771 5 9C5 9.55229 5.44772 10 6 10H10C10.5523 10 11 9.55229 11 9C11 8.44771 10.5523 8 10 8H6Z" fill={color}/>
        </svg>
    );
};

export default Sort;