import React from 'react';
import {blue60} from "../../vars";

const PersonalData = ({width = 18, height = 18, color = blue60, className = ''}) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5 0C3.89543 0 3 0.895431 3 2V14C3 15.1046 3.89543 16 5 16H13C14.1046 16 15 15.1046 15 14V2C15 0.895431 14.1046 0 13 0H5ZM9 4C9 2.89543 8.10457 2 7 2C5.89543 2 5 2.89543 5 4C5 5.10457 5.89543 6 7 6C8.10457 6 9 5.10457 9 4ZM6 11C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9H12C12.5523 9 13 9.44771 13 10C13 10.5523 12.5523 11 12 11H6ZM5 12.5C5 12.7761 5.22386 13 5.5 13H10.5C10.7761 13 11 12.7761 11 12.5C11 12.2239 10.7761 12 10.5 12H5.5C5.22386 12 5 12.2239 5 12.5ZM16 2H17C17.5523 2 18 2.44772 18 3V13C18 13.5523 17.5523 14 17 14H16V2ZM1 2H2V14H1C0.447715 14 0 13.5523 0 13V3C0 2.44772 0.447715 2 1 2Z" fill={color}/>
        </svg>
    );
};

export default PersonalData;