import React, { useMemo } from 'react';
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm, getFormAsyncErrors, getFormSubmitErrors, getFormSyncErrors, getFormValues, touch, formValueSelector } from "redux-form";
import FormBlock from "../common/formBlock/FormBlock";
import TextField from "../common/textfield/TextField";
import ButtonsBlock from "../common/buttonsBlock/ButtonsBlock";
import Button from "../common/button/Button";
import Icon from "../common/icon/Icon";
import { black, blue60, gray60 } from "../common/vars.js";
import { FormUtilsMask, FormUtilsValidator, } from "../utils/FormUtils.js";
import Config, { serverUrl } from "../../backend/config/index";
import saveRegUser from "../store/actions/RegUserActions"
import { deepFind } from '../../backend/models/helpers';
import * as types from "../store/actions/types";
import * as modals from "../store/actions/ModalConstants";
import { hideModal, showModal } from "../store/actions/ModalActions";
// const axios = require('axios');
const FORM_NAME = 'REGISTERFORM';

class RegisterForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            saveInProgress: false,
        }
    }
    componentDidUpdate(prevProps) {
        if (this.state.saveInProgress) {
            if (deepFind(this.props, 'regUserState.action') == types.REG_SUCCESS) {
                this.setState({
                    saveInProgress: false
                });
                this.props.dispatch(showModal(modals.MODAL_NOTIFICATION, {
                    title: 'Вы зарегистрировались!',
                    variant: 'success',
                    children: 'На вашу почту отправлено письмо. Для окончания регистрации перейдите по ссылке из письма',
                    buttonTitle: 'Продолжить',
                }));

                const { reset, clearSubmit, clearFields } = this.props;
                reset();
                clearSubmit();
                clearFields();
                // this.props.dispatch(reset(FORM_NAME));

            } else if (deepFind(this.props, 'regUserState.action') == types.REG_FAILURE) {
                this.setState({
                    saveInProgress: false
                });
                this.props.dispatch(showModal(modals.MODAL_NOTIFICATION, {
                    title: deepFind(this.props, 'regUserState.errorMessage') || 'Ошибка сервера',
                    variant: 'error',
                    buttonTitle: 'Продолжить',
                }));
            }
        }
        // if (this.props.objectId !== prevProps.objectId) {
        //     props.change('objectId', this.props.objectId);
        // }
    }
    render() {
        // const minLength = (length) => FormUtilsValidator.minLength
        const minLength = () => FormUtilsValidator.minLength(6)
        // const handleSubmit = (e) => {
        //     e.preventDefault();
        //     // axios({
        //     //     method: "post",
        //     //     url: Config.serverUrl + '/registration',
        //     //     data: {
        //     //         email: this.props.formValues.LOGIN,
        //     //         password: this.props.formValues.PASSWORD,
        //     //     },
        //     //     headers: {
        //     //         'Content-Type': 'application/json',
        //     //         // 'Accept': 'application/json;charset=UTF-8'
        //     //     }
        //     // })
        //     //     .then((response) => {
        //     //         console.log(response)
        //     //     })
        //     //     .catch(err => {

        //     //     })
        //     // handleSubmit(e)
        //     // this.isSaving = true;
        //     // setTimeout(() => {
        //     //     this.isSaving = false;
        //     // }, 1000);
        // };
        const handleFormSubmit = values => {
            const request = {
                email: values.LOGIN,
                password: values.PASSWORD,
            }
            
            this.setState({
                saveInProgress: true
            });
            this.props.dispatch(saveRegUser(request));
        }
        return (
            <form onSubmit={this.props.handleSubmit(handleFormSubmit)} className={FORM_NAME}>
                <h3 className="form-caption">Регистрация</h3>
                <FormBlock>
                    <TextField
                        id={'LOGIN'}
                        name={'LOGIN'}
                        label={'Email'}
                        size={'fullWidth'}
                        validate={[FormUtilsValidator.required, FormUtilsValidator.email]}
                    />
                    <TextField
                        id={'PASSWORD'}
                        name={'PASSWORD'}
                        type={'password'}
                        label={'Пароль'}
                        maxLength={32}
                        mask={'********************************'}
                        validate={[FormUtilsValidator.required, minLength()]}
                        size={'fullWidth'}
                    />
                    <TextField
                        id={'PASSWORD_REPEATE'}
                        name={'PASSWORD_REPEATE'}
                        type={'password'}
                        label={'Повторите пароль'}
                        maxLength={32}
                        mask={'********************************'}
                        validate={[FormUtilsValidator.required, FormUtilsValidator.passwordSame]}
                        size={'fullWidth'}
                    />
                    <ButtonsBlock>
                        <Button
                            size={40}
                            variant="primary"
                            type={'submit'}
                            // onClick={handleMobileFilterButtonClick}
                            fullWidth={true}
                            disabled={this.state.saveInProgress}
                        >
                            ЗАРЕГИСТРИРОВАТЬСЯ
                        </Button>
                    </ButtonsBlock>
                </FormBlock>
            </form>
        )
    }
}
const RowDetailForm = reduxForm({
    form: FORM_NAME
})(RegisterForm);

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (store) => {
    return {
        action: deepFind(store, 'regUserState.action'),
        regUserState: store.regUserState,
        isFetching: false,
        formValues: {
            LOGIN: selector(store, 'LOGIN') || '',
            PASSWORD: selector(store, 'PASSWORD') || '',
            PASSWORD_REPEATE: selector(store, 'PASSWORD_REPEATE') || '',
        }
    };
};
export default connect(mapStateToProps, null, null, { forwardRef: true })(RowDetailForm);