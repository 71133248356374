import React from 'react';

const NotificationInfoOval = ({ width = 28, height = 28, className = '' }) => {
    return (
        <svg  className={className} width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40Z" fill="#DDE7FF"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M20.0004 35.7147C28.6792 35.7147 35.7147 28.6792 35.7147 20.0004C35.7147 11.3217 28.6792 4.28613 20.0004 4.28613C11.3217 4.28613 4.28613 11.3217 4.28613 20.0004C4.28613 28.6792 11.3217 35.7147 20.0004 35.7147Z" fill="#BBCFFF"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.9996 34.2853C27.8894 34.2853 34.2853 27.8894 34.2853 19.9996C34.2853 12.1098 27.8894 5.71387 19.9996 5.71387C12.1098 5.71387 5.71387 12.1098 5.71387 19.9996C5.71387 27.8894 12.1098 34.2853 19.9996 34.2853Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.9999 31.4284C13.6981 31.4284 8.57129 26.3016 8.57129 19.9999C8.57129 13.6981 13.6981 8.57129 19.9999 8.57129C26.3016 8.57129 31.4284 13.6981 31.4284 19.9999C31.4284 26.3016 26.3016 31.4284 19.9999 31.4284ZM18.5713 15.0043C18.5713 14.8073 18.6078 14.6207 18.6808 14.4446C18.7539 14.2684 18.8547 14.1161 18.9832 13.9878C19.1118 13.8594 19.2622 13.7579 19.4346 13.6832C19.6069 13.6086 19.7954 13.5713 19.9999 13.5713C20.1985 13.5713 20.3855 13.6086 20.5608 13.6832C20.7361 13.7579 20.888 13.8594 21.0165 13.9878C21.1451 14.1161 21.2458 14.2684 21.3189 14.4446C21.3919 14.6207 21.4284 14.8073 21.4284 15.0043C21.4284 15.2074 21.3919 15.3954 21.3189 15.5686C21.2458 15.7418 21.1451 15.8925 21.0165 16.0209C20.888 16.1493 20.7361 16.2493 20.5608 16.321C20.3855 16.3926 20.1985 16.4284 19.9999 16.4284C19.7954 16.4284 19.6069 16.3926 19.4346 16.321C19.2622 16.2493 19.1118 16.1493 18.9832 16.0209C18.8547 15.8925 18.7539 15.7418 18.6808 15.5686C18.6078 15.3954 18.5713 15.2074 18.5713 15.0043ZM18.9284 18.857C18.9284 18.3047 19.3761 17.857 19.9284 17.857H20.0713C20.6236 17.857 21.0713 18.3047 21.0713 18.857V25.4284C21.0713 25.9807 20.6236 26.4284 20.0713 26.4284H19.9284C19.3761 26.4284 18.9284 25.9807 18.9284 25.4284V18.857Z" fill="#0044CC"/>
        </svg>


    );
};

export default NotificationInfoOval;