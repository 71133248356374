import React from 'react';
import PropTypes from 'prop-types';

import './ButtonsBlock.scss';
import FormDataFetching from "../formDataFetching/FormDataFetching.jsx";
import FormBlock from "../formBlock/FormBlock.jsx";

const ButtonsBlock = props => {
    const {
        children,
        customMarginTop,
        formDataIsFetching,
        justify = false,
    } = props;

    const containerStyles = typeof customMarginTop === 'number' ? {
        marginTop: `${customMarginTop}px`,
    } : undefined;


    return (
        <div className={`nlk-buttonsBlock`} style={containerStyles}>
            <FormDataFetching marginBottom={16} marginTop={0} isFetching={formDataIsFetching} />
            <div className={`nlk-buttonsBlock__innerWrapper ${justify ? 'nlk-buttonsBlock__justify' : ''}`}>
                {children}
            </div>
        </div>
    );
};

ButtonsBlock.propTypes = {
    customMarginTop: PropTypes.number,
    formDataIsFetching: PropTypes.bool,
    justify: PropTypes.bool,
}



export default ButtonsBlock;