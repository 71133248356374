import * as types from "../actions/types";
import Cookies from 'universal-cookie';
import moment from 'moment';
const cookies = new Cookies();

const INITIAL_STATE = { authenticated: false };

const authUserReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.LOGIN_REQUEST:
            return {
                ...state,
                isFetching: true,
                isAuthenticated: false,
                token: false,
                errorMessage: ""
            };
        case types.LOGIN_SUCCESS:
            const token = action.payload && action.payload.token
            if (token) {
                cookies.set('token', token, { path: '/', expires: moment().add(12, 'hours').toDate() })
            }
            return {
                ...state,
                isFetching: false,
                isAuthenticated: true,
                token: token,
                errorMessage: ""
            };
        case types.LOGIN_FAILURE:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: false,
                errorMessage: action.message
            };
        case types.LOGOUT_SUCCESS:
            return {
                ...state,
                isFetching: true,
                isAuthenticated: false,
                token: false,
                errorMessage: ""
            };
        case types.CHECK_AUTH:
            return {
                ...state,
                isFetching: true,
                isAuthenticated: false,
                token: false,
                errorMessage: ""
            };
        case types.AUTH_SUCCESS:
            return {
                ...state,
                isFetching: true,
                isAuthenticated: true,
                authenticated: true,
                token: false,
                errorMessage: ""
            };
        case types.AUTH_FAILURE:
            cookies.set('token', null, { path: '/', expires: moment().add(-12, 'hours').toDate() })
            return {
                ...state,
                isFetching: true,
                isAuthenticated: false,
                token: false,
                errorMessage: ""
            };
        default:
            return state;
    }
}
    ;

export default authUserReducer;
