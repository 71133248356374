import React from 'react';
import {blue60} from "../../vars";

const FavoriteEmpty = ({ width = 10, height = 13, className = '', color = blue60 }) => {
  return (
      <svg width={width} height={height} viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M9.16667 0.166016C9.6269 0.166016 10 0.539112 10 0.999349V12.8252C10 13.0553 9.81345 13.2418 9.58333 13.2418C9.51865 13.2418 9.45485 13.2268 9.39699 13.1978L5.18634 11.0925C5.06904 11.0339 4.93096 11.0339 4.81366 11.0925L0.603006 13.1978C0.397181 13.3008 0.146901 13.2173 0.0439887 13.0115C0.0150605 12.9537 0 12.8899 0 12.8252V0.999349C0 0.539112 0.373096 0.166016 0.833333 0.166016H9.16667ZM8.75 1.41602H1.25V11.416L4.25464 9.92277C4.72386 9.68958 5.27614 9.68958 5.74536 9.92277L8.75 11.416V1.41602Z" fill={color}/>
      </svg>

  );
};

export default FavoriteEmpty;