import * as types from "../actions/types";
import { deepFind } from '../../../backend/models/helpers';

const INITIAL_STATE = { failure: false, errorMessage: "", action: false, data: [] };

const WSReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.PROFILE_RELOAD:
            console.log(99999999)
            console.log(99999999)
            console.log(99999999)
            console.log(99999999)
            console.log(99999999)
            return {
                ...state,
                failure: false,
                errorMessage: "",
                action: action.type,
                data: action.payload
            };
            break
        default:
            return state;
    }
}
export default WSReducer;