import React from 'react';
import {blue60} from "../../vars";

const ChevronForward = ({ width = 8, height = 14, color = blue60, className }) => {
  return (
    <svg className={className} width={width} height={height} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0.534204 1.95759C0.267553 1.68761 0.267531 1.24987 0.534156 0.979863C0.80078 0.709857 1.23309 0.709835 1.49974 0.979813L6.75126 6.29686C7.13596 6.68635 7.13596 7.31278 6.75126 7.70228L1.49974 13.0193C1.23309 13.2893 0.80078 13.2893 0.534156 13.0193C0.267531 12.7493 0.267553 12.3115 0.534204 12.0415L5.51405 6.99957L0.534204 1.95759Z" fill={color}/>
    </svg>
  );
};

export default ChevronForward;