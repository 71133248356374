import React from 'react';
import {success60} from "../../vars.js";

const NotificationSuccess = ({width = 16, height = 16, className = '', color = success60}) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8 16C3.5888 16 0 12.4112 0 8C0 3.5888 3.5888 0 8 0C12.4112 0 16 3.5888 16 8C16 12.4112 12.4112 16 8 16ZM11.9322 5.36241C11.6165 5.11466 11.1472 5.15483 10.8841 5.45213L7.33488 9.46042L5.4704 7.7045L5.38694 7.63665C5.0956 7.43311 4.68217 7.45572 4.41797 7.7045C4.12734 7.97815 4.12734 8.42182 4.41797 8.69547L6.85982 10.9947L6.94108 11.0611C7.25239 11.2804 7.70094 11.238 7.95774 10.9478L12.0275 6.34932L12.0869 6.27218C12.2816 5.97972 12.2216 5.58952 11.9322 5.36241Z" fill={color}/>
        </svg>
    );
};

export default NotificationSuccess;