import React from 'react';
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm, getFormAsyncErrors, getFormSubmitErrors, getFormSyncErrors, getFormValues, touch, formValueSelector, initialize } from "redux-form";
import FormBlock from "./../../../common/formBlock/FormBlock";
import TextField from "../../../common/textfield/TextField";
import Datepicker from "../../../common/datepicker/Datepicker";
import ButtonsBlock from "../../../common/buttonsBlock/ButtonsBlock";
import Button from "../../../common/button/Button";
import { FormUtilsMask, FormUtilsValidator, } from "../../../utils/FormUtils.js";
// import loginUser from "../store/actions/LoginUserActions"
import { deepFind } from '../../../../backend/models/helpers';
import moment from 'moment';
import { userProfile } from "../../../store/actions/ProfileActions";
import * as type from "../../../store/actions/types"
import * as modals from "../../../store/actions/ModalConstants";
import { hideModal, showModal } from "../../../store/actions/ModalActions";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const FORM_NAME = 'PERSONDATAFORM';

class PersonDataForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            saveInProgress: false,
        }
    }
    componentDidMount() {
        const profileInfo = deepFind(this.props.profile, 'info') || {}
        const formDataValues = {
            FIO: profileInfo.fio || '',
            BIRTHDAY: profileInfo.birthday || '',
            PASSWORD: '',
            // SURNAME: deepFind(pf, 'FIO.data.SURNAME') || '',
            // PATRONYMIC: deepFind(pf, 'FIO.data.PATRONYMIC') || '',
            // GENDER: deepFind(pf, 'PERSON.data.GENDER') || '',
            // BIRTHDATE: deepFind(pf, 'PERSON.data.BIRTHDATE') || '',
            // SNILS: deepFind(pf, 'SNILS.data.SNILS') || '',
        }
        this.props.dispatch(initialize(FORM_NAME, formDataValues));
        // initializeValues(formData.initialValues);
    }
    componentDidUpdate(prevProps) {
        if (this.state.saveInProgress) {
            if (deepFind(this.props, 'userState.action') == type.USER_PROFILE_SUCCESS) {
                this.setState({
                    saveInProgress: false
                });

                const { reset, clearSubmit, clearFields } = this.props;
                reset();
                clearSubmit();
                this.props.dispatch(initialize(FORM_NAME, prevProps.formValues));
            } else if (deepFind(this.props, 'userState.action') == type.USER_PROFILE_UPDATE_FAILURE) {
                this.setState({
                    saveInProgress: false
                });
                this.props.dispatch(showModal(modals.MODAL_NOTIFICATION, {
                    title: deepFind(this.props, 'userState.errorMessage') || 'Ошибка сервера',
                    variant: 'error',
                    buttonTitle: 'Продолжить',
                }));
            }
        }
        // if (this.props.objectId !== prevProps.objectId) {
        //     props.change('objectId', this.props.objectId);
        // }
    }
    render() {
        const handleFormSubmit = values => {
            let request = {}
            if (this.props.dirty) {
                const profileInfo = deepFind(this.props.profile, 'info') || {}
                Object.keys(values).map(key => {
                    if (values[key].length > 0 && (!profileInfo[key.toLowerCase()] || profileInfo[key.toLowerCase()] != values[key])) {
                        request[key.toLowerCase()] = values[key]
                    }
                })
                if (Object.keys(request).length > 0) {
                    this.setState({
                        saveInProgress: true
                    });
                    this.props.userProfile('PUT', request, '/upd_profile', [type.USER_PROFILE_UPDATE_REQUEST, type.USER_PROFILE_SUCCESS, type.USER_PROFILE_FAILURE])
                } else {
                    this.props.dispatch(showModal(modals.MODAL_NOTIFICATION, {
                        title: 'Нет данных на изменение',
                        variant: 'warning',
                        buttonTitle: 'Продолжить',
                    }));
                }
            }
            // this.props.dispatch(loginUser(request));
        };
        const minLength = () => FormUtilsValidator.minLength(6)
        let birthDateValidators = [FormUtilsValidator.date, FormUtilsValidator.noFutureDates, FormUtilsValidator.minYears18];
        let maxBirthdayDate = moment().subtract(-18, 'years').toDate();

        const linkTg = () => {
            this.props.dispatch(showModal(modals.MODAL_TG_LINK, {}));
        }

        return (
            <form onSubmit={this.props.handleSubmit(handleFormSubmit)} className={FORM_NAME}>
                <FormBlock>
                    <TextField
                        id={'FIO'}
                        name={'FIO'}
                        label={'ФИО'}
                        size={'fullWidth'}
                        autocomplete={false}
                        validate={[FormUtilsValidator.fio, FormUtilsValidator.maxLength107]}
                    />
                </FormBlock>
                <FormBlock>
                    <Datepicker
                        id="BIRTHDAY"
                        name="BIRTHDAY"
                        label="Дата рождения"
                        validate={birthDateValidators}
                        // onChangeCallback={value => {
                        //     if (!value || value === initialFormValues.BIRTHDATE) return;
                        //     dispatch(change(formName, 'verification', { ...formValues.verification, HAS_VERIFY_DATE: false, HAS_BIRTH_CERT_VERIFIED: false }))
                        //     dispatch(change(formName, 'BIRTH_CERT_DATE_validatorMinDate', value));
                        //     dispatch(change(formName, 'PASSPORT_RF_ISSUED_ON_validatorMinDate', dateAddNYears(value, 14)));
                        //     formChanged(component);
                        //     formFieldChanged(component, 'BIRTHDATE');
                        // }}
                        // minDate={minBirthdayDate || null}
                        maxDate={maxBirthdayDate}
                    />
                </FormBlock>
                {!!this.props.profile?.info?.tg ?
                    <FormBlock direction={'row'}>
                        Telegram ChatID:<b>{this.props.profile.info.tg}</b>
                    </FormBlock> :
                    <ButtonsBlock>
                        <Button
                            size={40}
                            variant="primary"
                            onClick={() => { linkTg() }}
                            fullWidth={false}
                            type={'submit'}
                        >
                            Привязать Telegram
                        </Button>


                    </ButtonsBlock>
                }
                {/* <FormBlock>
                    <TextField
                        id={'PASSWORD'}
                        name={'PASSWORD'}
                        type={'password'}
                        label={'Пароль'}
                        maxLength={32}
                        autocomplete={false}
                        mask={'********************************'}
                        validate={[FormUtilsValidator.required, minLength()]}
                        size={'fullWidth'}
                    />
                </FormBlock> */}
                <ButtonsBlock>
                    <Button
                        size={40}
                        variant="primary"
                        // onClick={handleMobileFilterButtonClick}
                        fullWidth={true}
                        type={'submit'}
                        disabled={!this.props.dirty || this.state.saveInProgress}
                    >
                        Изменить
                    </Button>
                </ButtonsBlock>
            </form >
        )
    }
}
const RowDetailForm = reduxForm({
    form: FORM_NAME
})(PersonDataForm);

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (store) => {
    return {
        profile: deepFind(store.userState, 'data'),
        userState: store.userState,
        // authState: store.authState,
        // loginUserState: store.loginUserState,
        formValues: {
            FIO: selector(store, 'FIO') || '',
            BIRTHDAY: selector(store, 'BIRTHDAY') || '',
        }
    };
};
// const mapDispatchToProps = (dispatch, ownProps) => {
//     return {
//         userProfile,
//         // dispatch,
//         initializeValues: (values) => {
//             dispatch(initialize(FORM_NAME, values));
//         },

//     };
// };
const mapDispatchToProps = {
    userProfile,
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(RowDetailForm);