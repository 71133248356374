import React from 'react';
import {blue60} from "../../vars";

const PlitkaPlus = ({width = 18, height = 18, color = blue60, className = ''}) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 1C0 0.447715 0.447715 0 1 0H5C5.55228 0 6 0.447715 6 1V5C6 5.55228 5.55228 6 5 6H1C0.447715 6 0 5.55228 0 5V1ZM0 9C0 8.44772 0.447715 8 1 8H5C5.55228 8 6 8.44772 6 9V13C6 13.5523 5.55228 14 5 14H1C0.447715 14 0 13.5523 0 13V9ZM11 0C10.4477 0 10 0.447715 10 1V2H9C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H10V5C10 5.55228 10.4477 6 11 6C11.5523 6 12 5.55228 12 5V4H13C13.5523 4 14 3.55228 14 3C14 2.44772 13.5523 2 13 2H12V1C12 0.447715 11.5523 0 11 0ZM9 8C8.44772 8 8 8.44772 8 9V13C8 13.5523 8.44772 14 9 14H13C13.5523 14 14 13.5523 14 13V9C14 8.44772 13.5523 8 13 8H9Z" fill={color}/>
        </svg>
    );
};

export default PlitkaPlus;