import React, { useState, useEffect, useRef } from 'react';
import PropTypes from "prop-types";
import Button from "../button/Button";
import Icon from "../icon/Icon";
import { reduxForm } from "redux-form";
import './TableControll.scss'
import { gray90 } from '../vars';
import { useSelector } from 'react-redux';

const FORM_NAME = 'TABLE_CONTROLL'
const TableControll = props => {
    const {
        children,
        widthAddButton,
        addButtonClick,
        withPagination,
        paginationPageCount,
        paginationPageNow,
        withSettings,
        settingsBlock,
        withSelectedInfo,
        countSelectedItems,
        countAllItems,
        paginationPageChange,
    } = props
    
    const windowWidth = useSelector(store => store.windowWidth)
    const isMobile = windowWidth <= 768;
    const [settingsShow, setSettingsShow] = useState(false)
    const settingsBlockRef = useRef();
    const paginationActive = useRef(false);
    const openSettingsBlock = () => {
        setSettingsShow(!settingsShow)
    }
    const settingsClickOutside = (e) => {
        if (settingsBlockRef.current && settingsBlockRef.current.contains(e.target)) {
            return;
        }
        setSettingsShow(false);
    };
    useEffect(() => {
        if (settingsShow) {
            setTimeout(() => {
                document.addEventListener('click', settingsClickOutside);
            }, 100)
        } else {
            document.removeEventListener('click', settingsClickOutside);
        }
        return () => {
            document.removeEventListener('click', settingsClickOutside);
        };
    }, [settingsShow]);

    const paginationClick = (type) => {
        if (!paginationActive.current) {
            paginationActive.current = true
            if (type == 'prev') {
                if (paginationPageNow == 0) {
                    paginationPageChange(paginationPageCount - 1)
                } else {
                    paginationPageChange(paginationPageNow - 1)
                }
            } else if (type == 'next') {
                let countPage = paginationPageCount - 1
                if (paginationPageNow < countPage) {
                    paginationPageChange(paginationPageNow + 1)
                } else {
                    paginationPageChange(0)
                }
            }
            setTimeout(() => {
                paginationActive.current = false
            }, 200)
        }
    }
    return (
        <div className='table-controll'>
            <div className='table-controll__left'>
                {withSettings &&
                    <>
                        <div className='border-block'>
                            <Button
                                size={40}
                                variant="primary"
                                onClick={openSettingsBlock}
                                customClass="w-60 for-outside-icon"
                            >
                                {settingsShow ?
                                    <Icon icon="Close" color={gray90} width={24} height={24} /> :
                                    <Icon icon="Settings" color={gray90} width={20} height={20} />
                                }

                            </Button>
                        </div>
                        {settingsShow &&
                            <div className='settings-block' ref={settingsBlockRef}>
                                {settingsBlock}
                            </div>
                        }
                    </>
                }
                {withSelectedInfo &&
                    <div className='border-block selected-info'>
                        {!isMobile && 'Выбранно'}
                        <span className='weight-bold'>{countSelectedItems}</span>
                        из
                        <span className='weight-bold'>{countAllItems}</span>
                    </div>
                }
            </div>
            <div className='table-controll__right'>

                {withPagination &&
                    <div className='pagination'>
                        <div className='border-block'>
                            <Button
                                size={40}
                                variant="text"
                                onClick={() => { paginationClick('prev') }}
                                customClass="w-30 revers-arrow-right"
                            >
                                <Icon icon="Arrow/Right" color={'#fff'} width={30} height={30} />
                            </Button>
                        </div>
                        <div className='border-block'>
                            <div className='pages'>
                                <span className='page-now'>{paginationPageNow + 1}</span>
                                / {paginationPageCount}
                            </div>
                        </div>
                        <div className='border-block'>
                            <Button
                                size={40}
                                variant="text"
                                onClick={() => { paginationClick('next') }}
                                customClass="w-30"
                            >
                                <Icon icon="Arrow/Right" color={'#fff'} width={30} height={30} />
                            </Button>
                        </div>
                    </div>
                }
                {widthAddButton &&
                    <div className='border-block'>
                        <Button
                            size={40}
                            variant="primary"
                            onClick={addButtonClick}
                            customClass="w-60"
                        >
                            <Icon icon="Plus" color={gray90} width={16} height={18} />
                        </Button>
                    </div>
                }
            </div>
        </div>
    );
};

TableControll.propTypes = {
    // show: PropTypes.bool
    widthAddButton: PropTypes.bool,
    addButtonClick: PropTypes.func,
    withPagination: PropTypes.bool,
    paginationPageCount: PropTypes.number,
    paginationPageNow: PropTypes.number,
    paginationPageChange: PropTypes.func,
    withSettings: PropTypes.bool,
    withSelectedInfo: PropTypes.bool,
    settingsBlock: PropTypes.element,
    countSelectedItems: PropTypes.number,
    countAllItems: PropTypes.number,
};

TableControll.defaultProps = {
    widthAddButton: false,
    addButtonClick: null,
    withPagination: false,
    paginationPageNow: 1,
    paginationPageCount: 1,
    paginationPageChange: null,
    withSettings: false,
    withSelectedInfo: false,
    settingsBlock: null,
    countSelectedItems: 1,
    countAllItems: 1,
};

const RowDetailForm = reduxForm({
    form: FORM_NAME
})(TableControll);
export default RowDetailForm;
