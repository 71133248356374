import React from 'react';
import { gray60 } from "../../vars.js";

export const Back = ({ width = 20, height = 20, color = gray60, className = '' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 81 8" fill="none" stroke={color} stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class={className}>
            <path
                d="M0.646446 3.64645C0.451187 3.84171 0.451187 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53554 7.53553C4.7308 7.34027 4.7308 7.02369 4.53554 6.82843L1.70711 4L4.53554 1.17157C4.7308 0.976311 4.7308 0.659728 4.53554 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646446 3.64645ZM81 3.5L1 3.5V4.5L81 4.5V3.5Z" />
        </svg>
    );
};

export default Back;