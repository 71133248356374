import React from 'react';
import {blue60} from "../../vars";

const Attach = ({width = 18, height = 16, color = blue60, className = ''}) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.294 14.1123C9.04588 15.3531 7.62143 16 6.1672 16C4.69328 16 3.24167 15.3363 1.96113 14.0633C0.77036 12.8795 0.0941574 11.5295 0.0090078 10.1584C-0.0861439 8.62323 0.574183 7.10751 1.91186 5.77769L7.45481 0.267278C7.75348 -0.0296348 8.23713 -0.0296348 8.53464 0.267278C8.83379 0.564669 8.83379 1.04597 8.53465 1.34335L2.99164 6.85477C1.95346 7.88592 1.46607 8.96493 1.53417 10.0649C1.5959 11.0561 2.11556 12.0672 3.04196 12.9873C4.89465 14.8306 7.01118 15.2254 9.21323 13.0362L15.3185 6.96295C16.779 5.51102 16.8528 3.73013 15.5157 2.40001C14.2033 1.09801 12.5597 1.2397 11.06 2.73155L5.53273 8.19464C4.60256 9.11934 4.4799 10.0253 5.23225 10.7733C5.92757 11.4645 6.82584 11.385 7.7862 10.4294L12.4287 5.81323C12.7273 5.51635 13.2108 5.51635 13.5093 5.81306C13.8087 6.1095 13.8087 6.59189 13.5095 6.88931L8.8661 11.5054C7.35355 13.0112 5.50241 13.1905 4.15242 11.8494C2.78397 10.4889 2.93404 8.62759 4.45317 7.11637L9.98141 1.65324C12.0564 -0.407532 14.6909 -0.569569 16.5954 1.32383C17.5011 2.22412 18.001 3.3666 18 4.53842C17.999 5.78936 17.4441 7.00043 16.3993 8.03901L10.294 14.1123Z" fill={color}/>
        </svg>
    );
};

export default Attach;