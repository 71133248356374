import * as types from './types';
import apiMiddleware from "../../middleware/index";


export const userProfile = (method, request, url, typesList) => (dispatch, getState) => {        
    dispatch({ type: types.PRELOADER_SHOW })
    dispatch({ type: typesList[0] })
    apiMiddleware(method, request, url, typesList)
        .then(data => {
            dispatch(data)
        })
        .catch(err => {
            dispatch(err)
        })
        .finally(() => {
            setTimeout(() => {
                dispatch({ type: types.PRELOADER_HIDE })
            }, 100)
        })
    // const user = getState().authState;

    // if (user.isAuthenticated && user.token) {
    //     dispatch(fetchProfile(noCacheRequest(request), user.token));
    // }
};