import React, { useContext, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Context from '../utils/context';
import { connect } from 'react-redux';
import ModalRoot from '../models/ModalRoot';
import Header from '../components/Header';
import '../css/default.scss';
import 'normalize.css';
import '../css/vars.scss';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { createStore, applyMiddleware } from 'redux';
// import AuthUserReducer from './frontend/store/reducers/AuthUserReducer';
import thunk from 'redux-thunk';
import { userProfile } from "../store/actions/ProfileActions";
import { deepFind } from '../../backend/models/helpers';
import * as type from "../store/actions/types"
import Preloader from "../common/preloader/Preloader"
import NotificationPush from "../common/notificationPush/NotificationPush"
import WsClient from '../Helpers/wsClient';
import * as types from "../store/actions/types"
// const store = createStore(AuthUserReducer, applyMiddleware(thunk));
const cookies = new Cookies();

const ProfileLayout = (props) => {
    const { children, preloader, userState, profileAction } = props
    const profileIsLoad = useRef(false)
    const context = useContext(Context)
    const [profile, setProfile] = useState({})
    const wsState = useSelector(store => store.wsState)
    useEffect(() => {
        if (wsState.action == types.PROFILE_RELOAD) {
            props.userProfile('GET', null, '/load_profile', [type.USER_PROFILE_REQUEST, type.USER_PROFILE_SUCCESS, type.USER_PROFILE_FAILURE])
        }
    }, [wsState])
    // docIsAdding.current = false;
    useEffect(() => {
        const notActionLoad = [type.USER_PROFILE_UPDATE_REQUEST, type.USER_PROFILE_SUCCESS]
        // if (notActionLoad.indexOf(userState.action) < 0 && (!profile || (typeof profile === 'object' && Object.keys(profile).length <= 0))) {
        if (Object.keys(context.profile).length <= 0) {
            props.userProfile('GET', null, '/load_profile', [type.USER_PROFILE_REQUEST, type.USER_PROFILE_SUCCESS, type.USER_PROFILE_FAILURE])
            // props.dispatch(props.userProfile('GET', null, '/load_profile', [type.USER_PROFILE_REQUEST, type.USER_PROFILE_SUCCESS, type.USER_PROFILE_FAILURE]));
        }
    }, [profile]);
    useEffect(() => {
        if (profileAction == type.USER_PROFILE_SUCCESS) {
            profileIsLoad.current = true
            context.profile = userState.data
            setProfile(context.profile)
        }
    }, [profileAction]);

    return (
        <section id="profile">

            {profileIsLoad.current &&
                <>
                    <Header />
                    {children}
                </>
            }
            <WsClient />
            <ModalRoot />
            <Preloader show={preloader} />
            <NotificationPush />
            {/* preloader */}
        </section>

    )
};




const mapStateToProps = store => {
    // const pf = store.userState.size > 0 ? store.userState.get("profile") : null;    
    return {
        userState: store.userState,
        // profile: deepFind(store.userState, 'data'),
        profileAction: deepFind(store.userState, 'action'),
        userState: store.userState,
        preloader: deepFind(store.preloaderState, 'show'),

    }
}

const mapDispatchToProps = {
    userProfile,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfileLayout);
// export default ProfileLayout;
