import React from 'react';
import {blue60} from "../../vars";

const ChevronBack = ({ width = 8, height = 14, color = blue60, className }) => {
  return (
    <svg className={className} width={width} height={height} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.4658 1.95759C7.73245 1.68761 7.73247 1.24987 7.46584 0.979863C7.19922 0.709857 6.76691 0.709835 6.50026 0.979813L1.24874 6.29686C0.86404 6.68635 0.86404 7.31278 1.24874 7.70228L6.50026 13.0193C6.76691 13.2893 7.19922 13.2893 7.46584 13.0193C7.73247 12.7493 7.73245 12.3115 7.4658 12.0415L2.48595 6.99957L7.4658 1.95759Z" fill={color}/>
    </svg>
  );
};

export default ChevronBack;