import axios from 'axios';
// import { API } from '../actions/types';
import { apiActionHook, apiError } from '../store/actions/api';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
// const apiMiddleware = ({ dispatch }) => next => action => {
const sendRequest = (method, data, url, actions, dispatch) => {
    // next(action);

    // if (action.type !== API) { // only apply middleware to actions of type API
    //   return;
    // }

    // const { url, method, data, onSuccess, onFailure, label } = action.payload;

    // Adds support to POST and PUT requests with data 
    const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data';

    // axios configs
    axios.defaults.baseURL = process.env.NODE_ENV !== 'production' ? 'http://localhost:9100/api' : 'https://ag-platform.ru/api';
    axios.defaults.headers.common['Content-Type'] = 'application/json';

    // if (label) {
    // dispatch(apiStart(label)); // Action to notify that the api call is starting.
    // dispatch(apiActionHook(actions[0], {})); // Action to notify that the api call is starting.
    // }
    let headers = {
        withCredentials: true,
    }
    const token = cookies.get('token')
    if (token) {
        headers['x-access-token'] = token
    }
    return axios
        .request({
            url,
            method,
            [dataOrParams]: data,
            headers: headers
        })
        .then(({ data }) => {
            return data
        })
        .catch(error => {
            // dispatch(apiError(error));
            // Original apiAction executor's error handler. e.g. Fn passed inside fetchPosts action. 
            return error
        })
    // .finally(() => {
    //   if (label) {
    //     // Action to notify that the api call has ended.
    //     dispatch(apiEnd(label));
    //   }
    // });
};

export default sendRequest;