import React from 'react';
import {blue60} from "../../vars";

const CheckInfo = ({width = 11, height = 8, color=blue60, className}) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.26675 0.668838C9.62994 0.310121 10.2188 0.310092 10.5821 0.668772C10.9453 1.02745 10.9453 1.60902 10.5821 1.96773L4.87851 7.58243C4.4909 7.964 3.86939 7.9658 3.47958 7.58647L0.472451 4.66026C0.109259 4.30155 0.109289 3.71998 0.472518 3.3613C0.835747 3.00262 1.42463 3.00265 1.78782 3.36136L4.18102 5.67099L9.26675 0.668838Z" fill={color}/>
        </svg>
        );
};

export default CheckInfo;