import * as types from './types'
// import {MAKE_REQUEST_TO_API_SERVER} from "middleware/Api";
// import {Schemas} from "middleware/schemas";
// import {UPDATE_POPUP_FAILURE, UPDATE_POPUP_REQUEST, UPDATE_POPUP_SUCCESS} from "./ApplicationConstants";

export const initializeModals = (modals) => {
    return {
        type: types.INITIALIZE_MODALS,
        payload: modals,
    }
}

export const showModal = (modalType, modalProps) => {
    return {
        type: types.SHOW_MODAL,
        payload: {
            modalType,
            modalProps,
        },
    }
}

export const hideModal = (modalType) => {
    return (dispatch) => {
        dispatch({
            type: types.HIDE_MODAL,
            payload: {
                modalType,
            }
        });

        setTimeout(() => {
            dispatch({
                type: types.UNMOUNT_MODAL,
                payload: {
                    modalType,
                }
            })
        }, 400)
    }
}

export const unmountModal = (modalType) => {
    return dispatch => {
        dispatch({
            type: types.UNMOUNT_MODAL,
            payload: {
                modalType,
            }
        });
    }
}