import React from 'react';
import {blue60} from "../../vars";

const Check = ({width = 10, height = 8, color=blue60, className}) => {
    return (
        <svg className={className} width={width} height={height} viewBox={"0 0 10 8"} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.132954 4.44149C-0.044318 4.25349 -0.044318 3.94749 0.132954 3.75749L0.775678 3.07449C0.95295 2.88649 1.24113 2.88649 1.4184 3.07449L3.63657 5.46649L8.5811 0.141488C8.75837 -0.0465117 9.04655 -0.0465117 9.22382 0.141488L9.86655 0.825488C10.0438 1.01349 10.0438 1.32049 9.86655 1.50749L3.95748 7.85849C3.78021 8.04649 3.49203 8.04649 3.31476 7.85849L0.132954 4.44149Z" fill={color}/>
        </svg>
    );
};

export default Check;