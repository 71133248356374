import React from 'react';

const Notification = ({width = 14, height = 16.7, color='#0044CC', className}) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.64329 0.381282C7.47254 0.217187 7.24095 0.125 6.99947 0.125C6.75799 0.125 6.5264 0.217187 6.35565 0.381282C6.18489 0.545376 6.08897 0.767936 6.08897 1L5.99727 2.425C5.99725 2.43726 5.99756 2.44951 5.99818 2.46175L6 2.49675C4.7264 2.70335 3.6618 3.33679 2.82793 4.28471C1.99406 5.23263 1.53651 6.43374 1.53645 7.675C1.53645 10.3814 0.967391 11.448 0.0805613 13.1088L0.0496043 13.1683C0.0143473 13.2344 -0.00262069 13.3082 0.000328103 13.3825C0.00327689 13.4568 0.0260435 13.5292 0.0664441 13.5926C0.106845 13.656 0.163524 13.7084 0.231046 13.7447C0.298568 13.781 0.374668 13.8001 0.452046 13.8H13.5469C13.8856 13.8 14.1041 13.457 13.9493 13.1683L13.9184 13.1088C13.0315 11.448 12.4625 10.3814 12.4625 7.675C12.4622 6.43389 12.0046 5.23298 11.1707 4.28525C10.3369 3.33751 9.27345 2.7042 8 2.49763L8.00182 2.47137L8.00273 2.453L7.90997 1C7.90997 0.767936 7.81404 0.545376 7.64329 0.381282ZM8.19144 16.3728C8.52901 16.0352 8.71865 15.5774 8.71865 15.1H5.11865C5.11865 15.5774 5.30829 16.0352 5.64586 16.3728C5.98343 16.7104 6.44126 16.9 6.91865 16.9C7.39604 16.9 7.85388 16.7104 8.19144 16.3728Z" fill={color}/>
        </svg>
    );
};

export default Notification;