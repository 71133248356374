import { REG_REQUEST, REG_SUCCESS, REG_FAILURE, API } from './types';
// import {MAKE_REQUEST_TO_API_SERVER} from "../middleware/Api";
import apiMiddleware from "../../middleware/index";
// import {Schemas} from "../middleware/schemas";
// import {deepFind} from "../Helpers/Common";

/**
 *
 * @param request
 * @param token
 */
const apiAction = ({
    url,
    method = 'GET', // Default method
    data = null,
    onSuccess = () => { },
    onFailure = () => { },
    label
}) => {
    return {
        type: API,
        payload: {
            url,
            method,
            data,
            onSuccess,
            onFailure,
            label
        }
    };
}
export function setPosts(data) {
    return {
        type: REG_SUCCESS,
        payload: data
    };
}
const sendSaveRequest = (request, token) => ({
    ['MAKE_REQUEST_TO_API_SERVER']: {
        types: [REG_REQUEST, REG_SUCCESS, REG_FAILURE],
        schema: 'post',
        request: request
    }
});
/**
 *
 * @param request
 */
const saveRegUser = (request) => (dispatch, getState) => {
    // console.log(99999)
    // console.log(99999)
    // console.log(getState())
    // (method, data, url, actions, dispatch)
    dispatch({ type: REG_REQUEST })
    apiMiddleware('POST', request, '/registration', [REG_REQUEST, REG_SUCCESS, REG_FAILURE])
        .then(data => {
            dispatch(data)
        })
        .catch(err => {
            dispatch(err)
        })
    // console.log(getState())
    // return apiAction({
    //     url: 'https://jsonplaceholder.typicode.com/posts', // Mocked Backend Data.
    //     onSuccess: setPosts,
    //     onFailure: () => console.log('Error while loading posts'), // Dummy error handler.
    //     label: REG_REQUEST
    // });
    // let regUserState = getState().flatState;
    // if (deepFind(carState, 'carItems.' + request.widgetId)) {
    //     delete carState.carItems[request.widgetId];
    // }
    // const user = getState().authState;
    // if (user.isAuthenticated && user.token) {
    //     if (request.code === 'remove') {
    //         setTimeout(() => {
    //             dispatch({type: ''});
    //         }, 1);
    //     }
    // dispatch(sendSaveRequest(request, 'user.token'));
    // dispatch({ type: 'REG_REQUEST' });
    // dispatch({type : "REG_REQUEST"});
    // }
};

export default saveRegUser