import React from 'react';
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm, getFormAsyncErrors, getFormSubmitErrors, getFormSyncErrors, getFormValues, touch, formValueSelector } from "redux-form";
import FormBlock from "../common/formBlock/FormBlock";
import TextField from "../common/textfield/TextField";
import ButtonsBlock from "../common/buttonsBlock/ButtonsBlock";
import Button from "../common/button/Button";
import Icon from "../common/icon/Icon";
import { black, blue60, gray60 } from "../common/vars.js";
import { FormUtilsMask, FormUtilsValidator, } from "../utils/FormUtils.js";
import forgotUser from "../store/actions/ForgotUserActions"
import { deepFind } from '../../backend/models/helpers';
import * as types from "../store/actions/types";
import * as modals from "../store/actions/ModalConstants";
import { hideModal, showModal } from "../store/actions/ModalActions";
import { useHref } from 'react-router';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const FORM_NAME = 'FORGOTFORM';

class ForgotForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            saveInProgress: false,
        }
    }
    componentDidUpdate(prevProps) {
        if (this.state.saveInProgress) {

            if (deepFind(this.props, 'forgotUserState.action') == types.FORGOT_SUCCESS) {
                this.setState({
                    saveInProgress: false
                });
                this.props.dispatch(showModal(modals.MODAL_NOTIFICATION, {
                    title: 'Новый пароль отправлен вам на почту',
                    variant: 'success',
                    // children: 'На вашу почту отправлено письмо. Для окончания регистрации перейдите по ссылке из письма',
                    buttonTitle: 'Продолжить',
                }));

                const { reset, clearSubmit, clearFields } = this.props;
                reset();
                clearSubmit();
                clearFields();

            } else if (deepFind(this.props, 'forgotUserState.action') == types.FORGOT_FAILURE) {
                this.setState({
                    saveInProgress: false
                });
                this.props.dispatch(showModal(modals.MODAL_NOTIFICATION, {
                    title: deepFind(this.props, 'forgotUserState.errorMessage') || 'Ошибка сервера',
                    variant: 'error',
                    buttonTitle: 'Продолжить',
                }));
            }
        }
        // if (this.props.objectId !== prevProps.objectId) {
        //     props.change('objectId', this.props.objectId);
        // }
    }
    render() {
        const handleFormSubmit = values => {
            const request = {
                email: values.LOGIN,
            }

            this.setState({
                saveInProgress: true
            });
            this.props.dispatch(forgotUser(request));
        };

        return (
            <form onSubmit={this.props.handleSubmit(handleFormSubmit)} className={FORM_NAME}>
                <h3 className="form-caption">Восстановить пароль</h3>
                <FormBlock>
                    <TextField
                        id={'LOGIN'}
                        name={'LOGIN'}
                        label={'Email'}
                        size={'fullWidth'}
                        validate={[FormUtilsValidator.required, FormUtilsValidator.email]}
                    />
                    <ButtonsBlock>
                        <Button
                            size={40}
                            variant="primary"
                            // onClick={handleMobileFilterButtonClick}
                            fullWidth={true}
                            type={'submit'}
                            disabled={this.state.saveInProgress}
                        >
                            Восстановить
                        </Button>
                    </ButtonsBlock>
                </FormBlock>
            </form>
        )
    }
}
const RowDetailForm = reduxForm({
    form: FORM_NAME
})(ForgotForm);

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (store) => {
    return {
        authState: store.authState,
        forgotUserState: store.forgotUserState,
        formValues: {
            LOGIN: selector(store, 'LOGIN') || '',
        }
    };
};
export default connect(mapStateToProps, null, null, { forwardRef: true })(RowDetailForm);