// import { showModal } from '../../../../actions/ModalActions';
import { chunk } from 'lodash'
export default {
    showError: (props, error) => {
        // props.dispatch(showModal(modal.MODAL_TYPE_ALERT, {
        //     noIcon: true,
        //     redTitle: 'Ошибка!',
        //     content: (error.message) ? error.message : 'Ошибка',
        //     noRefresh: true
        // }));
    },
    arrayChunk: (array, chunkSize) => {
        return array.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / chunkSize)
            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [] // start a new chunk
            }
            resultArray[chunkIndex].push(item)
            return resultArray
        }, [])
    },
    objectChunk: (object, chunkSize) => {
        const arrayFromObject = Object.entries(object).map(([key, value]) => ({ [key]: value }));
        return chunk(arrayFromObject, chunkSize);
    },
    declOfNum: (number, titles) => {
        const cases = [2, 0, 1, 1, 1, 2];
        return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    },

}