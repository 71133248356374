module.exports.deepFind = (obj, path) => {
    let paths = path.split('.'),
        current = obj;
    for (let i = 0; i < paths.length; ++i) {
        if (current === undefined || current === null || current[paths[i]] === undefined || current[paths[i]] === null) {
            return null;
        } else {
            current = current[paths[i]];
        }
    }
    return current;
};
module.exports.emailCheck = value => {
    if (value === '' || value === undefined || value === null)
        return false;
    return /^[\-а-яёa-z0-9!#$%&'*+/=?^_`{|}~]+(?:\.[\-a-zа-яё0-9!#$%&'*+/=?^_`{|}~]+)*@(?:[a-zа-яё0-9]([\-а-яёa-z0-9]{0,61}[а-яёa-z0-9])?\.)+(?:[a-zа-яё0-9]{2,})$/i.test(value)
        ? true : false;
}
module.exports.fioCheck = value => {
    if (value === '' || value === undefined || value === null)
        return false;
    return /^[а-яё\.]+([- \`\']{1}[а-яё\.]+)*\.?$/i.test(value) ? true : false
}
module.exports.nameCheck = value => {
    if (value === '' || value === undefined || value === null)
        return false;
    return /^[а-яёa-z\.\(\)_0-9]+([- \`\'\(\)_0-9]{1}[а-яёa-z\.\(\)_0-9]+)*\.?$/i.test(value) ? true : false
}
module.exports.colorCheck = value => {
    if (value === '' || value === undefined || value === null)
        return false;
    return /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i.test(value) ? true : false
}
module.exports.idCheck = value => {
    if (value === '' || value === undefined || value === null)
        return false;
    return /^[a-zA-Z0-9]{24}/.test(value) ? true : false
}
module.exports.descriptionCheck = value => {
    if (value === '' || value === undefined || value === null)
        return false;
    return /^[a-zа-яеё0-9-_\(\).!\s;*\"\']/i.test(value) ? true : false
}
module.exports.addressCheck = value => {
    if (value === '' || value === undefined || value === null)
        return false;
    return /^[a-zа-яеё0-9\/\-_\(\).!\s\"\']/i.test(value) ? true : false
}
module.exports.dateCheck = value => {
    if (value === '' || value === undefined || value === null)
        return false;
    let matches = /^(\d{1,2})[.\/](\d{1,2})[.\/](\d{4})$/.exec(value),
        error = null;
    if (matches) {
        let d = parseInt(matches[1], 10);
        let m = parseInt(matches[2], 10) - 1;
        let y = parseInt(matches[3], 10);
        let composedDate = new Date(y, m, d);
        let result = composedDate.getDate() === d && composedDate.getMonth() === m && composedDate.getFullYear() === y;
        if (!result) {
            return false
        } else {
            return true
        }

    } else {
        return false
    }

}