import React from 'react';
import {blue60} from "../../vars";

const Print = ({width = 18, height = 18, color = blue60, className = ''}) => {
    return (
        <svg  width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M17,4 C17.5522847,4 18,4.44771525 18,5 L18,8 L20,8 C20.5522847,8 21,8.44771525 21,9 L21,17 C21,17.5522847 20.5522847,18 20,18 L18,18 L18,20 C18,20.5522847 17.5522847,21 17,21 L7,21 C6.44771525,21 6,20.5522847 6,20 L6,18 L4,18 C3.44771525,18 3,17.5522847 3,17 L3,9 C3,8.44771525 3.44771525,8 4,8 L6,8 L6,5 C6,4.44771525 6.44771525,4 7,4 L17,4 Z M16.5,14.5 L7.5,14.5 L7.5,19 C7.5,19.2761424 7.72385763,19.5 8,19.5 L8,19.5 L16,19.5 C16.2761424,19.5 16.5,19.2761424 16.5,19 L16.5,19 L16.5,14.5 Z M14.25,16 C14.6642136,16 15,16.3357864 15,16.75 C15,17.1642136 14.6642136,17.5 14.25,17.5 L9.75,17.5 C9.33578644,17.5 9,17.1642136 9,16.75 C9,16.3357864 9.33578644,16 9.75,16 L14.25,16 Z M6.25,10 L5.75,10 C5.33578644,10 5,10.3357864 5,10.75 C5,11.1642136 5.33578644,11.5 5.75,11.5 L5.75,11.5 L6.25,11.5 C6.66421356,11.5 7,11.1642136 7,10.75 C7,10.3357864 6.66421356,10 6.25,10 L6.25,10 Z M16,5.5 L8,5.5 C7.72385763,5.5 7.5,5.72385763 7.5,6 L7.5,6 L7.5,8 L16.5,8 L16.5,6 C16.5,5.72385763 16.2761424,5.5 16,5.5 L16,5.5 Z" id="print" fill={color}></path>
        </svg>
    );
};

export default Print;