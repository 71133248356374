import { FORGOT_REQUEST, FORGOT_SUCCESS, FORGOT_FAILURE } from './types';
// import {MAKE_REQUEST_TO_API_SERVER} from "../middleware/Api";
import apiMiddleware from "../../middleware/index";
// import {Schemas} from "../middleware/schemas";
// import {deepFind} from "../Helpers/Common";


/**
 *
 * @param request
 */
const forgotUser = (request) => (dispatch, getState) => {

    dispatch({ type: FORGOT_REQUEST })
    apiMiddleware('POST', request, '/forgot', [FORGOT_REQUEST, FORGOT_SUCCESS, FORGOT_FAILURE])
        .then(data => {
            dispatch(data)
        })
        .catch(err => {
            dispatch(err)
        })
};

export default forgotUser