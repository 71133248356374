import React from 'react';
import {blue60} from "../../vars";

const MiniClose = ({ width = 10, height = 10, color = blue60, className }) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.73144 1.19941C8.43855 0.906513 7.96367 0.906513 7.67078 1.19941L5 3.87018L2.32922 1.19941C2.03633 0.906513 1.56146 0.906513 1.26856 1.19941L1.19942 1.26855C0.906528 1.56144 0.906528 2.03632 1.19942 2.32921L3.8702 4.99999L1.19942 7.67077C0.906528 7.96366 0.906528 8.43853 1.19942 8.73143L1.26856 8.80057C1.56146 9.09346 2.03633 9.09346 2.32922 8.80057L5 6.12979L7.67078 8.80057C7.96367 9.09346 8.43855 9.09346 8.73144 8.80057L8.80058 8.73143C9.09348 8.43853 9.09348 7.96366 8.80058 7.67077L6.12981 4.99999L8.80058 2.32921C9.09348 2.03632 9.09348 1.56144 8.80058 1.26855L8.73144 1.19941Z" fill={color}/>
        </svg>
    );
};

export default MiniClose;