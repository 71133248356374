import React from 'react';
import ModalDialog from "../ModalDialog.jsx";
import PropTypes from "prop-types";

import './ModalForm.scss';
import Button from "../..//button/Button.jsx";
import Icon from "../../icon/Icon.jsx";

const ModalForm = (props) => {
    const {
        children,
        onHide,
        isShow,
        modalHeader,
        className,
        contentClassName,
        isGoBackButton,
        subtitle,
        withDashSubtitle,
        underTitle,
        underTitleMarginTop,
        buttonBlock,
        onClickBackButton,
        size,
        goBackButtonContent,
    } = props;

    return (
        <ModalDialog
            onHide={onHide}
            isShow={isShow}
            withCloseBtn={true}
            paddingTop={32}
            className={`nlk-modalForm ${className || ''}`}
            size={size}
            centered
        >
            <div
                className={`nlk-modalForm__header ${typeof(modalHeader ) === 'string' ? 'nlk-modalForm__header_string' : null}`}
            >
                <div className={'nlk-modalForm__header__content'}>
                    <div className={'nlk-modalForm__header-details'}>
                        {isGoBackButton &&
                        <Button onClick={onClickBackButton || null} variant={'ghost'} size={32}>
                            <div className="nlk-modalForm__goBackButtonContent">
                                <Icon icon={'Arrow/Back'} className={'nlk-modalForm__header-icon'} width={12} />
                                {goBackButtonContent}
                            </div>
                        </Button>
                        }
                        {subtitle  && <div className='nlk-modalForm__header-subtitle'>
                            {isGoBackButton &&  subtitle && <span className='nlk-modalForm__header-step-dash'>-</span>}
                            {withDashSubtitle && <span className={'nlk-modalForm__header-subtitle-line'}>-</span> }
                            {subtitle}
                        </div>
                        }
                    </div>
                    {modalHeader}
                </div>
                {underTitle?  <div className={`nlk-modalForm__header-undertitle ${underTitleMarginTop === 16? 'nlk-modalForm__header-undertitle-margin' : '' }`}>
                    {underTitle}
                </div> : null
                }

            </div>
            <div className={`nlk-modalForm__content ${contentClassName}`}>
                {children}
            </div>
            <div className={`nlk-modalForm__bottomBlock ${contentClassName}`}>
                {!!buttonBlock && buttonBlock}
            </div>
        </ModalDialog>
    );
};

ModalForm.defaultProps = {
    className: '',
    contentClassName: '',
    isGoBackButton:false,
    subtitle:'',
    underTitle:null,
    withDashSubtitle:false,
    underTitleMarginTop:8,
    buttonBlock: null,
    onClickBackButton: null,
    size: 'lg',
    goBackButtonContent: 'Назад',
}

ModalForm.propTypes = {
    onHide: PropTypes.func.isRequired,
    isShow: PropTypes.bool.isRequired,
    modalHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    isGoBackButton: PropTypes.bool,
    withDashSubtitle: PropTypes.bool,
    subtitle: PropTypes.string,
    underTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    underTitleMarginTop:PropTypes.oneOf([8, 16]),
    buttonBlock: PropTypes.element,
    onClickBackButton: PropTypes.func,
    size: PropTypes.oneOf(['sm','md', 'lg', 'xl']),
    goBackButtonContent: PropTypes.string,
}

export default ModalForm;