import * as types from "../actions/types";

const preloaderReducer = (state = { show: false }, action) => {
    switch (action.type) {
        case types.PRELOADER_SHOW:
            return {
                ...state,
                show: true,
                action: action.type,
                data: action.payload
            };
            break;
        case types.PRELOADER_HIDE:
            return {
                ...state,
                show: false,
                action: action.type,
                data: action.payload
            };
            break;
    }
    return state;
};

export default preloaderReducer;
