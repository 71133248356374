import React from 'react';

const NotificationError = ({width = 16, height = 16, className}) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 8C0 12.4112 3.5888 16 8 16C12.4112 16 16 12.4112 16 8C16 3.5888 12.4112 0 8 0C3.5888 0 0 3.5888 0 8ZM11.2426 4.75736C11.6332 5.14788 11.6332 5.78105 11.2426 6.17157L9.41421 8L11.2426 9.82843C11.6332 10.219 11.6332 10.8521 11.2426 11.2426C10.8521 11.6332 10.219 11.6332 9.82843 11.2426L8 9.41421L6.17157 11.2426C5.78105 11.6332 5.14788 11.6332 4.75736 11.2426C4.36684 10.8521 4.36684 10.219 4.75736 9.82843L6.58579 8L4.75736 6.17157C4.36684 5.78105 4.36684 5.14788 4.75736 4.75736C5.14788 4.36684 5.78105 4.36684 6.17157 4.75736L8 6.58579L9.82843 4.75736C10.219 4.36684 10.8521 4.36684 11.2426 4.75736Z" fill="#AA0000"/>
        </svg>
    );
};

export default NotificationError;