import React from 'react';
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm, getFormAsyncErrors, getFormSubmitErrors, getFormSyncErrors, getFormValues, touch, formValueSelector, initialize } from "redux-form";
import FormBlock from "./../../../common/formBlock/FormBlock";
import TextField from "../../../common/textfield/TextField";
import Datepicker from "../../../common/datepicker/Datepicker";
import ButtonsBlock from "../../../common/buttonsBlock/ButtonsBlock";
import Button from "../../../common/button/Button";
import AutosuggestField from "../../../common/autosuggestField/AutosuggestField"
import Checkbox from "../../../common/checkbox/Checkbox"
import { FormUtilsMask, FormUtilsValidator, } from "../../../utils/FormUtils.js";
// import loginUser from "../store/actions/LoginUserActions"
import { deepFind } from '../../../../backend/models/helpers';
import moment from 'moment';
import { generalRequest } from "../../../store/actions/GeneralActions";
import * as type from "../../../store/actions/types"
import * as modals from "../../../store/actions/ModalConstants";
import { hideModal, showModal } from "../../../store/actions/ModalActions";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const FORM_NAME = 'PROJECTFORM';

class ProjectForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            saveInProgress: false,
            addressIsError: false,
            addressError: '',
            suggestions: [],
            isFetching: false,
            address: {},
            isLoading: false,

        }
    }
    componentDidMount() {
        let formDataValues = {}
        if (this.props.itemID) {
            const findProjectData = deepFind(this.props.profile, `projects.${this.props.itemID}`)
            if (findProjectData) {
                this.setState({
                    address: findProjectData.address,
                });
                formDataValues = {
                    NAME: findProjectData.name,
                    DESCRIPTION: findProjectData.description,
                    HAND_ADDRESS: deepFind(findProjectData, `address.hand_address`),
                    NOT_FIND_ADDRESS: deepFind(findProjectData, `address.not_find_address`),
                }
            }
        } else {
            formDataValues = {

                NAME: '',
                DESCRIPTION: '',
                HAND_ADDRESS: '',
                NOT_FIND_ADDRESS: null,

                // SURNAME: deepFind(pf, 'FIO.data.SURNAME') || '',
                // PATRONYMIC: deepFind(pf, 'FIO.data.PATRONYMIC') || '',
                // GENDER: deepFind(pf, 'PERSON.data.GENDER') || '',
                // BIRTHDATE: deepFind(pf, 'PERSON.data.BIRTHDATE') || '',
                // SNILS: deepFind(pf, 'SNILS.data.SNILS') || '',
            }
        }
        this.props.dispatch(initialize(FORM_NAME, formDataValues));
        this.setState({
            isLoading: true,
        });
        // initializeValues(formData.initialValues);
    }
    componentDidUpdate(prevProps) {
        if (this.props.formValues.NOT_FIND_ADDRESS !== prevProps.formValues.NOT_FIND_ADDRESS && !this.props.formValues.NOT_FIND_ADDRESS) {
            const formDataValues = { HAND_ADDRESS: '', NOT_FIND_ADDRESS: this.props.formValues.NOT_FIND_ADDRESS, NAME: this.props.formValues.NAME, DESCRIPTION: this.props.formValues.DESCRIPTION }
            this.props.dispatch(initialize(FORM_NAME, formDataValues));
        }
        if (this.state.isFetching) {
            if (deepFind(this.props, 'generalRequestState.action') == type.SEARCH_ADDRESS_SUCCESS) {
                this.setState({
                    isFetching: false,
                    suggestions: deepFind(this.props, 'generalRequestState.data.suggestions')
                });
            } else if (deepFind(this.props, 'generalRequestState.action') == type.SEARCH_ADDRESS_FAILURE) {
                this.setState({
                    isFetching: false
                });
                this.props.dispatch(showModal(modals.MODAL_NOTIFICATION, {
                    title: deepFind(this.props, 'generalRequestState.errorMessage') || 'Ошибка сервера',
                    variant: 'error',
                    buttonTitle: 'Продолжить',
                }));
            }
        }
        if (this.state.saveInProgress) {
            if (deepFind(this.props, 'generalRequestState.action') == type.ADD_PROJECT_SUCCESS) {
                this.setState({
                    saveInProgress: false,
                    // suggestions: deepFind(this.props, 'generalRequestState.data.suggestions')
                });
                this.props.dispatch(showModal(modals.MODAL_NOTIFICATION, {
                    title: this.props.itemID ? 'Филиал изменен' : 'Филиал добавлен',
                    // redirectUrl: '/profile/projects/' + deepFind(this.props, 'generalRequestState.data.id'),
                    redirectUrl: '/profile/projects',
                    variant: 'success',
                }))
            } else if (deepFind(this.props, 'generalRequestState.action') == type.ADD_PROJECT_FAILURE) {
                this.setState({
                    saveInProgress: false
                });
                this.props.dispatch(showModal(modals.MODAL_NOTIFICATION, {
                    title: deepFind(this.props, 'generalRequestState.errorMessage') ? 'Ошибка' : 'Ошибка сервера',
                    children: deepFind(this.props, 'generalRequestState.errorMessage') || '',
                    variant: 'error',
                    buttonTitle: 'Продолжить',
                }));
            }
        }
    }
    render() {
        const handleFormSubmit = values => {
            if (this.props.dirty) {
                let address = {}
                if (!values.NOT_FIND_ADDRESS) {
                    address = this.state.address
                }
                let request = {
                    name: values.NAME,
                    description: values.DESCRIPTION,
                    address: {
                        ...address,
                        not_find_address: values.NOT_FIND_ADDRESS,
                        hand_address: values.HAND_ADDRESS
                    }
                }
                if (this.props.itemID) {
                    request['itemID'] = this.props.itemID
                }
                this.props.generalRequest('POST', request, '/add_project', [type.ADD_PROJECT_REQUEST, type.ADD_PROJECT_SUCCESS, type.ADD_PROJECT_FAILURE])
                this.setState({
                    saveInProgress: true,
                })
            }
        };
        const getSuggestions = value => {
            this.setState({
                isFetching: true
            })
            const request = {
                term: value
            }
            this.props.generalRequest('POST', request, '/search_address', [type.SEARCH_ADDRESS_REQUEST, type.SEARCH_ADDRESS_SUCCESS, type.SEARCH_ADDRESS_FAILURE], true)
        }
        const onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {

            this.setState({
                address: { ...suggestion.data, address_label: suggestion.unrestricted_value }
            })
        }

        return (<>
            {this.state.isLoading &&
                <form onSubmit={this.props.handleSubmit(handleFormSubmit)} className={FORM_NAME}>
                    <FormBlock>
                        <TextField
                            id={'NAME'}
                            name={'NAME'}
                            label={'Название'}
                            size={'fullWidth'}
                            validate={[FormUtilsValidator.required, FormUtilsValidator.name, FormUtilsValidator.maxLength75]}
                        />
                    </FormBlock>
                    <FormBlock>
                        {this.props.formValues.NOT_FIND_ADDRESS ?
                            <TextField
                                id={'HAND_ADDRESS'}
                                name={'HAND_ADDRESS'}
                                label={'Адрес'}
                                size={'fullWidth'}
                                validate={[FormUtilsValidator.required, FormUtilsValidator.maxLength150]}
                            /> :
                            <AutosuggestField
                                id={'address-sug'}
                                name={'address-sug'}
                                label={'Адрес'}
                                placeholder={'Начните вводить адрес...'}
                                validate={[FormUtilsValidator.required]}
                                isError={this.state.addressIsError}
                                error={this.state.addressError}
                                suggestions={this.state.suggestions}
                                isFetching={this.state.isFetching}
                                getSuggestions={getSuggestions}
                                onSuggestionSelected={onSuggestionSelected}
                                defaultValue={deepFind(this.state.address, 'address_label') || ''}
                            />
                        }
                    </FormBlock>
                    <FormBlock>
                        <Checkbox
                            name={'NOT_FIND_ADDRESS'}
                            id={'NOT_FIND_ADDRESS'}
                            label={'Моего адреса нет в списке'}
                        />
                    </FormBlock>
                    <FormBlock>
                        <TextField
                            id={'DESCRIPTION'}
                            name={'DESCRIPTION'}
                            label={'Описание'}
                            size={'fullWidth'}
                            multiline={true}
                            rows={5}
                            heightAuto={true}
                            validate={[FormUtilsValidator.required, FormUtilsValidator.maxLength300]}
                        />
                    </FormBlock>
                    <ButtonsBlock>
                        <Button
                            size={40}
                            variant="primary"
                            // onClick={handleMobileFilterButtonClick}
                            fullWidth={false}
                            type={'submit'}
                            disabled={!this.props.dirty || this.state.saveInProgress}
                        >
                            {
                                this.props.itemID ? 'Изменить' : 'Добавить'
                            }
                        </Button>
                        <Button
                            onClick={()=>{
                                this.props.navigate('/profile/projects')
                            }}
                            variant="text"
                            size={40}
                        >
                            Отменить
                        </Button>
                    </ButtonsBlock>
                </form >
            }
        </>
        )
    }
}
const RowDetailForm = reduxForm({
    form: FORM_NAME
})(ProjectForm);

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (store) => {
    return {
        profile: deepFind(store.userState, 'data'),
        userState: store.userState,
        generalRequestState: store.generalRequestState,
        // authState: store.authState,
        // loginUserState: store.loginUserState,
        formValues: {
            NAME: selector(store, 'NAME') || '',
            DESCRIPTION: selector(store, 'DESCRIPTION') || '',
            NOT_FIND_ADDRESS: selector(store, 'NOT_FIND_ADDRESS') || null,
            HAND_ADDRESS: selector(store, 'HAND_ADDRESS') || '',
        }
    };
};
// const mapDispatchToProps = (dispatch, ownProps) => {
//     return {
//         userProfile,
//         // dispatch,
//         initializeValues: (values) => {
//             dispatch(initialize(FORM_NAME, values));
//         },

//     };
// };
const mapDispatchToProps = {
    generalRequest,
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(RowDetailForm);