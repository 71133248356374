import * as types from "../actions/types";
import { deepFind } from '../../../backend/models/helpers';

const INITIAL_STATE = { failure: false, errorMessage: "", action: false, data: [] };

const forgotUserReduce = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.FORGOT_REQUEST:
            return {
                ...state,
                failure: false,
                errorMessage: "",
                action: action.type,
                data: action.payload
            };

        case types.FORGOT_SUCCESS:
            return {
                ...state,
                failure: false,
                errorMessage: '',
                action: action.type,
                data: action.payload
            };

        case types.FORGOT_FAILURE:
            let errorMessage = ''
            if (action.error) {
                if (action.error.response.status == 500) {
                    errorMessage = 'Ошибка сервера'
                } else {
                    switch (deepFind(action, 'error.response.data.code')) {
                        case 101:
                            errorMessage = 'Аккаунт заблокирован. Обратитесь в техподдержку'
                            break;
                        case 102:
                            errorMessage = 'Аккаунт неактивирован. Перейдите по ссылке из письма или обратитесь в техподдержку'
                            break;
                        case 105:
                            errorMessage = 'Аккаунт не найден'
                            break;
                        case 106:
                            errorMessage = 'Неверный пароль'
                            break;
                        case 104:
                            errorMessage = 'Ошибка сервера'
                            break;
                        default:
                            errorMessage = deepFind(action, 'error.response.data.message')
                    }
                }
            }
            return {
                ...state,
                failure: true,
                errorMessage: errorMessage,
                action: action.type,
                data: action.payload
            };

        default:
            return state;
    }
};

export default forgotUserReduce;
