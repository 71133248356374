import React from 'react';
import {gray60} from "../../vars.js";

const FilterIcon = ({width = 24, height = 24, color = gray60, className}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path fillRule="evenodd" clipRule="evenodd" d="M15.5 9C16.5252 9 17.4062 8.38295 17.792 7.5H19.25C19.6642 7.5 20 7.16421 20 6.75C20 6.33579 19.6642 6 19.25 6H17.95C17.7184 4.85888 16.7095 4 15.5 4C14.2905 4 13.2816 4.85888 13.05 6H4.75C4.33579 6 4 6.33579 4 6.75C4 7.16421 4.33579 7.5 4.75 7.5H13.208C13.5938 8.38295 14.4748 9 15.5 9ZM4 11.75C4 11.3358 4.33579 11 4.75 11H6.05001C6.28164 9.85888 7.29052 9 8.5 9C9.70948 9 10.7184 9.85888 10.95 11H19.25C19.6642 11 20 11.3358 20 11.75C20 12.1642 19.6642 12.5 19.25 12.5H10.792C10.4062 13.383 9.52516 14 8.5 14C7.47484 14 6.5938 13.383 6.20802 12.5H4.75C4.33579 12.5 4 12.1642 4 11.75ZM4.75 16C4.33579 16 4 16.3358 4 16.75C4 17.1642 4.33579 17.5 4.75 17.5H13.208C13.5938 18.383 14.4748 19 15.5 19C16.5252 19 17.4062 18.383 17.792 17.5H19.25C19.6642 17.5 20 17.1642 20 16.75C20 16.3358 19.6642 16 19.25 16H17.95C17.7184 14.8589 16.7095 14 15.5 14C14.2905 14 13.2816 14.8589 13.05 16H4.75ZM16.25 6.5C16.25 6.91421 15.9142 7.25 15.5 7.25C15.0858 7.25 14.75 6.91421 14.75 6.5C14.75 6.08579 15.0858 5.75 15.5 5.75C15.9142 5.75 16.25 6.08579 16.25 6.5ZM15.5 17.25C15.9142 17.25 16.25 16.9142 16.25 16.5C16.25 16.0858 15.9142 15.75 15.5 15.75C15.0858 15.75 14.75 16.0858 14.75 16.5C14.75 16.9142 15.0858 17.25 15.5 17.25ZM9.25 11.5C9.25 11.9142 8.91421 12.25 8.5 12.25C8.08579 12.25 7.75 11.9142 7.75 11.5C7.75 11.0858 8.08579 10.75 8.5 10.75C8.91421 10.75 9.25 11.0858 9.25 11.5Z" fill={color}/>
        </svg>
    );
};

export default FilterIcon;