import React from 'react';
import {iconzky} from "../../vars.js";

const Light = ({width = 22, height = 24, className = '', color = iconzky}) => {
    return (
        <svg width={width} height={height} className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M18 0L2.07582 13.114C1.71371 13.4122 1.92457 14 2.39367 14H10.5L5 24L21.8497 10.8947C22.2255 10.6024 22.0188 10 21.5427 10H12.5L18 0Z" fill={color}/>
        </svg>
    );
};

export default Light;