import React, { Component, useState, useEffect } from 'react';
import * as type from "../../store/actions/types"
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import { loginUser } from "../../../actions/AuthUserActions";
// import { Button, Alert } from 'react-bootstrap';
// import '../../../css/Application.scss';
import './settingsPage.scss'
import logo from '../../img/logo.svg'
import Link from "../../common/link/Link";
import { userProfile } from "../../store/actions/ProfileActions";
import { formValueSelector, reduxForm, initialize } from "redux-form";
import { deepFind } from '../../../backend/models/helpers';
import Template from '../../components/Template';
import Button from '../../common/button/Button';
import BalloonHelp from '../../common/balloonHelp/BalloonHelp';
import Grid from '../../common/grid/Grid';
import Icon from "../../common/icon/Icon";
import GridBlock from '../../common/gridBlock/GridBlock';
import Heading from '../../common/heading/Heading'
import Clipboard from '../../common/clipboard/Clipboard'
import Notification from '../../common/notification/Notification'
import FormBlock from '../../common/formBlock/FormBlock'
import { useSelector } from 'react-redux';
import ClientStatusesForm from './form/ClientStatusesForm';
import OrderStatusesForm from './form/OrderStatusesForm';
import TariffsForm from './form/TariffsForm';
import ExpenditureGroupForm from './form/ExpenditureGroupForm';
import NotificationsForm from './form/NotificationsForm';

import { error60, gray60, gray30, gray90, corpGreen } from '../../common/vars'


const SettingsPage = props => {
    const { profile } = props    
    const windowWidth = useSelector(store => store.windowWidth)
    const isMobile = windowWidth <= 768;
    const [section, setSection] = useState(0)

    const menu = [
        <li className={`${section == 0 ? 'active' : ''}`}>
            <Button type="submit" variant="ghost" size={40} fullWidth={false} textLeft={true} onClick={() => { setSection(0) }}>Статусы клиентов</Button>
        </li>,
        <li className={`${section == 1 ? 'active' : ''}`}>
            <Button type="submit" variant="ghost" size={40} fullWidth={false} textLeft={true} onClick={() => { setSection(1) }}>Статусы заказов</Button>
        </li>,
        <li className={`${section == 2 ? 'active' : ''}`}>
            <Button type="submit" variant="ghost" size={40} fullWidth={false} textLeft={true} onClick={() => { setSection(2) }}>Тарифы</Button>
        </li>,
        <li className={`${section == 3 ? 'active' : ''}`}>
            <Button type="submit" variant="ghost" size={40} fullWidth={false} textLeft={true} onClick={() => { setSection(3) }}>Группы расходов</Button>
        </li>,
        <li className={`${section == 4 ? 'active' : ''}`}>
            <Button type="submit" variant="ghost" size={40} fullWidth={false} textLeft={true} onClick={() => { setSection(4) }}>Оповещения</Button>
        </li>,
    ]

    return <>
        <Template
            caption={'Настройки'}
            colorVariant={'white-gray'}
            menu={menu}
        >
            {section == 0 && <ClientStatusesForm />}
            {section == 1 && <OrderStatusesForm />}
            {section == 2 && <TariffsForm />}
            {section == 3 && <ExpenditureGroupForm />}
            {section == 4 && <NotificationsForm />}
        </Template>
    </>
}
const mapStateToProps = store => {
    // const pf = store.userState.size > 0 ? store.userState.get("profile") : null;    
    return {
        profile: deepFind(store.userState, 'data') || {},
        // pf,
        // documentsState: store.documentsState,
        // formValues: {
        //     PASSPORT_RF_NUMBER: selector(store, 'PASSPORT_RF_NUMBER') || '',
        //     PASSPORT_RF_ISSUED_ON: selector(store, 'PASSPORT_RF_ISSUED_ON') || '',
        //     PASSPORT_RF_ISSUED_BY: selector(store, 'PASSPORT_RF_ISSUED_BY') || '',
        //     PASSPORT_RF_DIVISION_CODE: selector(store, 'PASSPORT_RF_DIVISION_CODE') || '',
        //     PASSPORT_RF_BIRTHPLACE: selector(store, 'PASSPORT_RF_BIRTHPLACE') || '',
        //     SNILS: selector(store, 'SNILS') || deepFind(pf, 'SNILS.data.SNILS') || '',
        //     BIRTHDATE: selector(store, 'BIRTHDATE') || deepFind(pf, 'PERSON.data.BIRTHDATE') || '',
        // }
    }
}

const mapDispatchToProps = {
    userProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
// export default ProfilePage