import React from 'react';

const NotificationSuccessOval = ({width, height, color, className}) => {
    return (
        <svg className={className} width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="#AFF8C7"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14 25C20.0751 25 25 20.0751 25 14C25 7.92487 20.0751 3 14 3C7.92487 3 3 7.92487 3 14C3 20.0751 7.92487 25 14 25Z" fill="#97EDAC"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14 24C19.5228 24 24 19.5228 24 14C24 8.47715 19.5228 4 14 4C8.47715 4 4 8.47715 4 14C4 19.5228 8.47715 24 14 24Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14 22C9.5888 22 6 18.4112 6 14C6 9.5888 9.5888 6 14 6C18.4112 6 22 9.5888 22 14C22 18.4112 18.4112 22 14 22ZM17.9322 11.3624C17.6165 11.1147 17.1472 11.1548 16.8841 11.4521L13.3349 15.4604L11.4704 13.7045L11.3869 13.6366C11.0956 13.4331 10.6822 13.4557 10.418 13.7045C10.1273 13.9781 10.1273 14.4218 10.418 14.6955L12.8598 16.9947L12.9411 17.0611C13.2524 17.2804 13.7009 17.238 13.9577 16.9478L18.0275 12.3493L18.0869 12.2722C18.2816 11.9797 18.2216 11.5895 17.9322 11.3624Z" fill="#008844"/>
        </svg>
    );
};

export default NotificationSuccessOval;